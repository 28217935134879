<template>
  <div class="card">
    <div class="card-header bg-light">
      <i class="bi bi-window-sidebar me-2"></i>
      Service roles
    </div>

    <div v-if="0 === roles.length" class="card-body">
      <div class="text-center">
        <img src="/images/empty-services.svg" class="img-fluid py-5" />
      </div>
    </div>

    <div v-else class="list-group list-group-flush">
      <div v-for="(role, key) in roles" v-bind:key="key" class="list-group-item border-0">
        <div class="row">
          <label :for="'role_' + role.id" class="col-sm-3 col-form-label py-0">
            <div class="fw-bold">{{ role.service_name  }}</div>
            <small>{{ role.organization_name }}</small>
          </label>
          <div class="col-sm-9">
            <select class="form-control" :id="'role_' + role.id" v-model="roles[key].id">
              <option value="">Please select</option>
              <option v-for="item in services[role.service_id]" v-bind:key="item.role_id" :value="item.role_id">{{ item.role_name }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <!-- <div v-for="(service, key, ndx) in services" class="list-group-item border-0">

        <div class="row">
          <label :for="'service_' + key" class="col-sm-3 col-form-label">{{ service[0].service_name }}</label>
          <div class="col-sm-9">
            <select class="form-control" :id="'service_' + key" v-model="roles[ndx].id">
              <option value="">Please select</option>
              <option v-for="role in service" v-bind:key="role.role_id" :value="role.role_id">{{ role.role_name }}
              </option>
            </select>
          </div>
        </div>
      </div> -->

      <div class="p-3">
        <button type="button" class="btn btn-primary w-25 float-end" @click="saveRoles()">Save</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import Utils from "@/helpers/utils";

export default {
  name: "MyAccountServiceRoles",
  components: {},
  data() {
    return {
      roles: [],
      services: [],
    };
  },
  mounted() {
    this.fetch_service_roles();

    setTimeout(() => {
      jQuery("[data-bs-toggle='tooltip']").each(function () {
        jQuery(this).tooltip();
      });
    }, 1000);
  },
  methods: {
    fetch_service_roles() {
      axios
        .get(
          process.env.VUE_APP_URL +
          "/services/service-roles",
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then((response) => {
          this.roles = response.data.roles;
          this.services = response.data.services;
        });
    },
    saveRoles() {
      var data = [];

      this.roles.forEach(item => {
        data.push({
          service: item.service_id,
          subscription: item.subscription_id,
          role: item.id
        })
      });

      axios
        .patch(
          process.env.VUE_APP_URL +
          "/services/service-roles", data,
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then(
          (response) => {
            this.emitter.emit("popup_alert", {
              type: "success",
              text: "User roles were successfully updated",
            });

            this.fetch_service_roles();
          },
          (error) => {
            console.log(error);
          }
        );
    },
    capitalizeText(text, allwords) {
      return Utils.capitalizeText(text, allwords);
    },
    timeAgo(dateString) {
      return Utils.timeAgo(dateString);
    }
  },
};
</script>