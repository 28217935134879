import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import AdminPage from "../views/AdminPage.vue";

import UsersPage from "../views/UsersPage.vue";
import UserPage from "../views/UserPage.vue";
import UserOverview from "../views/fragments/UserOverview.vue";
import UserOrganizations from "../views/fragments/UserOrganizations.vue";
import UserSites from "../views/fragments/UserSites.vue";
import UserServices from "../views/fragments/UserServices.vue";
import UserSessions from "../views/fragments/UserSessions.vue";
import UserActivity from "../views/fragments/UserActivity.vue";

import AdminOrganizationsPage from "../views/AdminOrganizationsPage.vue";
import AdminOrganizationPage from "../views/AdminOrganizationPage.vue";

import OrganizationsPage from "../views/OrganizationsPage.vue";
import OrganizationPage from "../views/OrganizationPage.vue";
import OrganizationOverview from "../views/fragments/OrganizationOverview.vue";
import OrganizationSites from "../views/fragments/OrganizationSites.vue";
import OrganizationMembers from "../views/fragments/OrganizationMembers.vue";
import OrganizationCollaborators from "../views/fragments/OrganizationCollaborators.vue";
import OrganizationServices from "../views/fragments/OrganizationServices.vue";
import OrganizationActivity from "../views/fragments/OrganizationActivity.vue";

import SitesPage from "../views/SitesPage.vue";
import SitePage from "../views/SitePage.vue";
import SiteOverview from "../views/fragments/SiteOverview.vue";
import SiteMembers from "../views/fragments/SiteMembers.vue";
import SiteCollaborators from "../views/fragments/SiteCollaborators.vue";
import SiteActivity from "../views/fragments/SiteActivity.vue";

import ServicesPage from "../views/ServicesPage.vue";
import ServicePage from "../views/ServicePage.vue";
import ServiceOverview from "../views/fragments/ServiceOverview.vue";
import ServiceRoles from "../views/fragments/ServiceRoles.vue";
import ServiceActivity from "../views/fragments/ServiceActivity.vue";

import AdminLogsPage from "../views/AdminLogsPage.vue";
import AdminLogPage from "../views/AdminLogPage.vue";
import MyLogsPage from "../views/MyLogsPage.vue";
import MyLogPage from "../views/MyLogPage.vue";

import MyAccountPage from "../views/MyAccountPage.vue";
import MyAccountOverview from "../views/fragments/MyAccountOverview.vue";
import MyAccountSessions from "../views/fragments/MyAccountSessions.vue";
import MyAccountActivity from "../views/fragments/MyAccountActivity.vue";
import MyAccountServiceRoles from "../views/fragments/MyAccountServiceRoles.vue";

import MyNotificationsPage from "../views/MyNotificationsPage.vue";
import NotificationsOverview from "../views/fragments/NotificationsOverview.vue";
import NotificationsSettings from "../views/fragments/NotificationsSettings.vue";
import MyNotificationPage from "../views/MyNotificationPage.vue";

import AdminNotificationsPage from "../views/AdminNotificationsPage.vue";
import AdminNotificationPage from "../views/AdminNotificationPage.vue";

import NotFound from "@/components/NotFound.vue";

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      title: "Dashboard - REMOTE TECH LTD"
    }
  },
  {
    path: "/admin",
    name: "AdminPage",
    component: AdminPage,
    meta: {
      title: "Administartion - REMOTE TECH LTD"
    }
  },
  {
    path: "/admin/users",
    name: "UsersPage",
    component: UsersPage,
    meta: {
      title: "Users - REMOTE TECH LTD"
    }
  },
  {
    path: "/admin/users/:user_id",
    name: "UserPage",
    component: UserPage,
    meta: {
      title: "User - REMOTE TECH LTD"
    },
    children: [
      {
        path: "",
        name: "UserOverview",
        component: UserOverview,
        meta: {
          title: "Overview - REMOTE TECH LTD",
          submenu: "overview"
        }
      },
      {
        path: "organisations",
        name: "UserOrganizations",
        component: UserOrganizations,
        meta: {
          title: "Organisations - REMOTE TECH LTD",
          submenu: "organisations"
        }
      },
      {
        path: "sites",
        name: "UserSites",
        component: UserSites,
        meta: {
          title: "Sites - REMOTE TECH LTD",
          submenu: "sites"
        }
      },
      {
        path: "services",
        name: "UserServices",
        component: UserServices,
        meta: {
          title: "Services - REMOTE TECH LTD",
          submenu: "services"
        }
      },
      {
        path: "sessions",
        name: "UserSessions",
        component: UserSessions,
        meta: {
          title: "Sessions - REMOTE TECH LTD",
          submenu: "sessions"
        }
      },
      {
        path: "logs",
        name: "UserActivity",
        component: UserActivity,
        meta: {
          title: "Activity - REMOTE TECH LTD",
          submenu: "logs"
        }
      }
    ]
  },
  {
    path: "/admin/organisations",
    name: "AdminOrganizationsPage",
    component: AdminOrganizationsPage,
    meta: {
      title: "Organisations - REMOTE TECH LTD"
    },
  },
  {
    path: "/admin/organisations/:organization_id",
    name: "AdminOrganizationPage",
    component: AdminOrganizationPage,
    meta: {
      title: "Organization - REMOTE TECH LTD"
    },
    children: [
      {
        path: "",
        name: "AdminOrganizationOverview",
        component: OrganizationOverview,
        meta: {
          title: "Overview - REMOTE TECH LTD",
          submenu: "overview"
        }
      },
      {
        path: "sites",
        name: "AdminOrganizationSites",
        component: OrganizationSites,
        meta: {
          title: "Sites - REMOTE TECH LTD",
          submenu: "sites"
        }
      },
      {
        path: "members",
        name: "AdminOrganizationMembers",
        component: OrganizationMembers,
        meta: {
          title: "Members - REMOTE TECH LTD",
          submenu: "members"
        }
      },
      {
        path: "collaborators",
        name: "AdminOrganizationCollaborators",
        component: OrganizationCollaborators,
        meta: {
          title: "Collaborators - REMOTE TECH LTD",
          submenu: "collaborators"
        }
      },
      {
        path: "services",
        name: "AdminOrganizationServices",
        component: OrganizationServices,
        meta: {
          title: "Services - REMOTE TECH LTD",
          submenu: "services"
        }
      },
      {
        path: "logs",
        name: "AdminOrganizationActivity",
        component: OrganizationActivity,
        meta: {
          title: "Activity - REMOTE TECH LTD",
          submenu: "logs"
        }
      },
    ]
  },
  {
    path: "/organisations",
    name: "OrganizationsPage",
    component: OrganizationsPage,
    meta: {
      title: "Organisations - REMOTE TECH LTD"
    },
  },
  {
    path: "/organisations/:organization_id",
    name: "OrganizationPage",
    component: OrganizationPage,
    meta: {
      title: "Organization - REMOTE TECH LTD"
    },
    children: [
      {
        path: "",
        name: "OrganizationOverview",
        component: OrganizationOverview,
        meta: {
          title: "Overview - REMOTE TECH LTD",
          submenu: "overview"
        }
      },
      {
        path: "sites",
        name: "OrganizationSites",
        component: OrganizationSites,
        meta: {
          title: "Sites - REMOTE TECH LTD",
          submenu: "sites"
        }
      },
      {
        path: "members",
        name: "OrganizationMembers",
        component: OrganizationMembers,
        meta: {
          title: "Members - REMOTE TECH LTD",
          submenu: "members"
        }
      },
      {
        path: "collaborators",
        name: "OrganizationCollaborators",
        component: OrganizationCollaborators,
        meta: {
          title: "Collaborators - REMOTE TECH LTD",
          submenu: "collaborators"
        }
      },
      {
        path: "services",
        name: "OrganizationServices",
        component: OrganizationServices,
        meta: {
          title: "Services - REMOTE TECH LTD",
          submenu: "services"
        }
      },
      {
        path: "logs",
        name: "OrganizationActivity",
        component: OrganizationActivity,
        meta: {
          title: "Activity - REMOTE TECH LTD",
          submenu: "logs"
        }
      },
    ]
  },
  {
    path: "/sites",
    name: "SitesPage",
    component: SitesPage,
    meta: {
      title: "Sites - REMOTE TECH LTD"
    },
  },
  {
    path: "/sites/:site_id",
    name: "SitePage",
    component: SitePage,
    meta: {
      title: "Site - REMOTE TECH LTD"
    },
    children: [
      {
        path: "",
        name: "SiteOverview",
        component: SiteOverview,
        meta: {
          title: "Overview - REMOTE TECH LTD",
          submenu: "overview"
        }
      },
      {
        path: "members",
        name: "SiteMembers",
        component: SiteMembers,
        meta: {
          title: "Overview - REMOTE TECH LTD",
          submenu: "members"
        }
      },
      {
        path: "collaborators",
        name: "SiteCollaborators",
        component: SiteCollaborators,
        meta: {
          title: "Overview - REMOTE TECH LTD",
          submenu: "collaborators"
        }
      },
      {
        path: "logs",
        name: "SiteActivity",
        component: SiteActivity,
        meta: {
          title: "Activity - REMOTE TECH LTD",
          submenu: "logs"
        }
      }
    ]
  },
  {
    path: "/admin/services",
    name: "ServicesPage",
    component: ServicesPage,
    meta: {
      title: "Services - REMOTE TECH LTD"
    },
  },
  {
    path: "/admin/services/:service_id",
    name: "ServicePage",
    component: ServicePage,
    meta: {
      title: "Service - REMOTE TECH LTD"
    },
    children: [
      {
        path: "",
        name: "ServiceOverview",
        component: ServiceOverview,
        meta: {
          title: "Overview - REMOTE TECH LTD",
          submenu: "overview"
        }
      },
      {
        path: "roles",
        name: "ServiceRoles",
        component: ServiceRoles,
        meta: {
          title: "Roles - REMOTE TECH LTD",
          submenu: "roles"
        }
      },
      {
        path: "logs",
        name: "ServiceActivity",
        component: ServiceActivity,
        meta: {
          title: "Activity - REMOTE TECH LTD",
          submenu: "logs"
        }
      },
    ]
  },
  {
    path: "/admin/logs",
    name: "AdminLogsPage",
    component: AdminLogsPage,
    meta: {
      title: "Activity Logs - REMOTE TECH LTD"
    },
  },
  {
    path: "/admin/logs/:log_id",
    name: "AdminLogPage",
    component: AdminLogPage,
    meta: {
      title: "Log entry - REMOTE TECH LTD",
    }
  },
  {
    path: "/logs",
    name: "MyLogsPage",
    component: MyLogsPage,
    meta: {
      title: "Activity Logs - REMOTE TECH LTD"
    },
  },
  {
    path: "/logs/:log_id",
    name: "MyLogPage",
    component: MyLogPage,
    meta: {
      title: "Log entry - REMOTE TECH LTD",
    }
  },
  {
    path: "/account",
    name: "MyAccountPage",
    component: MyAccountPage,
    meta: {
      title: "My account - REMOTE TECH LTD",
    },
    children: [
      {
        path: "",
        name: "MyAccountOverview",
        component: MyAccountOverview,
        meta: {
          title: "Overview - REMOTE TECH LTD",
          submenu: "overview"
        },
      },
      {
        path: "sessions",
        name: "MyAccountSessions",
        component: MyAccountSessions,
        meta: {
          title: "Sessions - REMOTE TECH LTD",
          submenu: "sessions"
        },
      },
      {
        path: "logs",
        name: "MyAccountActivity",
        component: MyAccountActivity,
        meta: {
          title: "Logs - REMOTE TECH LTD",
          submenu: "logs"
        },
      },
      {
        path: "roles",
        name: "MyAccountServiceRoles",
        component: MyAccountServiceRoles,
        meta: {
          title: "Service roles - REMOTE TECH LTD",
          submenu: "roles"
        },
      }
    ]
  },
  {
    path: "/notifications",
    name: "MyNotificationsPage",
    component: MyNotificationsPage,
    meta: {
      title: "Notifications - REMOTE TECH LTD",
    },
    children: [
      {
        path: "",
        name: "NotificationsOverview",
        component: NotificationsOverview,
        meta: {
          title: "Overview - REMOTE TECH LTD",
          submenu: "overview"
        },
      },
      {
        path: "settings",
        name: "NotificationsSettings",
        component: NotificationsSettings,
        meta: {
          title: "Notification Settings - REMOTE TECH LTD",
          submenu: "settings"
        },
      }
    ]
  },
  {
    path: "/notifications/:notification_id",
    name: "MyNotificationPage",
    component: MyNotificationPage,
    meta: {
      title: "Notification - REMOTE TECH LTD",
    }
  },
  {
    path: "/admin/notifications",
    name: "AdminNotificationsPage",
    component: AdminNotificationsPage,
    meta: {
      title: "Notifications - REMOTE TECH LTD"
    },
  },
  {
    path: "/admin/notifications/:notification_id",
    name: "AdminNotificationPage",
    component: AdminNotificationPage,
    meta: {
      title: "Notification entry - REMOTE TECH LTD",
    }
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFound,
    meta: {
      title: "Not Found - REMOTE TECH LTD",
    },
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

router.beforeEach((toRoute, fromRoute, next) => {
  window.document.title = toRoute.meta && toRoute.meta.title ? toRoute.meta.title : "REMOTE TECH LTD";

  next();
})
