<template>
  <div class="modal fade" tabindex="-1" :id="id">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Unlink Google Account</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="alert alert-danger text-center">
                Are you sure you want to unlink your Google account?
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <button class="btn btn-secondary w-100" data-bs-dismiss="modal">
                Cancel
              </button>
            </div>
            <div class="col-md-6">
              <div class="btn btn-danger w-100" @click="unlink_google()">
                Confirm
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ModalMeUnlinkGoogle",
  props: ["id"],
  data() {
    return {};
  },
  mounted() {
    this.emitter.on("user_details_loaded", (data) => {
      this.user_id = data.id ? data.id : null;
      this.full_name = data.full_name ? data.full_name : "";
    });
  },
  methods: {
    unlink_google() {
      axios
        .patch(
          process.env.VUE_APP_URL + "/me/unlink_google/",
          {},
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then(
          (response) => {
            if (200 === response.status) {
              this.emitter.emit("user_status_updated", this.user);

              let unlinkGoogleModal = bootstrap.Modal.getInstance(
                document.getElementById(this.id)
              );
              unlinkGoogleModal.hide();
              unlinkGoogleModal.dispose();
            } else {
            }
          },
          (error) => {
            console.log(error);
          }
        );
    },
  },
};
</script>