<template>
  <Breadcrumbs :breaditems="[
    { title: 'Admin', link: '/admin' },
    { title: 'Notifications' },
  ]" />
  <div class="container">
    <div class="d-flex bd-highlight">
      <div class="flex-grow-1 me-2">
        <div class="input-group">
          <button class="input-group-text bg-white" @click="fetch_notifications()">
            <i class="bi bi-search"></i>
          </button>
          <input type="text" class="form-control" v-model="filter.notification_name"
            v-on:keyup.enter="fetch_notifications()" placeholder="Search..." />
        </div>
      </div>

      <div class="bd-highlight me-2">
        <SearchableDropdown id="sites" placeholder="Select site" />
      </div>

      <div class="bd-highlight me-2">
        <SearchableDropdown id="services" placeholder="Select service" />
      </div>

      <div class="bd-highlight me-2">
        <SearchableDropdown id="types" placeholder="Select type" />
      </div>

      <div class="bd-highlight">
        <div class="input-group">
          <div class="input-group">
            <span class="input-group-text bg-white"><i class="bi bi-calendar-week"></i></span>
            <input type="text" id="fromDate" placeholder="From Date" class="form-control" style="max-width: 120px" />
            <span class="input-group-text bg-white"><i class="bi bi-calendar-week"></i></span>
            <input type="text" id="toDate" placeholder="To Date" class="form-control" style="max-width: 120px" />
          </div>
        </div>
      </div>
    </div>

    <div class="card mt-2">
      <div class="card-header bg-light">
        <i class="bi bi-bell-fill me-2"></i>
        Notifications
      </div>

      <div v-if="0 === notifications.length" class="card-body">
        <div class="text-center">
          <img src="/images/empty-notifications.svg" class="img-fluid py-5" />
        </div>
      </div>

      <div v-else class="list-group list-group-flush">
        <router-link :to="'/admin/notifications/' + notification.id" v-for="notification in notifications"
          v-bind:key="notification.id" class="list-group-item list-group-item-action">
          <div class="d-flex align-items-center" v-if="null === notification.read_at">
            <div class="flex-shrink-0">
              <i v-if="3 == notification.priority" class="bi bi-sign-stop-fill text-danger" style="font-size: 36px"></i>
              <i v-if="2 == notification.priority" class="bi bi-exclamation-triangle-fill text-warning"
                style="font-size: 36px"></i>
              <i v-if="1 == notification.priority" class="bi bi-info-circle-fill text-info" style="font-size: 36px"></i>
            </div>
            <div class="flex-grow-1 ms-3">
              <span class="fw-bold text-dark">{{
                notification.subject + " (" + notification.service_label + ")"
              }}</span>
              <p class="small text-muted mb-0 fw-normal" v-html="notification.description ? notification.description : '&nbsp;'"></p>
            </div>
            <span class="text-muted float-end small text-nowrap" data-bs-toggle="tooltip" data-bs-placement="top" title=""
              :data-bs-original-title="notification.created_at">{{ timeAgo(notification.created_at) }}</span>
            <div class="float-end ms-3">
              <i class="bi bi-chevron-right align-self-center"></i>
            </div>
          </div>
          <div class="d-flex align-items-center" v-else>
            <div class="flex-shrink-0">
              <i v-if="3 == notification.priority" class="bi bi-sign-stop-fill text-danger" style="font-size: 36px"></i>
              <i v-if="2 == notification.priority" class="bi bi-exclamation-triangle-fill text-warning"
                style="font-size: 36px"></i>
              <i v-if="1 == notification.priority" class="bi bi-info-circle-fill text-info" style="font-size: 36px"></i>
            </div>
            <div class="flex-grow-1 ms-3">
              <span class="text-muted">{{
                notification.subject + " (" + notification.service_label + ")"
              }}</span>
              <p class="small text-muted mb-0 fw-normal" v-html="notification.description ? notification.description : '&nbsp;'"></p>
            </div>
            <span class="text-muted float-end small text-nowrap" data-bs-toggle="tooltip" data-bs-placement="top" title=""
              :data-bs-original-title="notification.created_at">{{ timeAgo(notification.created_at) }}</span>
            <div class="float-end ms-3">
              <i class="bi bi-chevron-right align-self-center"></i>
            </div>
          </div>
        </router-link>
      </div>
      <div class="card-footer bg-light">
        <pagination v-if="paginator.current_page" :records="paginator.total_items" :per-page="10" v-model="current_page"
          :options="paginatorOptions"></pagination>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import FileDownload from "js-file-download";
import Pagination from "v-pagination-3";
import { Datepicker } from "vanillajs-datepicker";

import Breadcrumbs from "@/components/Breadcrumbs.vue";
import RTPagination from "@/components/RTPagination.vue";
import SearchableDropdown from "@/components/SearchableDropdown.vue";

import Utils from "@/helpers/utils";

var fileDownload = require("js-file-download");

export default {
  name: "AdminNotificationsPage",
  components: { Breadcrumbs, Pagination, SearchableDropdown },
  data() {
    return {
      paginatorOptions: {
        template: RTPagination,
        chunk: 10,
      },
      paginator: {},
      notifications: [],
      organisations: {},
      filter: {
        site: "",
        service: "",
        type: "",
        notification_name: "",
        from_date: "",
        to_date: "",
      },
      current_page: 1,
    };
  },
  mounted() {
    let app = this;

    const fromDate = new Datepicker(document.getElementById("fromDate"), {
      clearBtn: true,
      autohide: true,
      maxDate: new Date(),
      format: "d M yyyy",
    });

    const toDate = new Datepicker(document.getElementById("toDate"), {
      clearBtn: true,
      autohide: true,
      minDate: new Date(),
      maxDate: new Date(),
      format: "d M yyyy",
    });

    document.getElementById("fromDate").addEventListener("changeDate", (ev) => {
      app.filter.from_date = Datepicker.formatDate(
        ev.detail.date,
        "yyyy-mm-dd"
      );

      toDate.setOptions({ minDate: ev.detail.date });

      if (toDate.getDate() < ev.detail.date) {
        toDate.setDate(ev.detail.date);
      }
    });

    document.getElementById("toDate").addEventListener("changeDate", (ev) => {
      this.filter.to_date = Datepicker.formatDate(ev.detail.date, "yyyy-mm-dd");
    });

    /* Site selected */
    this.emitter.on("dropdown_selected_sites", (data) => {
      this.filter.site = data.value;
    });

    /* Service selected */
    this.emitter.on("dropdown_selected_services", (data) => {
      this.filter.service = data.value;
    });

    /* Type selected */
    this.emitter.on("dropdown_selected_types", (data) => {
      this.filter.type = data.value;
    });

    this.fetch_notifications();
    this.fetch_sites();
    this.fetch_services();
    this.fetch_types();

    setTimeout(() => {
      jQuery("[data-bs-toggle='tooltip']").each(function () {
        jQuery(this).tooltip();
      });
    }, 1000);
  },
  methods: {
    fetch_sites() {
      axios
        .get(process.env.VUE_APP_URL + "/sites/", {
          headers: {
            Authorization: process.appdata.tokens.access_token,
          },
        })
        .then((response) => {
          let sitesArray = [{ label: "All", value: "" }];

          for (let item in response.data[0]) {
            if (
              "undefined" ===
              typeof this.organisations[
              response.data[0][item].site.organization_id
              ]
            ) {
              this.organisations[response.data[0][item].site.organization_id] =
                {};
            }
            this.organisations[
              response.data[0][item].site.organization_id
            ].name = response.data[0][item].site.organization.name;

            if (
              "undefined" ===
              typeof this.organisations[
                response.data[0][item].site.organization_id
              ].sites
            ) {
              this.organisations[
                response.data[0][item].site.organization_id
              ].sites = [];
            }

            this.organisations[
              response.data[0][item].site.organization_id
            ].sites.push({
              id: response.data[0][item].site_id,
              name: response.data[0][item].site.name,
            });
          }

          for (let organisation in this.organisations) {
            sitesArray.push({
              label: this.organisations[organisation].name,
              group: true,
            });

            for (let site in this.organisations[organisation].sites) {
              sitesArray.push({
                label: this.organisations[organisation].sites[site].name,
                value: this.organisations[organisation].sites[site].id,
              });
            }
          }

          this.emitter.emit("dropdown_options_sites", sitesArray);
        });
    },
    fetch_services() {
      axios
        .get(process.env.VUE_APP_URL + "/me/services?items=1000", {
          headers: {
            Authorization: process.appdata.tokens.access_token,
          },
        })
        .then((response) => {
          let servicesArray = [{ label: "All", value: "" }];

          response.data.data.forEach((item) => {
            servicesArray.push({
              label: item.name,
              value: item.id,
            });
          });

          this.emitter.emit("dropdown_options_services", servicesArray);
        });
    },
    fetch_types() {
      axios
        .get(process.env.VUE_APP_NOTIFICATIONS_ENDPOINT + "/notifications/types", {
          headers: {
            Authorization: process.appdata.tokens.access_token,
          },
        })
        .then((response) => {
          let typesArray = [{ label: "All", value: "" }];

          for (let group in response.data) {
            typesArray.push({
              label: response.data[group].name,
              value: response.data[group].name,
            });
          }

          this.emitter.emit("dropdown_options_types", typesArray);
        });
    },
    fetch_notifications() {
      let url =
        process.env.VUE_APP_NOTIFICATIONS_ENDPOINT +
        "/notifications?page=" +
        this.current_page +
        "&from_date=" +
        this.filter.from_date +
        "&to_date=" +
        this.filter.to_date +
        "&service_id=" +
        this.filter.service +
        "&event_type=" +
        this.filter.type +
        "&description=" +
        encodeURIComponent(this.filter.notification_name);

      axios
        .get(url, {
          headers: {
            Authorization: process.appdata.tokens.access_token,
          },
        })
        .then((response) => {
          this.notifications = response.data.data;
          this.paginator = response.data.paginator;
        });
    },
    short_input(string) {
      if (string && string.length > 40) {
        return string.substring(0, 40) + "...";
      } else {
        return string;
      }
    },
    timeAgo(dateString) {
      return Utils.timeAgo(dateString);
    },
  },
  watch: {
    "filter.site": function () {
      this.fetch_notifications();
    },
    "filter.service": function () {
      this.fetch_notifications();
    },
    "filter.type": function () {
      this.fetch_notifications();
    },
    "filter.notification_name": function () {
      this.fetch_notifications();
    },
    "filter.from_date": function () {
      this.fetch_notifications();
    },
    "filter.to_date": function () {
      this.fetch_notifications();
    },
    current_page: function () {
      this.fetch_notifications();
    },
  },
};
</script>