<template>
  <div class="modal fade" tabindex="-1" :id="id">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Update Organization Description</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12 mb-3">
              <label for="description" class="form-label">Description</label
              ><input
                type="text"
                id="description"
                minlength="3"
                maxlength="50"
                class="form-control"
                v-model="description"
                @keyup="validate"
              />
              <small class="text-secondary">
                <i>Must be at least 3 characters</i></small
              >
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <button
                type="button"
                class="btn btn-primary w-100"
                :disabled="!valid_input"
                @click="update_organization"
              >
                Update Organization
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ModalOrganizationDescription",
  props: ["id"],
  data() {
    return {
      description: "",
      valid_input: false,
    };
  },
  mounted() {
    this.emitter.on("organization_details_loaded", (data) => {
      this.description = data.description ? data.description : "";

      this.validate();
    });
  },
  methods: {
    validate() {
      this.valid_input = this.description.length >= 3;
    },

    update_organization() {
      let app = this;

      axios
        .patch(
          process.env.VUE_APP_URL +
            "/organizations/" +
            this.$route.params.organization_id,
          {
            description: this.description,
          },
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then(
          (response) => {
            if (200 === response.status) {
              app.emitter.emit("organization_description_updated");

              let organizationDescriptionModal = bootstrap.Modal.getInstance(
                document.getElementById(this.id)
              );
              organizationDescriptionModal.hide();
            } else {
            }
          },
          (error) => {
            console.log(error);
          }
        );
    },
  },
};
</script>