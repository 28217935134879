<template>
  <div class="d-flex bd-highlight">
    <div class="flex-grow-1 me-2">
      <div class="input-group">
        <button class="input-group-text bg-white">
          <i class="bi bi-search"></i></button><input type="text" class="form-control" placeholder="Search event..."
          v-model="filters.event_type" />
      </div>
    </div>
    <div v-if="0 < services.length" class="bd-highlight me-2">
      <select class="form-select" v-model="filters.service" @change="select_service">
        <option v-for="service in services" v-bind:key="service.id" :value="service.id">
          {{ service.label }}
        </option>
      </select>
    </div>
  </div>

  <div v-if="0 === events.length" class="card mt-2">
    <div class="card-body">
      <div class="text-center">
        <img src="/images/empty-notifications.svg" class="img-fluid py-5" />
      </div>
    </div>
  </div>

  <div v-else v-for="event in events" v-bind:key="event.type" class="card mt-2">
    <div :class="-1 < event.type.indexOf(filters.event_type) ? '' : 'd-none'">
      <div class="card-header bg-light">
        {{ event.type }}

        <div class="float-end form-check form-switch form-control-lg p-0 m-0 d-flex">
          <input class="form-check-input align-self-center m-0" type="checkbox" :id="'event_' + event.type"
            :checked="null === event.unsubscribed_at" :disabled="0 === event.can_unsubscribe"
            @click="toggle_subscription(event.type)" />
        </div>
      </div>
      <div class="card-body">
        <OrganisationSites :id="event.type" :prefs="events[event.type].preferences" :orgs="user_sites"
          :updated="update_preferences" />
      </div>
      <div class="card-body border-top" v-if="event.preferences && event.preferences.channels">
        <p>Delivery channels</p>
        <div class="row">
          <div class="col-4 d-flex">
            <label :for="event.type + '_email'" class="me-3 pt-1" title="E-mail">
              <i class="bi bi-envelope-fill"></i>
            </label>
            <select :id="event.type + '_email'" class="form-control form-control-sm"
              v-model="event.preferences.channels.email.frequency" @change="update_settings">
              <option value="disabled">Disabled</option>
              <option value="instant">Instant</option>
              <option value="hourly">Hourly</option>
              <option value="daily">Daily</option>
              <option value="weekly">Weekly</option>
            </select>
          </div>

          <div class="col-4 d-flex">
            <label :for="event.type + '_telegram'" class="me-3 pt-1" title="Telegram">
              <i class="bi bi-telegram"></i>
            </label>
            <select :id="event.type + '_telegram'" class="form-control form-control-sm"
              v-model="event.preferences.channels.telegram.frequency" @change="update_settings">
              <option value="disabled">Disabled</option>
              <option value="instant">Instant</option>
              <option value="hourly">Hourly</option>
              <option value="daily">Daily</option>
              <option value="weekly">Weekly</option>
            </select>
          </div>

          <div class="col-4 d-flex">
            <label :for="event.type + '_wechat'" class="me-3 pt-1" title="WeChat">
              <i class="bi bi-wechat"></i>
            </label>
            <select :id="event.type + '_wechat'" class="form-control form-control-sm"
              v-model="event.preferences.channels.wechat.frequency" @change="update_settings">
              <option value="disabled">Disabled</option>
              <option value="instant">Instant</option>
              <option value="hourly">Hourly</option>
              <option value="daily">Daily</option>
              <option value="weekly">Weekly</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import OrganisationSites from "@/components/OrganisationSites.vue";

import Utils from "@/helpers/utils";

export default {
  name: "NotificationsSettings",
  components: { OrganisationSites },
  data() {
    return {
      settings: [],
      services: [],
      events: [],
      filters: {
        event_type: "",
        service: "",
      },
      user_sites: [],
    };
  },

  mounted() {
    this.fetch_subscriptions();
    this.fetch_my_sites();
  },

  methods: {
    fetch_subscriptions() {
      axios
        .get(process.env.VUE_APP_NOTIFICATIONS_ENDPOINT + "/subscriptions", {
          headers: {
            Authorization: process.appdata.tokens.access_token,
          },
        })
        .then((response) => {
          this.settings = response.data;

          this.services = [];
          for (let item in response.data) {
            this.services.push({
              id: response.data[item].id,
              label: response.data[item].label,
            });

            if (0 === this.events.length) {
              this.events = response.data[item].events;
            }
          }

          if (0 < this.services.length) {
            this.filters.service = this.services[0].id;
            this.select_service();
          }
        });
    },

    fetch_my_sites() {
      axios
        .get(process.env.VUE_APP_URL + "/mysites_ns", {
          headers: {
            Authorization: process.appdata.tokens.access_token,
          },
        })
        .then((response) => {
          this.user_sites = response.data;
        });
    },

    select_service() {
      this.events = this.settings[this.filters.service].events;
    },

    update_preferences(data) {
      this.events[data.event].preferences.muted_organisations = data.preferences.muted_organisations;
      this.events[data.event].preferences.muted_sites = data.preferences.muted_sites;

      this.update_settings();
    },

    update_settings() {
      let app = this;

      axios
        .patch(
          process.env.VUE_APP_NOTIFICATIONS_ENDPOINT + "/subscriptions",
          {
            service: app.filters.service,
            events: app.events,
          },
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then((response) => {
          app.emitter.emit("popup_alert", {
            type: "success",
            text: "Your notification preferences were successfully updated!",
          });
        });
    },

    toggle_subscription(event_type) {
      let app = this;

      axios
        .patch(
          process.env.VUE_APP_NOTIFICATIONS_ENDPOINT + "/subscriptions/toggle",
          {
            service: app.filters.service,
            event_type: event_type,
          },
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then((response) => {
          app.emitter.emit("popup_alert", {
            type: "success",
            text: "Your subscription preferences were successfully updated!",
          });
        });
    },

    capitalizeText(text, allwords) {
      return Utils.capitalizeText(text, allwords);
    },
  },
};
</script>