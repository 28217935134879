export default class Socket {
    static sock = null;
    static aliveCheck = null;

    constructor(url, onOpen, onMessage, onError) {
        this.sock = new WebSocket(String(url));

        clearInterval(this.aliveCheck);
        this.aliveCheck = setInterval(() => {
            this.alive();
        }, 10000);

        if (onOpen) {
            this.sock.addEventListener("open", function (event) {
                onOpen(event.data);
            });
        }

        if (onMessage) {
            this.sock.addEventListener("message", function (event) {
                onMessage(JSON.parse(event.data));
            });
        } else {
            this.sock.addEventListener("message", function (event) {
                console.log(event);
            });
        }

        if (onError) {
            this.sock.addEventListener("error", function (event) {
                onError(JSON.parse(event.data));
            });
        } else {
            this.sock.addEventListener("error", function (event) {
                console.log(event);
            });
        }
    }

    send(message) {
        if (this.sock.readyState === WebSocket.CLOSED) {
            this.sock = new WebSocket(url);

            this.connect(sendMessage);
        } else {
            this.sock.send(JSON.stringify(message));
        }
    }

    alive() {
        this.connect(null);
    }

    connect(callback) {
        setTimeout(
            function () {
                try {
                    if (1 === this.sock.readyState) {
                        if (callback != null) {
                            callback();
                        }
                    } else {
                        this.connect(callback);
                    }
                } catch (e) { }
            }, 50);
    }
}