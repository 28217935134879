<template>
  <div class="modal fade" tabindex="-1" :id="id">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Ban User</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="alert alert-danger text-center">
                Are you sure you to remove <b>{{ name }}</b
                >?
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <button class="btn btn-secondary w-100" data-bs-dismiss="modal">
                Cancel
              </button>
            </div>
            <div class="col-md-6">
              <div class="btn btn-danger w-100" @click="remove_service">
                Confirm
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ModalOrganizationRemoveService",
  props: ["id"],
  data() {
    return {
      service_id: null,
      name: "",
    };
  },
  mounted() {
    this.emitter.on("organization_service_loaded", (data) => {
      this.service_id = data.service_id ? data.service_id : null;
      this.name = data.name ? data.name : "";
    });
  },
  methods: {
    remove_service() {
      axios
        .patch(
          process.env.VUE_APP_URL +
            "/organizations/" +
            this.$route.params.organization_id +
            "/services/" +
            this.service_id,
          {},
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then(
          (response) => {
            if (200 === response.status) {
              this.emitter.emit("organization_service_removed", this.name);
            } else {
            }
          },
          (error) => {
            console.log(error);
          }
        );
    },
  },
};
</script>