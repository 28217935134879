<template>
    <div class="modal fade" tabindex="-1" :id="id">
      <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Manage Collaborator</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body" v-if="member">
            <div class="row">
              <div class="col-12 mb-3">
                <label for="full_name" class="form-label">Member</label>
                <input
                  type="text"
                  :value="member.user.first_name + ' ' + member.user.last_name"
                  class="form-control"
                  id="full_name"
                  disabled
                />
              </div>
            </div>
  
            <div class="row">
              <div class="col-12 mb-3">
                <label class="form-label">Organization Role</label>
  
                <div
                  class="btn-group w-100"
                  role="group"
                  aria-label="Basic radio toggle button group"
                >
                  <input
                    v-model="member.role"
                    type="radio"
                    class="btn-check"
                    id="radioLimited"
                    value="limited"
                    autocomplete="off"
                  />
                  <label class="btn btn-outline-primary" for="radioLimited"
                    >Limited</label
                  >
  
                  <input
                    v-model="member.role"
                    type="radio"
                    class="btn-check"
                    id="radioMember"
                    value="member"
                    autocomplete="off"
                  />
                  <label class="btn btn-outline-primary" for="radioMember"
                    >Member</label
                  >
  
                  <input
                    v-model="member.role"
                    type="radio"
                    class="btn-check"
                    id="radioOwner"
                    value="owner"
                    autocomplete="off"
                  />
                  <label class="btn btn-outline-primary" for="radioOwner"
                    >Owner</label
                  >
                </div>
              </div>
            </div>
  
            <div class="row">
              <div class="col-12" v-if="member.role == 'limited'">
                <label class="form-label">Sites</label>
                <TreeView
                  id="treeViewSites"
                  v-bind:key="treeViewSites"
                  :treeData="sites"
                  :values="selected_sites"
                  :onUpdate="refreshMembership"
                />
              </div>
            </div>
  
            <div class="row">
              <div class="col-12">
                <label class="form-label">Services</label>
                <TreeView
                  id="treeViewServices"
                  v-bind:key="treeViewServices"
                  :treeData="services"
                  :values="selected_services"
                  :onUpdate="refreshMembership"
                />
              </div>
            </div>
  
            <div class="row">
              <div class="col-12">
                <button
                  type="button"
                  @click="update_membership()"
                  class="btn btn-primary w-100"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  import TreeView from "@/components/TreeView.vue";
  
  export default {
    name: "ModalOrganizationCollaborator",
    props: ["id", "sites", "services"],
    components: { TreeView },
    data() {
      return {
        member: { id: "", role: "", user: {}, sites: [], services: [] },
        sites: [],
        selected_sites: [],
        services: [],
        selected_services: [],
        current_page: 1,
      };
    },
    mounted() {
      this.emitter.on("manage_collaborator", (data) => {
        this.member.id = data.id;
        this.member.role = data.role;
        this.member.user = data.user;
  
        this.selected_sites = [];
        this.selected_services = [];
  
        axios
          .get(
            process.env.VUE_APP_URL +
              "/organizations/" +
              this.$route.params.organization_id +
              "/members/" +
              this.member.id +
              "/relationship",
            {
              headers: {
                Authorization: process.appdata.tokens.access_token,
              },
            }
          )
          .then((response) => {
            this.member.sites = response.data.sites;
            this.member.services = response.data.services;
  
            let selectedSites = [];
            response.data.sites.filter((site) => {
              selectedSites.push(site.id);
            });
            this.selected_sites = selectedSites;
  
            let selectedServices = [];
            response.data.services.filter((service) => {
              selectedServices.push(service);
            });
            this.selected_services = selectedServices;
          });
  
        this.fetch_sites();
        this.fetch_services();
      });
    },
    methods: {
      fetch_sites() {
        axios
          .get(
            process.env.VUE_APP_URL +
              "/organizations/" +
              this.$route.params.organization_id +
              "/sites?items=500&page=1",
            {
              headers: {
                Authorization: process.appdata.tokens.access_token,
              },
            }
          )
          .then((response) => {
            this.sites = response.data.data;
          });
      },
      fetch_services() {
        axios
          .get(
            process.env.VUE_APP_URL +
              "/organizations/" +
              this.$route.params.organization_id +
              "/services?items=500&page=1",
            {
              headers: {
                Authorization: process.appdata.tokens.access_token,
              },
            }
          )
          .then((response) => {
            this.services = response.data.data.map((item) => {
              item.children = item.roles;
  
              return item;
            });
          });
      },
      refreshMembership(data) {
        if ("treeViewServices" === data.id) {
          this.selected_services = data.values;
        }
  
        if ("treeViewSites" === data.id) {
          this.selected_sites = data.values;
        }
      },
  
      update_membership() {
        let data = {
          role: this.member.role,
          services: this.selected_services,
        };

        let app = this;
  
        if ("limited" === this.member.role) {
          data.sites = this.selected_sites;
        }
  
        axios
          .patch(
            process.env.VUE_APP_URL +
              "/organizations/" +
              this.$route.params.organization_id +
              "/members/" +
              this.member.id,
            data,
            {
              headers: {
                Authorization: process.appdata.tokens.access_token,
              },
            }
          )
          .then((response) => {
            if (200 === response.status) {
              this.emitter.emit("member_updated", this.user);
            } else {
            }
          })
          .catch(function (error) {
            if (error.response) {
              app.emitter.emit("popup_alert", {
                type: "danger",
                text: error.response.data.message,
              });
            } else {
              app.emitter.emit("popup_alert", {
                type: "danger",
                text: "Error occurred",
              });
            }
          });
      },
    },
    watch: {
      role() {
        if (this.role == "limited") {
          this.fetch_sites(this.current_page);
        }
      },
    },
  };
  </script>
  