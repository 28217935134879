<template>
  <div class="card">
    <div class="card-header bg-light">
      <i class="bi bi-app-indicator me-2"></i>
      Overview
    </div>
    <div class="card-body">
      <ul class="list-group">
        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">Service Photo:</div>
          <div class="w-75 py-2 px-3">
            <img
              :src="service.logo"
              :alt="service.name"
              class="align-self-center"
              width="36"
              height="36"
              onerror="this.src='/images/window-sidebar.svg';"
            />
            <button
              type="button"
              class="btn btn-link p-0 float-end text-decoration-none"
              data-bs-toggle="modal"
              data-bs-target="#serviceLogo"
            >
              Update
            </button>
          </div>
        </li>
        <li class="list-group-item d-flex p-0 border-0 mt-1">
          <div class="w-25 py-2 px-3 bg-light">Display Name:</div>
          <div class="w-75 py-2 px-3">
            {{ service.name }}
            <button
              type="button"
              class="btn btn-link p-0 float-end text-decoration-none"
              data-bs-toggle="modal"
              data-bs-target="#serviceDetails"
            >
              Update
            </button>
          </div>
        </li>
        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">Description:</div>
          <div class="w-75 py-2 px-3">{{ service.description }}</div>
        </li>

        <li class="list-group-item d-flex p-0 border-0 mt-1">
          <div class="w-25 py-2 px-3 bg-light">Client ID:</div>
          <div class="w-75 py-2 px-3">
            {{ service.id }}
            <button
              type="button"
              class="btn btn-link p-0 float-end text-decoration-none"
              @click="copy_secret"
            >
              Copy
            </button>
          </div>
        </li>
        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">Client Secret:</div>
          <div class="w-75 py-2 px-3">
            ***********
            <button
              class="btn btn-link text-decoration-none p-0 float-end text-danger"
              data-bs-toggle="modal"
              data-bs-target="#serviceResetSecret"
            >
              Reset
            </button>
          </div>
        </li>

        <li class="list-group-item d-flex p-0 border-0 mt-1">
          <div class="w-25 py-2 px-3 bg-light">Redirect URI:</div>
          <div class="w-75 py-2 px-3">
            {{ service.redirect_uri }}
            <button
              type="button"
              class="btn btn-link p-0 float-end text-decoration-none"
              data-bs-toggle="modal"
              data-bs-target="#serviceDetails"
            >
              Update
            </button>
          </div>
        </li>

        <li class="list-group-item d-flex p-0 border-0 mt-1">
          <div class="w-25 py-2 px-3 bg-light">Public Service:</div>
          <div class="w-75 py-2 px-3">
            {{ service.type ? "Public" : "Confidential" }}
            <div
              class="
                float-end
                form-check form-switch form-control-lg
                p-0
                m-0
                d-flex
              "
            >
              <input
                class="form-check-input align-self-center m-0"
                type="checkbox"
                id="checkServiceType"
                v-model="service.type"
                @change="update_type"
              />
            </div>
          </div>
        </li>
        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">Consent Required:</div>
          <div class="w-75 py-2 px-3">
            {{ service.require_accept ? "Yes" : "No" }}
            <div
              class="
                float-end
                form-check form-switch form-control-lg
                p-0
                m-0
                d-flex
              "
            >
              <input
                class="form-check-input align-self-center m-0"
                type="checkbox"
                id="checkServiceAccept"
                v-model="service.require_accept"
                @change="update_require_accept"
              />
            </div>
          </div>
        </li>
        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">Eligibility for orgs:</div>
          <div class="w-75 py-2 px-3">
            {{ service.eligibility ? "Yes" : "No" }}
            <div
              class="
                float-end
                form-check form-switch form-control-lg
                p-0
                m-0
                d-flex
              "
            >
              <input
                class="form-check-input align-self-center m-0"
                type="checkbox"
                id="checkServiceEligibility"
                v-model="service.eligibility"
                @change="update_eligibility"
              />
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="card mt-2">
    <ul class="list-group list-group-flush">
      <li class="list-group-item">
        <div class="d-flex align-items-center">
          <div class="flex-shrink-0">
            <i class="bi bi-exclamation-triangle-fill fs-4 text-danger"></i>
          </div>
          <div class="flex-grow-1 ms-3">
            <b class="text-dark">Delete this Service</b>
            <p class="small text-muted mb-0">
              Once you delete a service, there is no going back. Please be
              certain.
            </p>
          </div>
          <button
            type="button"
            class="btn btn-outline-danger"
            data-bs-toggle="modal"
            data-bs-target="#serviceDelete"
          >
            Delete Service
          </button>
        </div>
      </li>
    </ul>
  </div>

  <ModalServiceLogo id="serviceLogo" />
  <ModalServiceDetails id="serviceDetails" />
  <ModalServiceDelete id="serviceDelete" />
  <ModalServiceResetSecret id="serviceResetSecret" />
</template>

<script>
import axios from "axios";
import moment from "moment";

import ModalServiceLogo from "@/components/modals/ModalServiceLogo.vue";
import ModalServiceDetails from "@/components/modals/ModalServiceDetails.vue";
import ModalServiceDelete from "@/components/modals/ModalServiceDelete.vue";
import ModalServiceResetSecret from "@/components/modals/ModalServiceResetSecret";

import Utils from "@/helpers/utils";

export default {
  name: "ServiceOverview",
  components: {
    ModalServiceLogo,
    ModalServiceDetails,
    ModalServiceDelete,
    ModalServiceResetSecret,
  },
  data() {
    return {
      service: {
        name: "",
        description: "",
        redirect_uri: "",
        type: false,
        require_accept: false,
        eligibility: false
      },
    };
  },
  mounted() {
    this.emitter.on("service_logo_updated", () => {
      this.emitter.emit("popup_alert", {
        type: "success",
        text: "Service logo was succesfuly updated!",
      });

      this.fetch_service();
    });

    this.emitter.on("service_details_updated", () => {
      this.emitter.emit("popup_alert", {
        type: "success",
        text: "Service details were succesfuly updated!",
      });

      this.fetch_service();
    });

    this.emitter.on("service_deleted", () => {
      this.emitter.emit("popup_alert", {
        type: "success",
        text: "Service was succesfuly deleted!",
      });

      window.location.href = "/admin/services";
    });

    this.emitter.on("service_secret_reset", () => {
      this.emitter.emit("popup_alert", {
        type: "success",
        text: "Service secret was succesfuly reset!",
      });

      let serviceResetSecretModal = bootstrap.Modal.getInstance(
        document.getElementById("serviceResetSecret")
      );
      serviceResetSecretModal.hide();
    });

    this.fetch_service();
  },
  methods: {
    fetch_service() {
      axios
        .get(
          process.env.VUE_APP_URL +
            "/services/" +
            this.$route.params.service_id,
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then((response) => {
          if (200 === response.status) {
            this.service = response.data;
            this.service.type = "public" === response.data.type ? true : false;

            this.emitter.emit("service_details_loaded", this.service);
          } else {
            window.location.href = "/404";
          }
        });
    },
    timeAgo(dateString) {
      return Utils.timeAgo(dateString);
    },
    capitalizeText(text, allwords) {
      return Utils.capitalizeText(text, allwords);
    },

    update_type() {
      this.update_service();
    },
    update_require_accept() {
      this.update_service();
    },
    update_eligibility() {
      this.update_service();
    },

    update_service() {
      let app = this;

      axios
        .patch(
          process.env.VUE_APP_URL +
            "/services/" +
            this.$route.params.service_id,
          {
            name: this.service.name,
            description: this.service.description,
            redirect_uri: this.service.redirect_uri,
            type: this.service.type ? "public" : "confidential",
            require_accept: this.service.require_accept,
            eligibility: this.service.eligibility,
          },
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then(
          (response) => {
            if (200 === response.status) {
              app.fetch_service();
            } else {
            }
          },
          (error) => {
            console.log(error);
          }
        );
    },
    copy_secret() {
      var app = this;

      navigator.clipboard.writeText(this.$route.params.service_id).then(
        function () {
          app.emitter.emit("popup_alert", {
            type: "success",
            text: "Service ID was successfully copied to clipboard!",
          });
        },
        function (err) {
          app.emitter.emit("popup_alert", {
            type: "warning",
            text: "Your browser does not allow copying to clipboard!",
          });
        }
      );
    },
  },
};
</script>
