<template>
  <div class="modal fade" tabindex="-1" :id="id">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Remove user from organisation</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="alert alert-danger text-center">
                Are you sure you to remove <b>{{ full_name }}</b> from
                <b>{{ organization_name }}</b
                >?
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <button class="btn btn-secondary w-100" data-bs-dismiss="modal">
                Cancel
              </button>
            </div>
            <div class="col-md-6">
              <div class="btn btn-danger w-100" @click="remove_user">Confirm</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ModalUserRemoveOrganization",
  props: ["id"],
  data() {
    return {
      user_id: null,
      full_name: "",
      organization_id: "",
      organization_name: "",
      membership_id: "",
    };
  },
  mounted() {
    this.emitter.on("user_details_loaded", (data) => {
      this.user_id = data.id ? data.id : null;
      this.full_name = data.full_name ? data.full_name : "";
      this.organization_id = data.organization ? data.organization.id : "";
      this.organization_name = data.organization ? data.organization.name : "";
      this.membership_id = data.membership_id ? data.membership_id : "";
    });
  },
  methods: {
    remove_user() {
      axios
        .delete(
          process.env.VUE_APP_URL +
            "/organizations/" + this.organization_id + "/members/" + this.membership_id,
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then(
          (response) => {
            if (200 === response.status) {
              this.emitter.emit("user_removed_organization");
            } else {
            }
          },
          (error) => {
            console.log(error);
          }
        );
    },
  },
};
</script>