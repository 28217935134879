<template>
  <Breadcrumbs :breaditems="[{ title: 'Dashboard' }]" />
  <div id="main" class="container">
    <div class="d-flex bd-highlight">
      <div class="flex-grow-1 me-2">
        <div class="input-group">
          <button class="input-group-text bg-white" @click="fetch_services()">
            <i class="bi bi-search"></i>
          </button>
          <input
            type="text"
            class="form-control"
            v-model="filter.name"
            v-on:keyup.enter="fetch_services()"
            placeholder="Search..."
          />
        </div>
      </div>
      <div class="bd-highlight">
        <div class="btn-group" role="group" aria-label="Services">
          <button
            type="button"
            :class="
              'list' === display
                ? 'btn bg-white border text-black'
                : 'btn btn-primary'
            "
            @click="displayGrid()"
          >
            <i class="bi bi-grid-3x3-gap"></i>
          </button>
          <button
            type="button"
            :class="
              'grid' === display
                ? 'btn bg-white border text-black'
                : 'btn btn-primary'
            "
            @click="displayList()"
          >
            <i class="bi bi-list-ul"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="card mt-2" v-if="'list' === display">
      <div class="card-header bg-light">Services</div>

      <div v-if="0 === services.length" class="card-body">
        <div class="text-center">
          <img src="/images/empty-services.svg" class="img-fluid py-5" />
        </div>
      </div>

      <div v-else class="list-group list-group-flush">
        <a
          v-for="service in services"
          v-bind:key="service.id"
          :href="service.redirect_uri"
          target="_blank"
          class="list-group-item list-group-item-action"
        >
          <div class="row">
            <div class="col-10">
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                  <img
                    :src="service.logo"
                    class="m-auto"
                    width="24"
                    onerror="this.src='/images/window-sidebar.svg';"
                  />
                </div>
                <div class="flex-grow-1 ms-3">
                  <b class="text-dark">{{ service.name }}</b>
                  <p class="small text-muted mb-0">{{ service.description }}</p>
                </div>
              </div>
            </div>
            <div class="col d-flex">
              <i class="bi bi-box-arrow-in-right align-self-center ms-auto"></i>
            </div>
          </div>
        </a>
      </div>
      <div class="card-footer bg-light">
        <pagination
          v-if="paginator.current_page"
          :records="paginator.total_items"
          :per-page="10"
          v-model="current_page"
          :options="paginatorOptions"
        ></pagination>
      </div>
    </div>

    <div
      class="row row-cols-1 row-cols-md-6 g-2 mt-0"
      v-if="'grid' === display"
    >
      <div class="col mt-2" v-for="service in services" v-bind:key="service.id">
        <div class="card" style="min-height: 200px">
          <div class="card-body text-center">
            <div class="h-50 d-inline-block w-100">
              <img
                :src="service.logo"
                class="m-auto mt-4 mb-2 fs-2"
                width="32"
                onerror="this.src='/images/window-sidebar.svg';"
              />
            </div>
            <div class="h-25 d-inline-block w-100 d-flex">
              <div class="align-self-center mx-auto">
                <b class="text-dark">{{ service.name }}</b>
                <p class="small text-muted">{{ service.description }}</p>
              </div>
            </div>
            <div class="h-25 d-inline-block w-100 d-flex">
              <a
                class="align-self-center mx-auto btn btn-sm btn-primary w-100"
                :href="service.redirect_uri"
                target="_blank"
                >Open App</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Pagination from "v-pagination-3";

import Breadcrumbs from "@/components/Breadcrumbs.vue";
import RTPagination from "@/components/RTPagination.vue";

export default {
  name: "Dashboard",
  components: { Breadcrumbs, Pagination, RTPagination },
  data() {
    return {
      paginatorOptions: {
        template: RTPagination,
      },
      paginator: {},
      services: [],
      filter: {
        name: "",
      },
      current_page: 1,
      display: "grid",
    };
  },
  mounted() {
    this.fetch_services();
  },
  methods: {
    fetch_services() {
      axios
        .get(
          process.env.VUE_APP_URL +
            "/me/services?page=" +
            this.current_page +
            "&filter[client.name]=" +
            encodeURIComponent(this.filter.name),
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then((response) => {
          if (200 === response.status) {
            this.services = response.data.data;
            this.paginator = response.data.paginator;
          } else {
            process.appdata.logout();
          }
        });
    },
    displayList() {
      this.display = "list";
    },
    displayGrid() {
      this.display = "grid";
    },
  },
  watch: {
    current_page: function () {
      this.fetch_services();
    },
  },
};
</script>
