<template>
  <div class="d-flex bd-highlight">
    <div class="flex-grow-1 me-2">
      <div class="input-group">
        <button
          v-on:click="fetch_service_roles()"
          type="button"
          class="input-group-text bg-white"
        >
          <i class="bi bi-search"></i>
        </button>
        <input
          type="text"
          v-model="filter.name"
          class="form-control"
          placeholder="Search..."
          v-on:keyup.enter="fetch_service_roles()"
        />
      </div>
    </div>
    <div class="bd-highlight">
      <button
        type="button"
        class="btn float-end bg-white border"
        data-bs-toggle="modal"
        data-bs-target="#newServiceRole"
      >
        New Role
      </button>
    </div>
  </div>
  <div class="card mt-2">
    <div class="card-header bg-light">
      <i class="bi bi-people me-2"></i>
      Roles
    </div>
    <ul class="list-group list-group-flush">
      <li v-for="role in roles" v-bind:key="role.id" class="list-group-item">
        <div class="d-flex align-items-center">
          <div class="flex-shrink-0">
            <i class="bi bi-person-circle fs-4"></i>
          </div>
          <div class="flex-grow-1 ms-3">
            <b class="text-dark">{{ capitalizeText(role.name) }}</b>
            <p class="small text-muted mb-0">
              {{ role.description || "&nbsp;" }}
            </p>
          </div>

          <div class="dropstart">
            <button
              type="button"
              class="btn btn-primary"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="bi bi-gear-fill mr-1"></i>
              Options
            </button>
            <div class="dropdown-menu">
              <h6 class="dropdown-header">Role Options</h6>
              <a
                href="#"
                class="btn dropdown-item"
                @click="openEditModal(role.id, role.name, role.description)"
                >Edit role <i class="far fa-user-edit float-right ml-3 mt-1"></i
              ></a>
              <div class="dropdown-divider"></div>
              <a
                href="#"
                class="btn dropdown-item text-danger"
                @click="openRemoveModal(role.id, role.name)"
                >Remove role <i class="bi bi-person-dash-fill float-end"></i
              ></a>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <div class="card-footer bg-light">
      <pagination
        v-if="paginator.current_page"
        :records="paginator.total_items"
        :per-page="10"
        v-model="current_page"
        :options="paginatorOptions"
      ></pagination>
    </div>
  </div>

  <ModalServiceNewRole id="newServiceRole" />
  <ModalServiceUpdateRole id="updateServiceRole" />
  <ModalServiceDeleteRole id="deleteServiceRole" />
</template>

<script>
import axios from "axios";
import moment from "moment";
import Pagination from "v-pagination-3";

import RTPagination from "@/components/RTPagination.vue";
import ModalServiceNewRole from "@/components/modals/ModalServiceNewRole.vue";
import ModalServiceUpdateRole from "@/components/modals/ModalServiceUpdateRole.vue";
import ModalServiceDeleteRole from "@/components/modals/ModalServiceDeleteRole.vue";

import Utils from "@/helpers/utils";

export default {
  name: "ServiceRoles",
  components: {
    Pagination,
    RTPagination,
    ModalServiceNewRole,
    ModalServiceUpdateRole,
    ModalServiceDeleteRole,
  },
  data() {
    return {
      paginatorOptions: {
        template: RTPagination,
      },
      paginator: {},
      roles: [],
      filter: {
        name: "",
      },
      current_page: 1,
    };
  },
  mounted() {
    this.emitter.on("service_role_added", () => {
      let newServiceRoleModal = bootstrap.Modal.getInstance(
        document.getElementById("newServiceRole")
      );
      newServiceRoleModal.hide();

      this.emitter.emit("popup_alert", {
        type: "success",
        text: "Service role was succesfuly added!",
      });

      this.fetch_service_roles();
    });

    this.emitter.on("service_role_updated", () => {
      let updateServiceRoleModal = bootstrap.Modal.getInstance(
        document.getElementById("updateServiceRole")
      );
      updateServiceRoleModal.hide();

      this.emitter.emit("popup_alert", {
        type: "success",
        text: "Service role was succesfuly updated!",
      });

      this.fetch_service_roles();
    });

    this.emitter.on("service_role_deleted", () => {
      let deleteServiceRoleModal = bootstrap.Modal.getInstance(
        document.getElementById("deleteServiceRole")
      );
      deleteServiceRoleModal.hide();

      this.emitter.emit("popup_alert", {
        type: "success",
        text: "Service role was succesfuly deleted!",
      });

      this.fetch_service_roles();
    });

    this.fetch_service_roles();
  },
  methods: {
    fetch_service_roles() {
      axios
        .get(
          process.env.VUE_APP_URL +
            "/services/" +
            this.$route.params.service_id +
            "/roles?items=10&page=" +
            this.current_page,
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then((response) => {
          if (200 === response.status) {
            this.roles = response.data.data;
            this.paginator = response.data.paginator;
          } else {
            process.appdata.logout();
          }
        });
    },

    openRemoveModal(role_id, role_name) {
      this.emitter.emit("remove_role", {
        id: role_id,
        name: role_name,
      });

      var deleteServiceRoleModal = new bootstrap.Modal(
        document.getElementById("deleteServiceRole"),
        null
      );
      deleteServiceRoleModal.show();
    },

    timeAgo(dateString) {
      return Utils.timeAgo(dateString);
    },
    capitalizeText(text, allwords) {
      return Utils.capitalizeText(text, allwords);
    },
    openEditModal(id, name, description) {
      this.emitter.emit("edited_role_loaded", {
        id: id,
        name: name,
        description: description,
      });

      var updateServiceRole = new bootstrap.Modal(
        document.getElementById("updateServiceRole"),
        null
      );
      updateServiceRole.show();
    },
  },
};
</script>
