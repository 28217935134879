<template>
  <div class="modal fade" tabindex="-1" :id="id">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">New Service</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body" v-if="!secret_key">
          <div class="row">
            <div class="col-12 mb-3">
              <label for="name" class="form-label">Name</label>
              <input type="text" class="form-control" v-model="name" />
            </div>
          </div>

          <div class="row">
            <div class="col-12 mb-3">
              <label for="name" class="form-label">Description</label>
              <input type="text" class="form-control" v-model="description" />
            </div>
          </div>

          <div class="row">
            <div class="col-6 mb-3">
              <label class="form-label">Service type</label>

              <div
                class="btn-group w-100"
                role="group"
                aria-label="Basic radio toggle button group"
              >
                <input
                  v-model="type"
                  type="radio"
                  class="btn-check"
                  value="public"
                  name="radioType"
                  id="radioPublic"
                  autocomplete="off"
                />
                <label class="btn btn-outline-primary" for="radioPublic"
                  >Public</label
                >

                <input
                  v-model="type"
                  type="radio"
                  class="btn-check"
                  value="confidential"
                  name="radioType"
                  id="radioConfidential"
                  autocomplete="off"
                />
                <label class="btn btn-outline-primary" for="radioConfidential"
                  >Confidential</label
                >
              </div>
            </div>
            <div class="col-6 mb-3">
              <label class="form-label">Consent Required</label>

              <div
                class="btn-group w-100"
                role="group"
                aria-label="Basic radio toggle button group"
              >
                <input
                  v-model="require_accept"
                  type="radio"
                  class="btn-check"
                  value="yes"
                  name="radioRequireAccept"
                  id="radioYes"
                  autocomplete="off"
                />
                <label class="btn btn-outline-primary" for="radioYes"
                  >Yes</label
                >

                <input
                  v-model="require_accept"
                  type="radio"
                  class="btn-check"
                  value="no"
                  name="radioRequireAccept"
                  id="radioNo"
                  autocomplete="off"
                />
                <label class="btn btn-outline-primary" for="radioNo">No</label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6 mb-3">
              <label class="form-label">Eligibility for organizations</label>

              <div
                class="btn-group w-100"
                role="group"
                aria-label="Basic radio toggle button group"
              >
                <input
                  v-model="eligibility"
                  type="radio"
                  class="btn-check"
                  value="on"
                  name="radioEligibility"
                  id="radioOn"
                  autocomplete="off"
                />
                <label class="btn btn-outline-primary" for="radioOn">On</label>

                <input
                  v-model="eligibility"
                  type="radio"
                  class="btn-check"
                  value="off"
                  name="radioEligibility"
                  id="radioOff"
                  autocomplete="off"
                />
                <label class="btn btn-outline-primary" for="radioOff"
                  >Off</label
                >
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 mb-3">
              <label class="form-label">Redirect URI</label>
              <input type="url" v-model="redirect_uri" class="form-control" />
            </div>
          </div>

          <div class="row">
            <div class="col-12 mb-3">
              <button
                type="button"
                @click="create_service()"
                class="btn btn-primary w-100"
              >
                Create Service
              </button>
            </div>
          </div>
        </div>

        <div class="modal-body" v-else>
          <div class="row">
            <div class="col-12 mb-3">
              <div class="alert alert-info" role="alert">
                The client secret is leaked and it will not be displayed here
                anymore.
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 mb-3">
              <label class="form-label">Client Secret</label>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control text-monospace"
                  v-model="secret_key"
                  disabled=""
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary"
                    @click="copy_secret"
                  >
                    <i class="fad fa-copy"></i> Copy
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <button class="btn btn-primary w-100" @click="service_created">
                Got it and browse app now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ModalServiceNew",
  data() {
    return {
      name: null,
      description: null,
      type: null,
      redirect_uri: null,
      service_id: null,
      secret_key: null,
      eligibility: null,
      require_accept: null,
    };
  },
  methods: {
    create_service() {
      axios
        .post(
          process.env.VUE_APP_URL + "/services",
          {
            name: this.name,
            description: this.description,
            type: this.type,
            redirect_uri: this.redirect_uri,
            eligibility: "on" === this.eligibility ? true : false,
            require_accept: "yes" === this.require_accept ? true : false,
          },
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then((response) => {
          this.service_id = response.data.id;

          axios
            .patch(
              process.env.VUE_APP_URL +
                "/services/" +
                response.data.id +
                "/secret",
              {},
              {
                headers: {
                  Authorization: process.appdata.tokens.access_token,
                },
              }
            )
            .then((res) => {
              this.secret_key = res.data.secret;
            });
        })
        .catch((error) => {
          this.emitter.emit("popup_alert", {
            type: "danger",
            text: "Error! " + error.response.data.error_description,
          });
        });
    },
    copy_secret() {
      navigator.clipboard.writeText(this.secret_key).then(
        function () {
          this.emitter.emit("popup_alert", {
            type: "success",
            text: "Service secret was successfully copied to clipboard!",
          });
        },
        function (err) {
          this.emitter.emit("popup_alert", {
            type: "warning",
            text: "Your browser does not allow copying to clipboard!",
          });
        }
      );
    },
    service_created() {
      this.emitter.emit("service_created", {});
    },
  },
};
</script>
