<template>
  <div class="modal fade" tabindex="-1" :id="id">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Update Avatar</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div id="modalDropArea">
            <img
              src="/images/card-image.svg"
              width="32"
              height="32"
              class="mb-5"
            />
            <p class="text-primary lead">Upload a file or drag and drop</p>
            <p class="text-secondary lead">PNG, JPG, up to 10MB</p>

            <input type="file" id="modalDropTarget" :onchange="selectImage" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ModalMyAvatar",
  props: ["id"],
  data() {
    return {};
  },
  methods: {
    selectImage(ev) {
      var app = this;

      let url =
        process.env.VUE_APP_URL +
        "/me/picture";

      var fileObj = ev.target.files[0],
        reader = new FileReader();

      reader.onloadend = function (blob) {
        var data = new FormData();
        data.append("logo", fileObj);

        axios
          .post(url, data, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: process.appdata.tokens.access_token,
            },
          })
          .then(
            (response) => {
              if (200 === response.status) {
                app.emitter.emit("user_avatar_updated");
              } else {
              }
            },
            (error) => {
              console.log(error);
            }
          );
      };

      var blob = fileObj.slice(0, fileObj.size);
      reader.readAsBinaryString(blob);
    },
  },
};
</script>