<template>
  <div class="modal fade" tabindex="-1" :id="id">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Revoke All Sessions</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="alert alert-danger text-center">
                Are you sure you to revoke all your session? You will be logged
                out from all devices.
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <button class="btn btn-secondary w-100" data-bs-dismiss="modal">
                Cancel
              </button>
            </div>
            <div class="col-md-6">
              <div class="btn btn-danger w-100" @click="revoke_all_sessions">
                Confirm
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ModalMySessionsRevoke",
  props: ["id"],
  data() {
    return {};
  },
  mounted() {},
  methods: {
    revoke_all_sessions() {
      axios
        .delete(process.env.VUE_APP_URL + "/sessions/", {
          headers: {
            Authorization: process.appdata.tokens.access_token,
          },
        })
        .then((response) => {
          if (200 === response.status) {
            process.appdata.logout();
          } else {
            process.appdata.logout();
          }
        });
    },
  },
};
</script>