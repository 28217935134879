<template>
  <div class="modal fade" tabindex="-1" :id="id">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Reset Service Secret</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body" v-if="!secret_code">
          <div class="row">
            <div class="col-12">
              <div class="alert alert-danger text-center">
                Are you sure you to reset the secret of <b>{{ name }}</b
                >?
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <button class="btn btn-secondary w-100" data-bs-dismiss="modal">
                Cancel
              </button>
            </div>
            <div class="col-md-6">
              <div class="btn btn-danger w-100" @click="reset_secret">
                Confirm
              </div>
            </div>
          </div>
        </div>
        <div class="modal-body" v-else>
          <div class="row">
            <div class="col-12 mb-3">
              <label class="form-label">Client Secret</label>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control text-monospace"
                  v-model="secret_code"
                  disabled=""
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary"
                    @click="copy_secret"
                  >
                    <i class="fad fa-copy"></i> Copy
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <button
                class="btn btn-primary w-100"
                @click="service_secret_reset"
              >
                Got it and browse app now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ModalServiceResetSecret",
  props: ["id"],
  data() {
    return {
      service_id: null,
      name: "",
      secret_code: null,
    };
  },
  mounted() {
    this.emitter.on("service_details_loaded", (data) => {
      this.service_id = data.id ? data.id : null;
      this.name = data.name ? data.name : "";
    });
  },
  methods: {
    reset_secret() {
      axios
        .patch(
          process.env.VUE_APP_URL +
            "/services/" +
            this.$route.params.service_id +
            "/secret",
          {},
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then(
          (response) => {
            if (200 === response.status) {
              this.secret_code = response.data.secret;
            } else {
            }
          },
          (error) => {
            console.log(error);
          }
        );
    },
    copy_secret() {
      let app = this;

      navigator.clipboard.writeText(this.secret_code).then(
        function () {
          app.emitter.emit("popup_alert", {
            type: "success",
            text: "Service secret was successfully copied to clipboard!",
          });
        },
        function (err) {
          app.emitter.emit("popup_alert", {
            type: "warning",
            text: "Your browser does not allow copying to clipboard!",
          });
        }
      );
    },
    service_secret_reset() {
      this.emitter.emit("service_secret_reset", {});
    },
  },
};
</script>