<template>
    <div class="modal fade" tabindex="-1" :id="id">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">New User</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12 mb-3">
                            <div class="form-group">
                                <label for="email" class="form-label">Email</label>
                                <input type="text" id="email" v-model="user.email" class="form-control" @keyup="validate" />
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6 mb-3">
                            <div class="form-group">
                                <label for="first_name" class="form-label">First name</label>
                                <input type="text" id="first_name" v-model="user.first_name" class="form-control"
                                    @keyup="validate" />
                            </div>
                        </div>

                        <div class="col-md-6 mb-3">
                            <div class="form-group">
                                <label for="last_name" class="form-label">Last name</label>
                                <input type="text" id="last_name" v-model="user.last_name" class="form-control"
                                    @keyup="validate" />
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12 mb-3">
                            <div class="form-group">
                                <label for="job_title" class="form-label">Job title</label>
                                <input type="text" id="job_title" v-model="user.job_title" class="form-control"
                                    @keyup="validate" />
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 mb-3">
                            <div class="form-group" v-if="organisations">
                                <label for="organisation" class="form-label">Organisation</label>
                                <select class="form-control" id="organisation" v-model="user.organisation"
                                    @change="validate">
                                    <option disabled value="">Please select</option>
                                    <option v-for="organisation in organisations" :key="organisation.id"
                                        :value="organisation.id">
                                        {{ organisation.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6 mb-3">
                            <div class="form-group">
                                <label for="type" class="form-label">Type</label>
                                <select id="type" v-model="user.type" class="form-control" @change="validate">
                                    <option value="">Please select</option>
                                    <option value="admin">Administrator</option>
                                    <option value="user">User</option>
                                </select>
                            </div>
                        </div>

                        <div class="col-md-6 mb-3">
                            <div class="form-group">
                                <label for="role" class="form-label">Role</label>
                                <select id="role" v-model="user.role" class="form-control" @change="validate">
                                    <option value="">Please select</option>
                                    <option value="member">Member</option>
                                    <option value="collaborator">Collaborator</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6 mb-3">
                            <div class="form-group">
                                <label for="password" class="form-label">Password</label>
                                <input type="password" id="password" v-model="user.password" class="form-control"
                                    @keyup="validate" />
                            </div>
                            <small class="text-secondary"><i>Must be at least 8 characters</i></small>
                        </div>

                        <div class="col-md-6 mb-3">
                            <div class="form-group">
                                <label for="password_confirmation" class="form-label">Password Confirmation</label>
                                <input type="password" id="password_confirmation" v-model="user.password_confirmation"
                                    class="form-control" @keyup="validate" />
                            </div>
                            <small class="text-secondary"><i>Must be at least 8 characters</i></small>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <button type="button" @click="create_user()" class="btn btn-primary w-100"
                                :disabled="!valid_input">
                                Add User
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "ModalUserNew",
    props: ["id"],
    data() {
        return {
            user: {
                email: "",
                first_name: "",
                last_name: "",
                job_title: "",
                password: "",
                password_confirmation: "",
                organisation: "",
                type: "",
                role: ""
            },
            organisations: [],
            valid_input: false,
        };
    },
    mounted() {
        this.fetch_organizations();
    },
    methods: {
        validate() {
            let validEmail =
                String(this.user.email)
                    .toLowerCase()
                    .match(
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    );

            if (null !== validEmail
                && 3 <= this.user.first_name.trim().length
                && 3 <= this.user.last_name.trim().length
                && "" !== this.user.job_title.trim()
                && "" !== this.user.organisation
                && "" !== this.user.type
                && "" !== this.user.role
                && 8 <= this.user.password.length
                && this.user.password === this.user.password_confirmation
            ) {
                this.valid_input = true;
            } else {
                this.valid_input = false;
            }
        },
        fetch_organizations() {
            let url =
                process.env.VUE_APP_URL +
                "/organizations?items=1000&order=name_asc";
            axios
                .get(url, {
                    headers: {
                        Authorization: process.appdata.tokens.access_token,
                    },
                })
                .then((response) => {
                    if (200 === response.status) {
                        this.organisations = response.data.data;
                    } else {
                        process.appdata.logout();
                    }
                });
        },
        create_user() {
            axios
                .post(
                    process.env.VUE_APP_URL +
                    "/admin/users",
                    this.user,
                    {
                        headers: {
                            Authorization: process.appdata.tokens.access_token,
                        },
                    }
                )
                .then((response) => {
                    this.emitter.emit("popup_alert", {
                        type: "success",
                        text: "User was successfully created!",
                    });

                    this.user = {
                        email: "",
                        first_name: "",
                        last_name: "",
                        job_title: "",
                        password: "",
                        password_confirmation: "",
                        organisation: "",
                        type: "",
                        role: ""
                    };

                    jQuery("#userNew").modal("hide");
                })
                .catch((error) => {
                    this.emitter.emit("popup_alert", {
                        type: "danger",
                        text: error.response.data.message,
                    });
                });
        }
    },
};
</script>