<template>
  <div class="modal fade" tabindex="-1" :id="id">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Update Organization Address</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-6 mb-3">
              <div class="form-group">
                <label for="post_code" class="form-label">Post Code</label>
                <input
                  type="text"
                  id="post_code"
                  v-model="post_code"
                  class="form-control text-uppercase"
                />
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <div class="form-group">
                <label for="post_code" class="form-label">&nbsp;</label>
                <button
                  type="button"
                  @click="fetch_address(post_code)"
                  class="btn btn-primary form-control"
                >
                  Find Address
                </button>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 mb-3">
              <div class="form-group" v-if="addresses">
                <label for="selected_address" class="form-label"
                  >Address selector</label
                >
                <select
                  class="form-control"
                  id="selected_address"
                  v-model="selected_address"
                  @change="fill_with_selected($event.target.selectedIndex)"
                >
                  <option disabled value="" selected>Please select one</option>
                  <option
                    v-for="adr in addresses"
                    :key="adr.line_1 + adr.line_2"
                  >
                    {{ adr.line_1 + ", " + adr.line_2 + ", " + adr.town }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 mb-3">
              <div class="form-group">
                <label for="address_line1" class="form-label"
                  >Address line 1</label
                >
                <input
                  type="text"
                  id="address_line1"
                  v-model="address_line1"
                  class="form-control"
                />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 mb-3">
              <div class="form-group">
                <label for="address_line2" class="form-label"
                  >Address line 2</label
                >
                <input
                  type="text"
                  id="address_line2"
                  v-model="address_line2"
                  class="form-control"
                />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 mb-3">
              <div class="form-group">
                <label class="form-label">City</label>
                <input type="text" v-model="city" class="form-control" />
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <div class="form-group">
                <label class="form-label">County</label>
                <input type="text" v-model="county" class="form-control" />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <button
                type="button"
                @click="update_organization()"
                class="btn btn-primary w-100"
                :disabled="!valid_input"
              >
                Update Organization Address
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import axios from "axios";

export default {
  name: "ModalOrganizationAddress",
  props: ["id"],
  data() {
    return {
      post_code: "",
      address_line1: "",
      address_line2: "",
      city: "",
      county: "",
      addresses: null,
      selected_address: "",
      valid_input: false
    };
  },
  mounted() {
    this.emitter.on("organization_details_loaded", (data) => {
      this.post_code = data.post_code ? data.post_code : "";
      this.address_line1 = data.address_line1 ? data.address_line1 : "";
      this.address_line2 = data.address_line2 ? data.address_line2 : "";
      this.city = data.city ? data.city : "";
      this.county = data.county ? data.county : "";

      this.validate();
    });
  },
  methods: {
    validate() {
      this.valid_input = this.post_code.length >= 6;
    },

    update_organization() {
      let app = this;

      axios
        .patch(
          process.env.VUE_APP_URL +
            "/organizations/" +
            this.$route.params.organization_id,
          {
            post_code: this.post_code,
            address_line1: this.address_line1,
            address_line2: this.address_line2,
            city: this.city,
            county: this.county,
          },
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then(
          (response) => {
            if (200 === response.status) {
              app.emitter.emit("organization_address_updated");
            } else {
            }
          },
          (error) => {
            console.log(error);
          }
        );
    },
    fetch_address() {
      axios
        .get(process.env.VUE_APP_URL + "/address/" + this.post_code, {
          headers: {
            Authorization: process.appdata.tokens.access_token,
          },
        })
        .then((response) => {
          if (response.data.results.length > 0) {
            this.addresses = response.data.results;
          } else {
            this.addresses = null;
          }
        });
    },
    fill_with_selected(index) {
      var selected_adress = this.addresses[index - 1];
      this.address_line1 = selected_adress.line_1;
      this.address_line2 = selected_adress.line_2;
      this.city = selected_adress.town;
      this.county = selected_adress.county;
    },
  },
};
</script>