<template>
  <div class="modal fade" tabindex="-1" :id="id">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Update Email</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12 mb-3">
              <label for="emailOld" class="form-label"
                >Current Email Address</label
              ><input
                type="text"
                id="emailOld"
                minlength="3"
                maxlength="50"
                class="form-control"
                v-model="email"
                @keyup="validate"
                disabled
              />
            </div>
          </div>

          <div class="row">
            <div class="col-12 mb-3">
              <label for="emailNew">New Email Address</label
              ><input
                type="text"
                id="emailNew"
                maxlength="50"
                class="form-control"
                v-model="password_confirmation"
                @keyup="validate"
              />
              <small class="text-secondary">
                <i
                  >A confirmation will be required on both emails for this
                  change to take effect.</i
                >
              </small>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <button
                type="button"
                class="btn btn-primary w-100"
                :disabled="!valid_input"
                @click="update_user"
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ModalSiteNew",
  props: ["id"],
  data() {
    return {
      email: "",
      new_email: "",
      valid_input: false,
    };
  },
  mounted() {
    this.emitter.on("user_details_loaded", (data) => {
      this.email = data.email ? data.email : "";

      this.validate();
    });
  },
  methods: {
    validate() {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      this.valid_input = re.test(String(this.email).toLowerCase());
    },

    update_user() {
      let app = this;

      axios
        .patch(
          process.env.VUE_APP_URL +
            "/admin/users/" +
            this.$route.params.user_id +
            "/email",
          {
            email: this.new_email,
          },
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then(
          (response) => {
            if (200 === response.status) {
              app.emitter.emit("user_email_updated");
            } else {
            }
          },
          (error) => {
            console.log(error);
          }
        );
    },
  },
};
</script>