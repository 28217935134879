<template>
  <div class="card">
    <div class="card-header bg-light d-flex">
      <i class="bi bi-app-indicator me-2"></i>
      Overview
      <span class="ms-auto text-muted small">{{
        "Active " + timeAgo(user.last_activity.created_at)
      }}</span>
    </div>
    <div class="card-body">
      <ul class="list-group">
        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-person-circle me-3"></i>Profile Photo:
          </div>
          <div class="w-75 py-2 px-3">
            <img
              :src="user.picture"
              :alt="user.full_name"
              class="rounded-circle align-self-center"
              width="21"
              height="21"
              onerror="this.src='/images/person-circle.svg';"
            /><button
              type="button"
              class="btn btn-link p-0 float-end text-decoration-none"
              data-bs-toggle="modal"
              data-bs-target="#userAvatar"
            >
              Update
            </button>
          </div>
        </li>
        <li class="list-group-item d-flex p-0 border-0 mt-1">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-person-badge me-3"></i>First Name:
          </div>
          <div class="w-75 py-2 px-3">
            {{ user.first_name }}
            <button
              type="button"
              class="btn btn-link p-0 float-end text-decoration-none"
              data-bs-toggle="modal"
              data-bs-target="#userDetails"
            >
              Update
            </button>
          </div>
        </li>
        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-person-badge me-3"></i>Last Name:
          </div>
          <div class="w-75 py-2 px-3">{{ user.last_name }}</div>
        </li>
        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-person-badge-fill me-3"></i>Job Title:
          </div>
          <div class="w-75 py-2 px-3">{{ user.job_title }}</div>
        </li>
        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-telephone me-3"></i>Phone Number:
          </div>
          <div class="w-75 py-2 px-3">{{ user.phone_number }}</div>
        </li>

        <li class="list-group-item d-flex p-0 border-0 mt-1">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-envelope me-3"></i>Email:
          </div>
          <div class="w-75 py-2 px-3">
            {{ user.email }}
            <button
              type="button"
              class="btn btn-link p-0 float-end text-decoration-none"
              data-bs-toggle="modal"
              data-bs-target="#userEmail"
              v-if="parseInt(user.google_id) > 0 && !user.privacy.email"
            >
              Update
            </button>
          </div>
        </li>

        <li class="list-group-item d-flex p-0 border-0 mt-1">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-shield-lock me-3"></i>Password:
          </div>
          <div class="w-75 py-2 px-3">
            ***********
            <button
              type="button"
              class="btn btn-link p-0 float-end text-decoration-none"
              data-bs-toggle="modal"
              data-bs-target="#userPassword"
            >
              Update
            </button>
          </div>
        </li>

        <li class="list-group-item d-flex p-0 border-0 mt-1">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-google me-3"></i>Google Sign-in:
          </div>
          <div class="w-75 py-2 px-3">{{ user.google_id }}</div>
        </li>

        <li class="list-group-item d-flex p-0 border-0 mt-1">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-globe me-3"></i>Timezone:
          </div>
          <div class="w-75 py-2 px-3">
            {{ readableTimezone(user.timezone) }}
            <button
              type="button"
              class="btn btn-link p-0 float-end text-decoration-none"
              data-bs-toggle="modal"
              data-bs-target="#userTimezone"
            >
              Update
            </button>
          </div>
        </li>

        <li class="list-group-item d-flex p-0 border-0 mt-1">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-shield-shaded me-3"></i>Email Privacy:
          </div>
          <div class="w-75 py-2 px-3">
            {{ user.privacy.email ? "Yes" : "No" }}
            <div
              class="
                float-end
                form-check form-switch form-control-lg
                p-0
                m-0
                d-flex
              "
            >
              <input
                class="form-check-input align-self-center m-0"
                type="checkbox"
                id="flexSwitchCheckDefault"
                v-model="user.privacy.email"
                @change="update_privacy"
              />
            </div>
          </div>
        </li>
        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-shield-shaded me-3"></i>Phone Privacy:
          </div>
          <div class="w-75 py-2 px-3">
            {{ user.privacy.phone ? "Yes" : "No" }}
            <div
              class="
                float-end
                form-check form-switch form-control-lg
                p-0
                m-0
                d-flex
              "
            >
              <input
                class="form-check-input align-self-center m-0"
                type="checkbox"
                id="flexSwitchCheckDefault"
                v-model="user.privacy.phone"
                @change="update_privacy"
              />
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="card mt-2">
    <div class="card-header bg-light">
      <i class="bi bi-building me-2"></i>
      Organisation
    </div>
    <div class="card-body">
      <ul class="list-group">
        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-building me-3"></i>Name:
          </div>
          <div class="w-75 py-2 px-3">
            <router-link
              :to="'/admin/organisations/' + user.organization.id"
              class="text-decoration-none"
              >{{ user.organization.name }}</router-link
            >
            <button
              v-if="isAdmin && null !== user.membership_id"
              type="button"
              class="
                btn btn-link
                text-danger
                p-0
                float-end
                text-decoration-none
              "
              data-bs-toggle="modal"
              data-bs-target="#userRemoveOrganization"
            >
              Remove
            </button>
          </div>
        </li>
        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-person-square me-3"></i>Role:
          </div>
          <div class="w-75 py-2 px-3">
            <span
              v-if="user.role"
              class="badge bg-light text-dark border align-self-center me-1"
              >{{ capitalizeText(user.role) }}</span
            >
            <span class="badge bg-light text-dark border align-self-center"
              >{{ user.sites }} Sites</span
            >
            <button
              v-if="isAdmin && null !== user.membership_id"
              type="button"
              class="btn btn-link p-0 float-end text-decoration-none"
              @click="openManageModal"
            >
              Update
            </button>
          </div>
        </li>
      </ul>
    </div>
  </div>

  <div class="card mt-2">
    <div class="card-header bg-light">
      <i class="bi bi-calendar-check me-2"></i>
      Registration Info
    </div>
    <div class="card-body">
      <ul class="list-group">
        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-forward-fill me-3"></i>Invited By:
          </div>
          <div class="w-75 py-2 px-3">
            <router-link
              :to="'/admin/users/' + user.invitation.inviter_id"
              class="text-decoration-none"
              >{{ user.invitation.inviter_name }}</router-link
            >
            <router-link
              :to="'/admin/organisations/' + user.organization.id"
              class="float-end text-decoration-none"
              >{{ user.organization.name }}</router-link
            >
          </div>
        </li>
        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-forward-fill me-3"></i>Invited On:
          </div>
          <div class="w-75 py-2 px-3 small font-monospace align-self-center">
            {{
              formattedTimestamp(user.invitation.created_time) +
              " · " +
              timeAgo(user.invitation.created_time)
            }}
          </div>
        </li>
        <li class="list-group-item d-flex p-0 border-0 mt-1">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-patch-check-fill me-3"></i>Verified:
          </div>
          <div class="w-75 py-2 px-3">
            {{ null !== user.invitation.deleted_time ? "Yes" : "No" }}
          </div>
        </li>
        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-patch-check-fill me-3"></i>Verified on:
          </div>
          <div class="w-75 py-2 px-3 small font-monospace align-self-center">
            {{
              formattedTimestamp(user.invitation.deleted_time) +
              " · " +
              timeAgo(user.invitation.deleted_time)
            }}
          </div>
        </li>
        <li class="list-group-item d-flex p-0 border-0 mt-1">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-check-circle-fill me-3"></i>Status:
          </div>
          <div class="w-75 py-2 px-3">
            <span v-if="'active' === user.status" class="text-success fw-bold"
              >Active</span
            >
            <span v-else class="text-danger fw-bold">{{
              capitalizeText(user.status) +
              (user.status_description
                ? " (" + user.status_description + ")"
                : "")
            }}</span>
          </div>
        </li>
      </ul>
    </div>
  </div>

  <div class="card mt-2 mb-2">
    <div class="card-header bg-light">
      <i class="bi bi-shield-fill me-2"></i>
      Last Login Information
    </div>
    <div class="card-body">
      <ul class="list-group">
        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-circle-fill me-3"></i>Device:
          </div>
          <div
            class="
              w-75
              py-2
              px-3
              font-monospace
              small
              align-self-center
              overflow-hidden
            "
          >
            {{
              (user_agent.os.name || "") +
              " " +
              (user_agent.os.version || "") +
              " · " +
              (user_agent.browser.name || "") +
              " " +
              (user_agent.browser.version || "")
            }}
            <button
              type="button"
              class="btn btn-link p-0 float-end text-decoration-none"
              data-bs-toggle="modal"
              data-bs-target="#userAgent"
            >
              View Agent
            </button>
          </div>
        </li>
        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-circle-fill me-3"></i>IP Address:
          </div>
          <div class="w-75 py-2 px-3 font-monospace small align-self-center">
            {{ user.last_activity.ip_address }}
            <button
              type="button"
              class="btn btn-link p-0 float-end text-decoration-none"
              data-bs-toggle="modal"
              data-bs-target="#userLocation"
            >
              View Info
            </button>
          </div>
        </li>
        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-circle-fill me-3"></i>Hostname:
          </div>
          <div class="w-75 py-2 px-3 font-monospace small align-self-center">
            {{ user.last_activity.hostname }}
          </div>
        </li>
        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-circle-fill me-3"></i>Location:
          </div>
          <div class="w-75 py-2 px-3 font-monospace small align-self-center">
            {{
              (undefined === user.last_activity.location.city
                ? ""
                : user.last_activity.location.city + ", ") +
              (undefined === user.last_activity.location.stateprov
                ? ""
                : user.last_activity.location.stateprov + ", ") +
              (user.last_activity.location.country || "")
            }}
          </div>
        </li>
        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-circle-fill me-3"></i>Company:
          </div>
          <div class="w-75 py-2 px-3 font-monospace small align-self-center">
            {{ user.last_activity.location.organization_name }}
          </div>
        </li>
        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-circle-fill me-3"></i>Timestamp:
          </div>
          <div class="w-75 py-2 px-3 font-monospace small align-self-center">
            {{ timeAgo(user.last_activity.created_at) }}
          </div>
        </li>
      </ul>
    </div>
  </div>

  <div class="card mt-2 mb-2">
    <div class="card-header bg-light">
      <i class="bi bi-exclamation-circle-fill me-2"></i>
      Admin Features
    </div>
    <ul class="list-group list-group-flush">
      <li class="list-group-item">
        <div class="d-flex align-items-center">
          <div class="flex-shrink-0">
            <i class="bi bi-exclamation-triangle-fill fs-4 text-warning"></i>
          </div>
          <div class="flex-grow-1 ms-3">
            <b class="text-dark">Suspend User</b>
            <p class="small text-muted mb-0">
              User can only login but not perform any action or view any
              information. eg. compromised account.
            </p>
          </div>
          <button
            v-if="'suspended' === user.status"
            type="button"
            class="btn btn-outline-success"
            style="width: 130px"
            @click="changeUserStatus('active')"
          >
            Restore User
          </button>
          <button
            v-else
            type="button"
            class="btn btn-outline-warning"
            style="width: 130px"
            data-bs-toggle="modal"
            data-bs-target="#userSuspend"
          >
            Suspend User
          </button>
        </div>
      </li>
      <li class="list-group-item">
        <div class="d-flex align-items-center">
          <div class="flex-shrink-0">
            <i class="bi bi-exclamation-triangle-fill fs-4 text-danger"></i>
          </div>
          <div class="flex-grow-1 ms-3">
            <b class="text-dark">Disable User</b>
            <p class="small text-muted mb-0">
              The user cannot login and all his memberships are removed. Can be
              restored by admin or an invite to a new org.
            </p>
          </div>
          <button
            v-if="'inactive' === user.status"
            type="button"
            class="btn btn-outline-success"
            style="width: 130px"
            @click="changeUserStatus('active')"
          >
            Enable User
          </button>
          <button
            v-else
            type="button"
            class="btn btn-outline-danger"
            style="width: 130px"
            data-bs-toggle="modal"
            data-bs-target="#userDisable"
          >
            Disable User
          </button>
        </div>
      </li>
      <li class="list-group-item">
        <div class="d-flex align-items-center">
          <div class="flex-shrink-0">
            <i class="bi bi-exclamation-triangle-fill fs-4 text-danger"></i>
          </div>
          <div class="flex-grow-1 ms-3">
            <b class="text-dark">Ban User</b>
            <p class="small text-muted mb-0">
              The user cannot login and all his memberships are removed. This
              can only be reversed by an admin.
            </p>
          </div>

          <button
            v-if="'banned' === user.status"
            type="button"
            class="btn btn-outline-success"
            style="width: 130px"
            @click="changeUserStatus('active')"
          >
            Unban User
          </button>
          <button
            v-else
            type="button"
            class="btn btn-outline-danger"
            style="width: 130px"
            data-bs-toggle="modal"
            data-bs-target="#userBan"
          >
            Ban User
          </button>
        </div>
      </li>
    </ul>
  </div>

  <ModalUserAvatar id="userAvatar" />
  <ModalUserDetails id="userDetails" />
  <ModalUserPassword id="userPassword" />
  <ModalUserEmail id="userEmail" />
  <ModalUserTimezone id="userTimezone" />
  <ModalUserRemoveOrganization id="userRemoveOrganization" />
  <ModalOrganizationUser id="organizationUser" />

  <ModalUserAgent id="userAgent" />
  <ModalUserLocation id="userLocation" />

  <ModalUserBan id="userBan" />
  <ModalUserSuspend id="userSuspend" />
  <ModalUserDisable id="userDisable" />
</template>

<script>
import axios from "axios";
import moment from "moment";
import UAParser from "ua-parser-js";

import ModalUserAvatar from "@/components/modals/ModalUserAvatar.vue";
import ModalUserDetails from "@/components/modals/ModalUserDetails.vue";
import ModalUserPassword from "@/components/modals/ModalUserPassword.vue";
import ModalUserEmail from "@/components/modals/ModalUserEmail.vue";
import ModalUserTimezone from "@/components/modals/ModalUserTimezone.vue";

import ModalUserRemoveOrganization from "@/components/modals/ModalUserRemoveOrganization.vue";
import ModalOrganizationUser from "@/components/modals/ModalOrganizationUser.vue";

import ModalUserAgent from "@/components/modals/ModalUserAgent.vue";
import ModalUserLocation from "@/components/modals/ModalUserLocation.vue";
import ModalUserBan from "@/components/modals/ModalUserBan.vue";
import ModalUserSuspend from "@/components/modals/ModalUserSuspend.vue";
import ModalUserDisable from "@/components/modals/ModalUserDisable.vue";

import Utils from "@/helpers/utils";

export default {
  name: "UserOverview",
  components: {
    ModalUserAvatar,
    ModalUserDetails,
    ModalUserPassword,
    ModalUserEmail,
    ModalUserTimezone,
    ModalUserRemoveOrganization,
    ModalOrganizationUser,
    ModalUserAgent,
    ModalUserLocation,
    ModalUserBan,
    ModalUserSuspend,
    ModalUserDisable,
  },
  data() {
    return {
      isAdmin:
        "admin" === process.appdata.user.type ||
        (process.appdata.user.rootOrganization &&
          process.env.VUE_APP_RT_NUMBER ===
            process.appdata.user.rootOrganization.company_number)
          ? true
          : false,
      user: {
        full_name: "",
        organization: {},
        privacy: {
          email: false,
          phone: false,
        },
        invitation: {},
        last_activity: { created_at: "", ip_address: "", location: {} },
        membership_id: "",
      },
      user_agent: {
        device: {},
        browser: {},
        os: {},
        ua: "",
        device_type: "",
      },
    };
  },
  mounted() {
    this.emitter.on("user_avatar_updated", () => {
      let userAvatarModal = bootstrap.Modal.getInstance(
        document.getElementById("userAvatar")
      );
      userAvatarModal.hide();

      this.emitter.emit("popup_alert", {
        type: "success",
        text: "User avatar was succesfuly updated!",
      });

      this.fetch_user();
    });

    this.emitter.on("user_details_updated", () => {
      let userDetailsModal = bootstrap.Modal.getInstance(
        document.getElementById("userDetails")
      );
      userDetailsModal.hide();

      this.emitter.emit("popup_alert", {
        type: "success",
        text: "User details were succesfuly updated!",
      });

      this.fetch_user();
    });

    this.emitter.on("user_password_updated", () => {
      let userPasswordModal = bootstrap.Modal.getInstance(
        document.getElementById("userPassword")
      );
      userPasswordModal.hide();

      this.emitter.emit("popup_alert", {
        type: "success",
        text: "User password was succesfuly changed!",
      });

      this.fetch_user();
    });

    this.emitter.on("user_email_updated", () => {
      let userEmailModal = bootstrap.Modal.getInstance(
        document.getElementById("userEmail")
      );
      userEmailModal.hide();

      this.emitter.emit("popup_alert", {
        type: "success",
        text: "User email was succesfuly changed!",
      });

      this.fetch_user();
    });

    this.emitter.on("user_timezone_updated", () => {
      let userTimezonesModal = bootstrap.Modal.getInstance(
        document.getElementById("userTimezone")
      );
      userTimezonesModal.hide();

      this.emitter.emit("popup_alert", {
        type: "success",
        text: "User timezone was succesfuly changed!",
      });

      this.fetch_user();
    });

    this.emitter.on("user_removed_organization", () => {
      let userRemoveOrganizationModal = bootstrap.Modal.getInstance(
        document.getElementById("userRemoveOrganization")
      );
      userRemoveOrganizationModal.hide();

      this.emitter.emit("popup_alert", {
        type: "success",
        text: "User was succesfuly removed from the organisation!",
      });

      this.fetch_user();
    });

    this.emitter.on("user_suspended", () => {
      let userSuspendModal = bootstrap.Modal.getInstance(
        document.getElementById("userSuspend")
      );
      userSuspendModal.hide();

      this.emitter.emit("popup_alert", {
        type: "success",
        text: "User was succesfuly suspended!",
      });

      this.fetch_user();
    });

    this.emitter.on("user_banned", () => {
      let userBanModal = bootstrap.Modal.getInstance(
        document.getElementById("userBan")
      );
      userBanModal.hide();

      this.emitter.emit("popup_alert", {
        type: "success",
        text: "User was succesfuly banned!",
      });

      this.fetch_user();
    });

    this.emitter.on("user_disabled", () => {
      let userDisableModal = bootstrap.Modal.getInstance(
        document.getElementById("userDisable")
      );
      userDisableModal.hide();

      this.emitter.emit("popup_alert", {
        type: "success",
        text: "User was succesfuly disabled!",
      });

      this.fetch_user();
    });

    this.emitter.on("member_updated", () => {
      let userOrganizationModal = bootstrap.Modal.getInstance(
        document.getElementById("organizationUser")
      );
      userOrganizationModal.hide();

      this.emitter.emit("popup_alert", {
        type: "success",
        text: "The membership of the user was succesfuly updated!",
      });

      this.fetch_user();
    });

    this.fetch_user();
  },
  methods: {
    fetch_user() {
      axios
        .get(process.env.VUE_APP_URL + "/users/" + this.$route.params.user_id, {
          headers: {
            Authorization: process.appdata.tokens.access_token,
          },
        })
        .then((response) => {
          if (200 === response.status) {
            this.user = response.data;

            this.emitter.emit("user_details_loaded", this.user);

            const parser = new UAParser(this.user.last_activity.user_agent);
            this.user_agent = parser.getResult();
            this.user_agent.device_type = this.user.last_activity.device_type;

            this.emitter.emit("user_agent_loaded", this.user_agent);

            this.user.last_activity.location.ip_address =
              this.user.last_activity.ip_address;
            this.emitter.emit(
              "user_location_loaded",
              this.user.last_activity.location
            );
          } else {
            process.appdata.logout();
          }
        });
    },

    openManageModal() {
      this.emitter.emit("manage_member", {
        membership_id: this.user.membership_id,
        role: this.user.role,
        user: this.user,
      });

      var updateOrganizationMember = new bootstrap.Modal(
        document.getElementById("organizationUser"),
        null
      );
      updateOrganizationMember.show();
    },

    update_privacy() {
      axios
        .patch(
          process.env.VUE_APP_URL +
            "/admin/users/" +
            this.$route.params.user_id +
            "/privacy",
          {
            privacy: {
              email: this.user.privacy.email,
              phone: this.user.privacy.phone,
            },
          },
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then(
          (response) => {
            if (200 === response.status) {
              this.fetch_user();

              this.emitter.emit("user_privacy_updated", this.user);
            } else {
            }
          },
          (error) => {
            console.log(error);
          }
        );
    },
    changeUserStatus(status) {
      axios
        .patch(
          process.env.VUE_APP_URL +
            "/admin/users/" +
            this.$route.params.user_id +
            "/change-status",
          {
            status: status,
          },
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then(
          (response) => {
            if (200 === response.status) {
              this.emitter.emit("popup_alert", {
                type: "success",
                text: 'User status was succesfuly changed to "' + status + '"!',
              });

              this.fetch_user();
            } else {
            }
          },
          (error) => {
            console.log(error);
          }
        );
    },
    capitalizeText(text, allwords) {
      return Utils.capitalizeText(text, allwords);
    },
    readableTimezone(timezoneCode) {
      return Utils.readableTimezone(timezoneCode);
    },
    timeAgo(dateString) {
      return Utils.timeAgo(dateString);
    },
    formattedTimestamp(date) {
      return moment(this.localMachineTime(date)).format("HH:mm:ss DD/MM/YY");
    },
    localReadableTime(dateString) {
      return Utils.localReadableTime(dateString);
    },
    localMachineTime(dateString) {
      return Utils.localMachineTime(dateString);
    },
  },
};
</script>
