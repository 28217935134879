<template>
  <div class="d-flex bd-highlight">
    <div class="flex-grow-1 me-2">
      <div class="input-group">
        <button
          v-on:click="fetch_user_sites()"
          type="button"
          class="input-group-text bg-white"
        >
          <i class="bi bi-search"></i>
        </button>
        <input
          type="text"
          class="form-control"
          placeholder="Search..."
          v-model="filter.site_name"
          v-on:keyup.enter="fetch_user_sites()"
        />
      </div>
    </div>
    <div class="bd-highlight">
      <select class="form-select" v-model="filter.organization_id">
        <option value="">All Organisations ({{ organizations.length }})</option>
        <option
          v-for="organization in organizations"
          v-bind:key="organization.id"
          :value="organization.id"
        >
          {{ organization.name }}
        </option>
      </select>
    </div>
  </div>

  <div class="card mt-2">
    <div class="card-header bg-light">
      <i class="bi bi-geo me-2"></i>
      Sites
    </div>

    <div v-if="0 === sites.length" class="card-body">
      <div class="text-center">
        <img src="/images/empty-sites.svg" class="img-fluid py-5" />
      </div>
    </div>

    <div v-else class="list-group list-group-flush">
      <router-link
        v-for="site in sites"
        v-bind:key="site.id"
        :to="'/sites/' + site.id"
        class="list-group-item list-group-item-action"
      >
        <div class="row">
          <div class="col d-flex">
            <div class="d-flex align-items-center">
              <div class="flex-shrink-0">
                <i class="bi bi-geo fs-4"></i>
              </div>
              <div class="flex-grow-1 ms-3">
                <b class="text-dark">{{ site.name }}</b>
                <p class="small text-muted mb-0">
                  <span>{{
                    site.address_line1 +
                    ", " +
                    site.city +
                    ", " +
                    site.country +
                    ", " +
                    site.post_code
                  }}</span>
                </p>
              </div>
            </div>
          </div>
          <div class="col-4 d-flex">
            <div class="d-block text-end me-3 ms-auto">
              <b class="text-dark">{{ site.organization.name }}</b>
              <p class="small text-muted mb-0">
                <span>{{ site.organization.industry }}</span>
              </p>
            </div>
            <i class="bi bi-chevron-right align-self-center"></i>
          </div>
        </div>
      </router-link>
    </div>
    <div class="card-footer bg-light">
      <pagination
        v-if="paginator.current_page"
        :records="paginator.total_items"
        :per-page="10"
        v-model="current_page"
        :options="paginatorOptions"
      ></pagination>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Pagination from "v-pagination-3";

import RTPagination from "@/components/RTPagination.vue";

import Utils from "@/helpers/utils";

export default {
  name: "UserSites",
  components: { Pagination, RTPagination },
  data() {
    return {
      paginatorOptions: {
        template: RTPagination,
      },
      paginator: {},
      filter: {
        site_name: "",
        organization_id: "",
      },
      sites: [],
      organizations: [],
      current_page: 1,
      totals: {},
    };
  },
  mounted() {
    this.fetch_user_sites();
  },
  methods: {
    fetch_user_sites() {
      axios
        .get(
          process.env.VUE_APP_URL +
            "/admin/users/" +
            this.$route.params.user_id +
            "/sites?items=10&page=" +
            this.current_page +
            "&filter[site.name]=" +
            encodeURIComponent(this.filter.site_name) +
            "&filter[organization.id]=" +
            this.filter.organization_id,
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then((response) => {
          if (200 === response.status) {
            let item;
            this.organizations = [];

            this.sites = response.data.data;
            this.paginator = response.data.paginator;

            for (item in response.data.organizations) {
              this.organizations.push({
                id: item,
                name: response.data.organizations[item],
              });
            }
          } else {
            process.appdata.logout();
          }
        });
    },
    capitalizeText(text, allwords) {
      return Utils.capitalizeText(text, allwords);
    },
  },
  watch: {
    "filter.organization_id": function () {
      this.fetch_user_sites();
    },
    current_page: function () {
      this.fetch_user_sites();
    },
  },
};
</script>