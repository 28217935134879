<template>
  <div class="modal fade" tabindex="-1" :id="id">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">New Site</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12 mb-3">
              <div class="form-group">
                <label for="name" class="form-label">Site name</label>
                <input type="text" id="name" v-model="name" class="form-control" @keyup="validate" />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 mb-3">
              <div class="form-group">
                <label for="description" class="form-label">Description</label>
                <input type="text" id="description" v-model="description" class="form-control" />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 mb-3">
              <div class="form-group">
                <label for="post_code" class="form-label">Post Code</label>
                <input type="text" id="post_code" v-model="post_code" class="form-control text-uppercase"
                  @keyup="validate" />
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <div class="form-group">
                <label for="post_code" class="form-label">&nbsp;</label>
                <button type="button" @click="fetch_address(post_code)" class="btn btn-primary form-control">
                  Find Address
                </button>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 mb-3">
              <div class="form-group" v-if="addresses">
                <label for="selected_address" class="form-label">Address selector</label>
                <select class="form-control" id="selected_address" v-model="selected_address"
                  @change="fill_with_selected($event.target.selectedIndex)">
                  <option disabled value="">Please select one</option>
                  <option v-for="adr in addresses" :key="adr.line_1 + adr.line_2">
                    {{ adr.line_1 + ", " + adr.line_2 + ", " + adr.town }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 mb-3">
              <div class="form-group">
                <label for="address_line1" class="form-label">Address line 1</label>
                <input type="text" id="address_line1" v-model="address_line1" class="form-control" />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 mb-3">
              <div class="form-group">
                <label for="address_line2" class="form-label">Address line 2</label>
                <input type="text" id="address_line2" v-model="address_line2" class="form-control" />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 mb-3">
              <div class="form-group">
                <label class="form-label">City</label>
                <input type="text" v-model="city" class="form-control" />
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <div class="form-group">
                <label class="form-label">County</label>
                <input type="text" v-model="county" class="form-control" />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 mb-3">
              <label for="latLong" class="form-label">Latitude / Longitude</label>
              <input type="text" id="latLong" minlength="3" maxlength="50" class="form-control"
                placeholder="1.2345, 6.789" v-model="lat_long" @keyup="validate" />
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <button type="button" @click="create_site()" class="btn btn-primary w-100" :disabled="!valid_input">
                Add Site
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ModalSiteNew",
  props: ["id"],
  data() {
    return {
      name: "",
      description: "",
      post_code: "",
      address_line1: "",
      address_line2: "",
      city: "",
      county: "",
      addresses: null,
      lat_long: "",
      latitude: "",
      longitude: "",
      selected_address: "",
      valid_input: false,
    };
  },
  mounted() {
    this.validate();
  },
  methods: {
    validate() {
      if ("" !== this.lat_long.trim()) {
        let coords = this.lat_long.match(/[\-|\d|\.]+/g);
        this.latitude = parseFloat(coords[0]);
        this.longitude = parseFloat(coords[1]);

        this.valid_input = this.name.length > 3
          && this.post_code.length >= 6
          && this.latitude >= -90
          && this.latitude <= 90
          && this.longitude >= -180
          && this.longitude <= 180;
      } else {
        this.latitude = "";
        this.longitude = "";

        this.valid_input = this.name.length > 3 && this.post_code.length >= 6;
      }
    },

    fetch_address() {
      axios
        .get(process.env.VUE_APP_URL + "/address/" + this.post_code, {
          headers: {
            Authorization: process.appdata.tokens.access_token,
          },
        })
        .then((response) => {
          if (response.data.results.length > 0) {
            this.addresses = response.data.results;
          } else {
            this.addresses = null;
          }
        });
    },
    fill_with_selected(index) {
      var selected_adress = this.addresses[index - 1];
      this.address_line1 = selected_adress.line_1;
      this.address_line2 = selected_adress.line_2;
      this.city = selected_adress.town;
      this.county = selected_adress.county;

      this.validate();
    },

    create_site() {
      axios
        .post(
          process.env.VUE_APP_URL +
          "/organizations/" +
          this.$route.params.organization_id +
          "/sites",
          {
            name: this.name,
            description: this.description,
            address_line1: this.address_line1,
            address_line2: this.address_line2,
            city: this.city,
            county: this.county,
            post_code: this.post_code,
            latitude: this.latitude,
            longitude: this.longitude,
            created_by: process.appdata.user.id,
          },
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then((response) => {
          if (200 === response.status) {
            this.emitter.emit("organization_site_added", this.name);
          } else {
          }

          this.name = null;
          this.description = null;
          this.post_code = null;
          this.address_line1 = null;
          this.address_line2 = null;
          this.city = null;
          this.county = null;
          this.addresses = null;
          this.selected_address = "";
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>