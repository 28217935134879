<template>
  <div class="d-flex bd-highlight">
    <div class="flex-grow-1 me-2">
      <div class="input-group">
        <button
          v-on:click="fetch_site_members()"
          type="button"
          class="input-group-text bg-white"
        >
          <i class="bi bi-search"></i>
        </button>
        <input
          type="text"
          class="form-control"
          placeholder="Search..."
          v-model="filter.name"
          v-on:keyup.enter="fetch_site_members()"
        />
      </div>
    </div>
    <div class="bd-highlight">
      <select class="form-select" v-model="filter.role">
        <option value="">{{ "All Users (" + totals.all + ")" }}</option>
        <option :value="totals.owner.id">
          {{ "Owners (" + totals.owner.count + ")" }}
        </option>
        <option :value="totals.member.id">
          {{ "Members (" + totals.member.count + ")" }}
        </option>
        <option :value="totals.limited.id">
          {{ "Limited Members (" + totals.limited.count + ")" }}
        </option>
      </select>
    </div>
  </div>
  <div class="card mt-2">
    <div class="card-header bg-light">
      <i class="bi bi-people-fill me-2"></i>
      Members
    </div>

    <div v-if="0 === members.length" class="card-body">
      <div class="text-center">
        <img src="/images/empty-users.svg" class="img-fluid py-5" />
      </div>
    </div>

    <div v-else class="list-group list-group-flush">
      <div
        v-for="member in members"
        v-bind:key="member.membership.id"
        class="list-group-item"
      >
        <div class="row">
          <div class="col-4">
            <a class="d-flex align-items-center text-decoration-none" href="#">
              <div class="flex-shrink-0">
                <img
                  :src="member.user.picture"
                  :alt="member.user.first_name + member.user.last_name"
                  class="rounded-circle align-self-center"
                  width="36"
                  height="36"
                  onerror="this.src='/images/person-circle.svg';"
                />
              </div>
              <div class="flex-grow-1 ms-3">
                <b class="text-dark">{{
                  member.user.first_name + member.user.last_name
                }}</b>
                <p class="small text-muted mb-0">
                  <span>{{ member.user.email }}</span>
                </p>
              </div>
            </a>
          </div>
          <div class="col-2 d-flex">
            <div class="align-self-center">
              <span
                class="badge bg-light text-dark border w-100 d-block mb-1"
                >{{ capitalizeText(member.type) }}</span
              >
              <span class="badge bg-light text-dark border w-100 d-block"
                >All Sites</span
              >
            </div>
          </div>
          <div class="col-3 d-flex">
            <div class="align-self-center">
              <p class="mb-0">
                <span class="badge bg-primary">{{
                  member.rootOrganization.name
                }}</span>
              </p>
              <p class="mb-0">
                <span class="badge bg-secondary">{{
                  member.user.job_title
                }}</span>
              </p>
            </div>
          </div>
          <div class="col-3 d-flex pe-0">
            <div class="d-block text-end me-3 ms-auto">
              <p class="small text-muted mb-0"><span>Last Active</span></p>
              <b class="text-dark text-muted small">{{
                timeAgo(member.user.last_activity)
              }}</b>
            </div>
            <div class="align-self-center mx-3">
              <div class="dropstart">
                <button
                  type="button"
                  class="btn btn-link"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i class="bi bi-gear-fill mr-1"></i>
                </button>

                <div class="dropdown-menu" style="width: 250px">
                  <h6 class="dropdown-header">Member Options</h6>
                  <a
                    href="#"
                    class="btn dropdown-item"
                    @click="openManageModal(member.user)"
                    >Manage <i class="bi bi-gear-fill float-end"></i
                  ></a>

                  <router-link
                    :to="'/admin/users/' + member.user.id"
                    class="btn dropdown-item"
                    >View user in admin panel
                    <i class="bi bi-person-fill float-end"></i
                  ></router-link>

                  <div class="dropdown-divider"></div>
                  <a
                    href="#"
                    class="btn dropdown-item text-danger"
                    @click="openRemoveModal()"
                    >Remove from organization
                    <i class="bi bi-person-dash-fill float-end"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer bg-light">
      <pagination
        v-if="paginator.current_page"
        :records="paginator.total_items"
        :per-page="10"
        v-model="current_page"
        :options="paginatorOptions"
      ></pagination>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import Pagination from "v-pagination-3";

import RTPagination from "@/components/RTPagination.vue";

import Utils from "@/helpers/utils";

export default {
  name: "SiteMembers",
  components: { Pagination, RTPagination },
  data() {
    return {
      paginatorOptions: {
        template: RTPagination,
      },
      paginator: {},
      members: [],
      totals: {
        all: 0,
        owner: 0,
        member: 0,
        limited: 0,
      },
      filter: {
        name: "",
        role: "",
      },
      current_page: 1,
    };
  },
  mounted() {
    this.fetch_site_members();
  },
  methods: {
    fetch_site_members() {
      axios
        .get(
          process.env.VUE_APP_URL +
            "/sites/" +
            this.$route.params.site_id +
            "/members?role=" +
            this.filter.role +
            "&name=" +
            encodeURIComponent(this.filter.name) +
            "&items=10&page=" +
            this.current_page,
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then((response) => {
          if (200 === response.status) {
            this.members = response.data.data;
            this.paginator = response.data.paginator;

            this.totals = response.data.totals;
          } else {
            process.appdata.logout();
          }
        });
    },
    capitalizeText(text, allwords) {
      return Utils.capitalizeText(text, allwords);
    },
    timeAgo(dateString) {
      return Utils.timeAgo(dateString);
    },
    localReadableTime(dateString) {
      return Utils.localReadableTime(dateString);
    },
    localMachineTime(dateString) {
      return Utils.localMachineTime(dateString);
    },
  },
  watch: {
    "filter.role": function () {
      this.current_page = 1;
      this.fetch_site_members();
    },
    current_page: function () {
      this.fetch_site_members();
    },
  },
};
</script>
