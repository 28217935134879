<template>
  <Breadcrumbs
    :breaditems="[
      { title: 'Organisations', link: '/organisations' },
      {
        title: site.stats.organization_name,
        link: '/organisations/' + site.stats.organization_id,
      },
      { title: site.stats.name },
    ]"
  />
  <div class="container">
    <div class="row g-2">
      <div class="col-md-3 mt-2">
        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="flex-grow-1">
                <b class="text-dark">{{ site.stats.organization_name }}</b>
                <p class="small text-muted mb-0">
                  {{ site.stats.organization_industry }}
                </p>
              </div>
            </div>
          </div>
          <div class="list-group list-group-flush">
            <router-link
              :to="'/sites/' + site.id"
              :class="
                ('overview' === active_menu ? 'active ' : '') +
                'list-group-item list-group-item-action d-flex gap-2 align-items-center'
              "
              @click="setActiveMenu('overview')"
            >
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                  <i class="bi bi-app-indicator"></i>
                </div>
                <div class="flex-grow-1 ms-3">Overview</div>
              </div>
            </router-link>
            <router-link
              :to="'/sites/' + site.id + '/members'"
              :class="
                ('members' === active_menu ? 'active ' : '') +
                'list-group-item list-group-item-action d-flex gap-2 align-items-center'
              "
              @click="setActiveMenu('members')"
            >
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                  <i class="bi bi-people-fill"></i>
                </div>
                <div class="flex-grow-1 ms-3">Members</div>
              </div>
              <span class="badge bg-light ms-auto text-dark border fw-normal">{{
                site.stats.members
              }}</span>
            </router-link>
            <router-link
              :to="'/sites/' + site.id + '/collaborators'"
              :class="
                ('collaborators' === active_menu ? 'active ' : '') +
                'list-group-item list-group-item-action d-flex gap-2 align-items-center'
              "
              @click="setActiveMenu('collaborators')"
            >
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                  <i class="bi bi-people"></i>
                </div>
                <div class="flex-grow-1 ms-3">Collaborators</div>
              </div>
              <span class="badge bg-light ms-auto text-dark border fw-normal">{{
                site.stats.collaborators
              }}</span>
            </router-link>
            <router-link
              :to="'/sites/' + site.id + '/logs'"
              :class="
                ('logs' === active_menu ? 'active ' : '') +
                'list-group-item list-group-item-action d-flex gap-2 align-items-center'
              "
              @click="setActiveMenu('logs')"
            >
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                  <i class="bi bi-calendar-week"></i>
                </div>
                <div class="flex-grow-1 ms-3">Activity Logs</div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-md-9 mt-2">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import Breadcrumbs from "@/components/Breadcrumbs.vue";

export default {
  name: "SitePage",
  components: { Breadcrumbs },
  data() {
    return {
      active_menu: "overview",
      site: {
        id: this.$route.params.site_id,
        stats: {},
      },
    };
  },
  created() {
    this.active_menu = this.$route.meta.submenu;
  },
  mounted() {
    this.fetch_site_stats();
  },
  methods: {
    setActiveMenu(menu) {
      this.active_menu = menu;
    },
    fetch_site_stats() {
      axios
        .get(
          process.env.VUE_APP_URL +
            "/sites/" +
            this.$route.params.site_id +
            "/stats",
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then((response) => {
          if (200 === response.status) {
            this.site.stats = response.data;
          } else {
            process.appdata.logout();
          }
        });
    },
  },
};
</script>