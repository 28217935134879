<template>
  <li>
    <div v-if="node.children && 0 < node.children.length" class="tree-node text-nowrap">
      <i class="bi bi-square me-2"></i>
      <span class="form-label" v-on:click="toggleVisible">{{
        node.name
      }}</span>
      <ul class="tree-node-hidden">
        <TreeNode
          v-for="child in node.children"
          v-bind:key="child.id"
          :node="child"
          :onChildSelect="onChildSelect"
        ></TreeNode>
      </ul>
    </div>
    <div v-else class="text-nowrap">
      <input type="checkbox" class="form-check-input me-2" :id="node.id" v-on:change="onChildSelect" />
      <label :for="node.id" class="form-label">{{ node.name }}</label>
    </div>
  </li>
</template>

<script>
import TreeNode from "@/components/TreeNode.vue";

export default {
  name: "TreeNode",
  props: { node: TreeNode, onChildSelect: Function },
  components: { TreeNode },
  methods: {
    toggleVisible(ev) {
      const target = jQuery(ev.target);

      target.next().toggleClass("tree-node-hidden");
    },
  },
};
</script>