<template>
  <div class="modal fade" tabindex="-1" :id="id">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="siteLabel">New Organization</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12 mb-3">
              <label for="firstName" class="form-label"
                >Does the company you will add have a company number?</label
              >

              <div
                class="btn-group w-100"
                role="group"
                aria-label="Basic radio toggle button group"
              >
                <input
                  type="radio"
                  :value="true"
                  v-model="organization_exists"
                  class="btn-check"
                  id="radioYes"
                  name="btnradio"
                  autocomplete="off"
                  checked
                />
                <label class="btn btn-outline-primary" for="radioYes"
                  >Yes</label
                >

                <input
                  type="radio"
                  :value="false"
                  v-model="organization_exists"
                  class="btn-check"
                  id="radioNo"
                  name="btnradio"
                  autocomplete="off"
                />
                <label class="btn btn-outline-primary" for="radioNo">No</label>
              </div>
            </div>
          </div>

          <div v-if="organization_exists">
            <div class="row">
              <div class="col-6 mb-3">
                <div class="form-group">
                  <label class="form-label" for="companyNumber"
                    >Company Number</label
                  >
                  <input
                    type="text"
                    v-model="company_number"
                    class="form-control text-uppercase"
                    id="companyNumber"
                  />
                </div>
              </div>
              <div class="col-6 mb-3">
                <div class="form-group">
                  <label class="form-label">&nbsp;</label>
                  <button
                    type="button"
                    @click="fetch_company()"
                    class="btn btn-primary form-control"
                  >
                    Find Company
                  </button>
                </div>
              </div>
            </div>

            <div v-if="1 === join_request">
              <div class="row">
                <div class="col-12 mb-3">
                  <div class="form-group">
                    <label class="form-label" for="companyName"
                      >Company Name</label
                    >
                    <input
                      type="text"
                      v-model="name"
                      class="form-control text-uppercase"
                      id="companyName"
                      disabled="disabled"
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12 mb-3">
                  <div class="form-group">
                    <label class="form-label" for="description"
                      >Description</label
                    >
                    <input
                      type="text"
                      v-model="description"
                      id="description"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12 mb-3">
                  <div class="form-group">
                    <label class="form-label" for="industry">Industry</label>
                    <select
                      class="form-control"
                      v-model="industry"
                      v-on:change="validate"
                    >
                      <option value="">- -</option>
                      <option>Accommodation and Food Service Activities</option>
                      <option>
                        Administrative and Support Service Activities
                      </option>
                      <option>Agriculture, Forestry and Fishing</option>
                      <option>Arts, Entertainment and Recreation</option>
                      <option>Construction</option>
                      <option>Education</option>
                      <option>
                        Electricity, Gas, Steam and Air Conditioning Supply
                      </option>
                      <option>Financial and Insurance Activities</option>
                      <option>Human Health and Social Work Activities</option>
                      <option>Information and Communication</option>
                      <option>Manufacturing</option>
                      <option>Mining and Quarrying</option>
                      <option>Other Service Activities</option>
                      <option>
                        Professional, Scientific and Technical Activities
                      </option>
                      <option>Real Estate Activities</option>
                      <option>Transportation and Storage</option>
                      <option>Water Supply</option>
                      <option>
                        Sewerage, Waste Management and Remediation Activities
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-6 mb-3">
                  <div class="form-group">
                    <label class="form-label" for="postCode">Post Code</label>
                    <input
                      type="text"
                      v-model="post_code"
                      class="form-control text-uppercase"
                      id="postCode"
                    />
                  </div>
                </div>
                <div class="col-6 mb-3">
                  <div class="form-group">
                    <label class="form-label">&nbsp;</label>
                    <button
                      type="button"
                      @click="fetch_address(post_code)"
                      class="btn btn-primary form-control"
                    >
                      Find Address
                    </button>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12 mb-3">
                  <div class="form-group" v-if="addresses">
                    <label>Address selector</label>
                    <select
                      class="form-control"
                      v-model="selected_address"
                      @change="fill_with_selected($event.target.selectedIndex)"
                    >
                      <option disabled value="">Please select one</option>
                      <option
                        v-for="adr in addresses"
                        :key="adr.line_1 + adr.line_2"
                      >
                        {{ adr.line_1 + "," + adr.line_2 + "," + adr.town }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12 mb-3">
                  <div class="form-group">
                    <div class="form-group">
                      <label>Address line 1</label>
                      <input
                        type="text"
                        v-model="address_line1"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12 mb-3">
                  <div class="form-group">
                    <div class="form-group">
                      <label>Address line 2</label>
                      <input
                        type="text"
                        v-model="address_line2"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-6 mb-3">
                  <div class="form-group">
                    <label>City</label>
                    <input type="text" v-model="city" class="form-control" />
                  </div>
                </div>
                <div class="col-6 mb-3">
                  <div class="form-group">
                    <label>County</label>
                    <input type="text" v-model="county" class="form-control" />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12 mb-3">
                  <label for="type" class="form-label">Organization Type</label>
                  <select class="form-control" id="type" v-model="type">
                    <option value="">- -</option>
                    <option value="customer">Customer</option>
                    <option value="partner_certified">Certified Partner</option>
                    <option value="partner_premier">Premier Partner</option>
                    <option value="partner_elite">Elite Partner</option>
                  </select>
                </div>
              </div>

              <div v-if="'customer' === type" class="row">
                <div class="col-md-12 mb-3">
                  <label for="partner" class="form-label"
                    >Organization Partner</label
                  >
                  <select class="form-control" id="partner" v-model="partner">
                    <option value="">- -</option>
                    <option
                      v-for="organization in partners"
                      v-bind:key="organization.id"
                      :value="organization.id"
                    >
                      {{ organization.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <button
                    type="button"
                    @click="create_organization()"
                    class="btn btn-primary w-100"
                    :disabled="!valid_input"
                  >
                    Create Organization
                  </button>
                </div>
              </div>
            </div>

            <div v-if="2 === join_request">
              <div class="row">
                <div class="col-12 mb-3">
                  <div class="form-group">
                    <label class="form-label" for="companyName"
                      >Company Name</label
                    >
                    <input
                      type="text"
                      v-model="name"
                      class="form-control text-uppercase"
                      id="companyName"
                      disabled="disabled"
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12 mb-3">
                  <label for="type" class="form-label">Organization Type</label>
                  <select class="form-control" id="type" v-model="type">
                    <option value="">- -</option>
                    <option value="customer">Customer</option>
                    <option value="partner_certified">Certified Partner</option>
                    <option value="partner_premier">Premier Partner</option>
                    <option value="partner_elite">Elite Partner</option>
                  </select>
                </div>
              </div>

              <div v-if="'customer' === type" class="row">
                <div class="col-md-12 mb-3">
                  <label for="partner" class="form-label"
                    >Organization Partner</label
                  >
                  <select class="form-control" id="partner" v-model="partner">
                    <option value="">- -</option>
                    <option
                      v-for="organization in partners"
                      v-bind:key="organization.id"
                      :value="organization.id"
                    >
                      {{ organization.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <button
                    type="button"
                    @click="send_join()"
                    class="btn btn-primary w-100"
                  >
                    Join Organization
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div v-else>
            <div class="row">
              <div class="col-12 mb-3">
                <div class="form-group">
                  <label>Company Name</label>
                  <input
                    type="text"
                    v-model="name"
                    minlength="3"
                    class="form-control"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 mb-3">
                <div class="form-group">
                  <label class="form-label" for="description"
                    >Description</label
                  >
                  <input
                    type="text"
                    v-model="description"
                    id="description"
                    class="form-control"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 mb-3">
                <div class="form-group">
                  <label class="form-label" for="industry">Industry</label>
                  <select
                    class="form-control"
                    v-model="industry"
                    v-on:change="validate"
                  >
                    <option value="">- -</option>
                    <option>Accommodation and Food Service Activities</option>
                    <option>
                      Administrative and Support Service Activities
                    </option>
                    <option>Agriculture, Forestry and Fishing</option>
                    <option>Arts, Entertainment and Recreation</option>
                    <option>Construction</option>
                    <option>Education</option>
                    <option>
                      Electricity, Gas, Steam and Air Conditioning Supply
                    </option>
                    <option>Financial and Insurance Activities</option>
                    <option>Human Health and Social Work Activities</option>
                    <option>Information and Communication</option>
                    <option>Manufacturing</option>
                    <option>Mining and Quarrying</option>
                    <option>Other Service Activities</option>
                    <option>
                      Professional, Scientific and Technical Activities
                    </option>
                    <option>Real Estate Activities</option>
                    <option>Transportation and Storage</option>
                    <option>Water Supply</option>
                    <option>
                      Sewerage, Waste Management and Remediation Activities
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-6 mb-3">
                <div class="form-group">
                  <label class="form-label" for="postCode">Post Code</label>
                  <input
                    type="text"
                    v-model="post_code"
                    class="form-control text-uppercase"
                    id="postCode"
                  />
                </div>
              </div>
              <div class="col-6 mb-3">
                <div class="form-group">
                  <label class="form-label">&nbsp;</label>
                  <button
                    type="button"
                    @click="fetch_address(post_code)"
                    class="btn btn-primary form-control"
                  >
                    Find Address
                  </button>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 mb-3">
                <div class="form-group" v-if="addresses">
                  <label>Address selector</label>
                  <select
                    class="form-control"
                    v-model="selected_address"
                    @change="fill_with_selected($event.target.selectedIndex)"
                  >
                    <option disabled value="">Please select one</option>
                    <option
                      v-for="adr in addresses"
                      :key="adr.line_1 + adr.line_2"
                    >
                      {{ adr.line_1 + "," + adr.line_2 + "," + adr.town }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 mb-3">
                <div class="form-group">
                  <div class="form-group">
                    <label>Address line 1</label>
                    <input
                      type="text"
                      v-model="address_line1"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 mb-3">
                <div class="form-group">
                  <div class="form-group">
                    <label>Address line 2</label>
                    <input
                      type="text"
                      v-model="address_line2"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-6 mb-3">
                <div class="form-group">
                  <label>City</label>
                  <input type="text" v-model="city" class="form-control" />
                </div>
              </div>
              <div class="col-6 mb-3">
                <div class="form-group">
                  <label>County</label>
                  <input type="text" v-model="county" class="form-control" />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12 mb-3">
                <label for="type" class="form-label">Organization Type</label>
                <select class="form-control" id="type" v-model="type">
                  <option value="">- -</option>
                  <option value="customer">Customer</option>
                  <option value="partner_certified">Certified Partner</option>
                  <option value="partner_premier">Premier Partner</option>
                  <option value="partner_elite">Elite Partner</option>
                </select>
              </div>
            </div>

            <div v-if="'customer' === type" class="row">
              <div class="col-md-12 mb-3">
                <label for="partner" class="form-label"
                  >Organization Partner</label
                >
                <select class="form-control" id="partner" v-model="partner">
                  <option value="">- -</option>
                  <option
                    v-for="organization in partners"
                    v-bind:key="organization.id"
                    :value="organization.id"
                  >
                    {{ organization.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="row">
              <div class="col-12 mb-3">
                <button
                  type="button"
                  @click="create_organization()"
                  class="btn btn-primary w-100"
                  :disabled="!valid_input"
                >
                  Create Organization
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import axios from "axios";

export default {
  name: "ModalOrganizationNew",
  props: ["id"],
  data() {
    return {
      organization_exists: true,
      name: null,
      company_number: null,
      description: null,
      industry: "",
      post_code: null,
      address_line1: null,
      address_line2: null,
      city: null,
      county: null,
      addresses: null,
      selected_address: null,
      uuid: null,
      errors: null,
      join_request: 0,
      type: "customer",
      partner: "",
      partners: [],
      valid_input: false,
    };
  },
  mounted() {
    this.fetch_partner_organizations();
  },
  methods: {
    validate() {
      this.valid_input = "" !== this.industry && null !== this.post_code;
    },
    fetch_partner_organizations() {
      axios
        .get(process.env.VUE_APP_URL + "/partnerorganizations", {
          headers: {
            Authorization: process.appdata.tokens.access_token,
          },
        })
        .then((response) => {
          if (200 === response.status) {
            this.partners = response.data.data;
          } else {
            process.appdata.logout();
          }
        });
    },
    create_organization() {
      let data = {};
      let url = process.env.VUE_APP_URL;
      let app = this;

      if (this.organization_exists) {
        url += "/organizations";
        data = {
          company_number: this.company_number.trim(),
          description: this.description,
          industry: this.industry,
          address_line1: this.address_line1,
          address_line2: this.address_line2,
          city: this.city,
          county: this.county,
          post_code: this.post_code,
          type: this.type,
          partner: this.partner,
        };
      } else {
        url += "/organizations_custom";
        data = {
          name: this.name,
          description: this.description,
          industry: this.industry,
          address_line1: this.address_line1,
          address_line2: this.address_line2,
          city: this.city,
          county: this.county,
          post_code: this.post_code,
          type: this.type,
          partner: this.partner,
        };
      }

      axios
        .post(url, data, {
          headers: {
            Authorization: process.appdata.tokens.access_token,
          },
        })
        .then((response) => {
          // this.emitter.emit("organization_created", this.name);

          window.location.href = "/organisations/" + response.data.id;
        })
        .catch(function (error) {
          if (error.response) {
            app.emitter.emit("popup_alert", {
              type: "danger",
              text: error.response.data.error_description,
            });
          } else {
            app.emitter.emit("popup_alert", {
              type: "danger",
              text: "Error occurred",
            });
          }
        });
    },
    fetch_company() {
      var app = this;

      axios
        .get(
          process.env.VUE_APP_URL + "/company/" + this.company_number.trim(),
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then((response) => {
          this.name = response.data.name;
          this.post_code = response.data.post_code;
          this.address_line1 = response.data.address_line1;
          this.address_line2 = response.data.address_line2;
          this.city = response.data.city;
          this.county = response.data.county;
          this.uuid = response.data.id;

          if (response.data.exists) {
            this.join_request = 2;
          } else {
            this.join_request = 1;
          }
        })
        .catch(function (error) {
          if (error.response) {
            app.emitter.emit("popup_alert", {
              type: "danger",
              text: error.response.data.error_description,
            });
          } else {
            app.emitter.emit("popup_alert", {
              type: "danger",
              text: "Error occurred",
            });
          }
        });
    },
    fetch_address(code) {
      axios
        .get(process.env.VUE_APP_URL + "/address/" + code, {
          headers: {
            Authorization: process.appdata.tokens.access_token,
          },
        })
        .then((response) => {
          this.addresses = response.data.results;
        })
        .catch((e) => {
          this.$catch(e.response.data);
        });
    },
    send_join() {
      var app = this;

      axios
        .get(
          process.env.VUE_APP_URL +
            "/organizations/" +
            this.uuid +
            "/membership",
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then(() => {
          this.name = null;
          this.company_number = null;
          this.description = null;
          this.post_code = null;
          this.address_line1 = null;
          this.address_line2 = null;
          this.city = null;
          this.county = null;
          this.addresses = null;
          this.selected_address = null;
          this.join_request = 0;
          this.organization_exists = true;
        })
        .catch(function (error) {
          if (error.response) {
            app.emitter.emit("popup_alert", {
              type: "danger",
              text: error.response.data.error_description,
            });
          } else {
            app.emitter.emit("popup_alert", {
              type: "danger",
              text: "Error occurred",
            });
          }
        });
    },
    fill_with_selected(index) {
      var selected_adress = this.addresses[index - 1];
      this.address_line1 = selected_adress.line_1;
      this.address_line2 = selected_adress.line_2;
      this.city = selected_adress.town;
      this.county = selected_adress.county;
    },
  },
  watch: {
    post_code: function () {
      this.validate();
    },
  },
};
</script>