<template>
  <Breadcrumbs :breaditems="[{ title: 'Notifications' }]" />
  <div class="container">
    <div class="row g-2">
      <div class="col-md-3 mt-2">
        <div class="card">
          <div class="card-header bg-light">Menu</div>
          <div class="list-group list-group-flush">
            <router-link
              to="/notifications"
              :class="
                ('overview' === active_menu ? 'active ' : '') +
                'list-group-item list-group-item-action d-flex gap-2 align-items-center'
              "
              @click="setActiveMenu('overview')"
            >
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                  <i class="bi bi-app-indicator"></i>
                </div>
                <div class="flex-grow-1 ms-3">Notifications</div>
              </div>
            </router-link>
            <router-link
              :to="'/notifications/settings'"
              :class="
                ('settings' === active_menu ? 'active ' : '') +
                'list-group-item list-group-item-action d-flex gap-2 align-items-center'
              "
              @click="setActiveMenu('settings')"
            >
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                  <i class="bi bi-tools"></i>
                </div>
                <div class="flex-grow-1 ms-3">Settings</div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-md-9 mt-2">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs.vue";

export default {
  name: "MyNotificationsPage",
  components: { Breadcrumbs },
  data() {
    return {
      active_menu: "overview",
    };
  },
  created() {
    this.active_menu = this.$route.meta.submenu;
  },
  mounted() {},
  methods: {
    setActiveMenu(menu) {
      this.active_menu = menu;
    },
  },
};
</script>