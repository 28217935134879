<template>
  <div class="card">
    <div class="card-header bg-light d-flex">
      <i class="bi bi-app-indicator me-2"></i>
      Overview
    </div>
    <div class="card-body">
      <ul class="list-group">
        <li class="list-group-item d-flex p-0 border-0 mt-1">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-building me-3"></i>Name:
          </div>
          <div class="w-75 py-2 px-3">
            {{ site.name }}
            <button type="button" class="btn btn-link p-0 float-end text-decoration-none" data-bs-toggle="modal"
              data-bs-target="#siteDetails">
              Update
            </button>
          </div>
        </li>
        <li class="list-group-item d-flex p-0 border-0 mt-1">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-card-text me-3"></i>Description:
          </div>
          <div class="w-75 py-2 px-3">{{ site.description }}</div>
        </li>
        <li class="list-group-item d-flex p-0 border-0 mt-1">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-geo me-3"></i>Address:
          </div>
          <div class="w-75 py-2 px-3">
            {{
            (site.address_line1 ? site.address_line1 + ", " : "") +
            (site.city ? site.city + ", " : "") +
            (site.post_code || "")
            }}
            <button type="button" class="btn btn-link p-0 float-end text-decoration-none" data-bs-toggle="modal"
              data-bs-target="#siteAddress">
              Update
            </button>
          </div>
        </li>
        <li class="list-group-item d-flex p-0 border-0 mt-1">
          <div class="w-25 py-2 ps-3 bg-light">
            <i class="bi bi-geo-alt-fill me-3"></i>Latitude / Longitude:
          </div>
          <div class="w-75 py-2 px-3">
            {{ site.lat_long }}
            <button type="button" class="btn btn-link p-0 float-end text-decoration-none" data-bs-toggle="modal"
              data-bs-target="#siteLatLong">
              Update
            </button>
          </div>
        </li>
      </ul>
    </div>
  </div>

  <div class="card mt-2">
    <div class="card-header bg-light">
      <i class="bi bi-calendar-check me-2"></i>
      Created
    </div>
    <div class="card-body">
      <ul class="list-group">
        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-person-circle me-3"></i>Created By:
          </div>
          <div class="w-75 py-2 px-3">
            <router-link v-if="site.creator" :to="'/admin/users/' + site.creator.id" class="text-decoration-none">{{
              site.creator.full_name
            }}</router-link>
            <router-link v-if="site.creator" :to="'/admin/organisations/' + site.organization.id"
              class="float-end text-decoration-none">{{ site.organization.name }}</router-link>
          </div>
        </li>
        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-calendar-event me-3"></i>Created On:
          </div>
          <div class="w-75 py-2 px-3">
            {{
  localReadableTime(site.created_time) +
  " · " +
  timeAgo(site.created_time)
            }}
          </div>
        </li>
      </ul>
    </div>
  </div>

  <div class="card mt-2">
    <ul class="list-group list-group-flush">
      <li class="list-group-item">
        <div v-if="1 != site.deleted" class="d-flex align-items-center">
          <div class="flex-shrink-0">
            <i class="bi bi-exclamation-triangle-fill fs-4 text-danger"></i>
          </div>
          <div class="flex-grow-1 ms-3">
            <b class="text-dark">Delete this Site</b>
            <p class="small text-muted mb-0">
              This will be marked as deleted and will dissapear for normal
              users.
            </p>
          </div>
          <button type="button" class="btn btn-outline-danger" data-bs-toggle="modal" data-bs-target="#siteDelete">
            Delete Site
          </button>
        </div>

        <div v-else-if="isAdmin" class="d-flex align-items-center">
          <div class="flex-shrink-0">
            <i class="bi bi-exclamation-triangle-fill fs-4 text-success"></i>
          </div>
          <div class="flex-grow-1 ms-3">
            <b class="text-dark">Restore this Site</b>
            <p class="small text-muted mb-0">
              The site will be enabled and visible to anyone who has access to
              it.
            </p>
          </div>
          <button type="button" class="btn btn-outline-success" data-bs-toggle="modal" data-bs-target="#siteRestore">
            Restore Site
          </button>
        </div>
      </li>
    </ul>
  </div>

  <ModalSiteDetails id="siteDetails" />
  <ModalSiteDelete id="siteDelete" />
  <ModalSiteRestore v-if="isAdmin" id="siteRestore" />
  <ModalSiteAddress id="siteAddress" />
  <ModalSiteLatLong id="siteLatLong" />
</template>

<script>
import axios from "axios";
import moment from "moment";

import ModalSiteDetails from "@/components/modals/ModalSiteDetails.vue";
import ModalSiteDelete from "@/components/modals/ModalSiteDelete.vue";
import ModalSiteRestore from "@/components/modals/ModalSiteRestore.vue";
import ModalSiteAddress from "@/components/modals/ModalSiteAddress.vue";
import ModalSiteLatLong from "@/components/modals/ModalSiteLatLong.vue";

import Utils from "@/helpers/utils";

export default {
  name: "SiteOverview",
  components: {
    ModalSiteDetails,
    ModalSiteDelete,
    ModalSiteRestore,
    ModalSiteAddress,
    ModalSiteLatLong
  },
  data() {
    return {
      isAdmin:
        "admin" === process.appdata.user.type ||
          (process.appdata.user.rootOrganization &&
            process.env.VUE_APP_RT_NUMBER ===
            process.appdata.user.rootOrganization.company_number)
          ? true
          : false,
      site: {},
    };
  },
  mounted() {
    this.emitter.on("site_details_updated", () => {
      let siteDetailsModal = bootstrap.Modal.getInstance(
        document.getElementById("siteDetails")
      );
      siteDetailsModal.hide();

      this.emitter.emit("popup_alert", {
        type: "success",
        text: "Site details were succesfuly updated!",
      });

      this.fetch_site();
    });

    this.emitter.on("site_address_updated", () => {
      let siteAddressModal = bootstrap.Modal.getInstance(
        document.getElementById("siteAddress")
      );
      siteAddressModal.hide();

      this.emitter.emit("popup_alert", {
        type: "success",
        text: "Site address was succesfuly updated!",
      });

      this.fetch_site();
    });

    this.emitter.on("site_lat_long_updated", () => {
      let siteLatLongModal = bootstrap.Modal.getInstance(
        document.getElementById("siteLatLong")
      );
      siteLatLongModal.hide();

      this.emitter.emit("popup_alert", {
        type: "success",
        text: "Site Latitude and Longitude were succesfuly updated!",
      });

      this.fetch_site();
    });

    this.emitter.on("site_deleted", (data) => {
      this.emitter.emit("popup_alert", {
        type: "success",
        text: "Site " + data + " was succesfuly deleted!",
      });

      window.location.href = "/sites";
    });

    this.emitter.on("site_restored", (data) => {
      let siteRestoreModal = bootstrap.Modal.getInstance(
        document.getElementById("siteRestore")
      );
      siteRestoreModal.hide();

      this.emitter.emit("popup_alert", {
        type: "success",
        text: "Site " + data + " was succesfuly restored!",
      });

      this.fetch_site();
    });

    this.fetch_site();
  },
  methods: {
    fetch_site() {
      axios
        .get(process.env.VUE_APP_URL + "/sites/" + this.$route.params.site_id, {
          headers: {
            Authorization: process.appdata.tokens.access_token,
          },
        })
        .then((response) => {
          if (200 === response.status) {
            this.site = response.data;

            this.emitter.emit("site_details_loaded", this.site);
            this.emitter.emit("site_lat_long_loaded", this.site);
          } else {
            process.appdata.logout();
          }
        })
        .catch((error) => {
          if ("not_found" === error.response.data.error) {
            window.location.href = "/404";
          }
        });
    },

    capitalizeText(text, allwords) {
      return Utils.capitalizeText(text, allwords);
    },
    timeAgo(dateString) {
      return Utils.timeAgo(dateString);
    },
    localReadableTime(dateString) {
      return Utils.localReadableTime(dateString);
    },
    localMachineTime(dateString) {
      return Utils.localMachineTime(dateString);
    },
  },
};
</script>
