<template>
  <Breadcrumbs :breaditems="[{ title: 'Admin', link: '/admin' }, { title: 'Users' }]" />
  <div id="main" class="container">
    <div class="d-flex bd-highlight">
      <div class="flex-grow-1 me-2">
        <div class="input-group">
          <button v-on:click="fetch_users()" type="button" class="input-group-text bg-white">
            <i class="bi bi-search"></i>
          </button>
          <input v-model="filter.full_name" type="text" class="form-control" placeholder="Search..."
            v-on:keyup.enter="fetch_users()" />
        </div>
      </div>
      <div class="bd-highlight col-md-3 me-2">
        <div class="input-group">
          <select class="form-select" v-model="filter.type">
            <option value="" selected="">
              {{ "All (" + totals.all + ")" }}
            </option>
            <option value="user">{{ "Users (" + totals.users + ")" }}</option>
            <option value="admin">
              {{ "Admins (" + totals.admins + ")" }}
            </option>
          </select>
          <select class="form-select" v-model="filter.status">
            <option value="inactive">
              {{ "Inactive (" + totals.inactive + ")" }}
            </option>
            <option value="active" selected="">
              {{ "Active (" + totals.active + ")" }}
            </option>
            <option value="banned">
              {{ "Banned (" + totals.banned + ")" }}
            </option>
            <option value="suspended">
              {{ "Suspended (" + totals.suspended + ")" }}
            </option>
          </select>
        </div>
      </div>
      <div class="bd-highlight">
        <button type="button" class="btn border bg-white float-end text-decoration-none" data-bs-toggle="modal"
          data-bs-target="#userNew">New User</button>
      </div>
    </div>

    <div class="card my-2">
      <div class="card-header bg-light">Users</div>
      <div class="list-group list-group-flush">
        <div class="list-group-item bg-light">
          <div class="row">
            <div class="col-md-5">
              <b class="text-dark small">Name</b>
            </div>
            <div class="col-md-3">
              <b class="text-dark small">Organisation</b>
            </div>
            <div class="col-md-2">
              <b class="text-dark small">Status / Created</b>
            </div>
            <div class="col-md-2">
              <b class="text-dark small">Last Activity</b>
            </div>
          </div>
        </div>
        <router-link v-for="user in users" v-bind:key="user.id" :to="'/admin/users/' + user.id"
          class="list-group-item list-group-item-action filtered-list-item">
          <div class="row">
            <div class="col-md-5">
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                  <img :src="user.picture" :alt="user.full_name" class="rounded-circle align-self-center" width="36"
                    height="36" onerror="this.onerror=null;this.src='/images/person-circle.svg';" />
                </div>
                <div class="flex-grow-1 ms-3">
                  <b class="text-dark">{{ user.full_name }}</b>
                  <p class="small text-muted mb-0">
                    <span>{{ user.email }}</span>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-3 d-flex">
              <div class="align-self-center">
                <p class="mb-0">
                  <span v-if="user.organization.name" class="badge bg-primary">{{ user.organization.name }}</span>
                  <span v-else class="badge">&nbsp;</span>
                </p>
                <p class="mb-0">
                  <span v-if="user.job_title" class="badge bg-secondary">{{
                    user.job_title
                  }}</span>
                  <span v-else class="badge">&nbsp;</span>
                </p>
              </div>
            </div>
            <div class="col-md-2 d-flex">
              <div class="align-self-center">
                <b v-if="'active' === user.status" class="small text-success">Active</b>
                <b v-else class="small text-danger">{{
                  capitalizeText(user.status, true)
                }}</b>
                <p class="small text-muted mb-0">
                  {{ timeAgo(user.created_time) }}
                </p>
              </div>
            </div>
            <div class="col-md-2 d-flex">
              <div class="align-self-center">
                <b class="small text-muted">
                  {{ timeAgo(user.last_login) }}
                </b>
                <p class="small text-muted mb-0">
                  <span>{{ localReadableTime(user.last_login) }}</span>
                </p>
              </div>
            </div>
          </div>
        </router-link>
      </div>
      <div class="card-footer bg-light">
        <pagination v-if="paginator.current_page" :records="paginator.total_items" :per-page="10" v-model="current_page"
          :options="paginatorOptions"></pagination>
      </div>
    </div>
  </div>

  <ModalUserNew id="userNew" />
</template>

<script>
import axios from "axios";
import Pagination from "v-pagination-3";

import Breadcrumbs from "@/components/Breadcrumbs.vue";
import RTPagination from "@/components/RTPagination.vue";

import ModalUserNew from "@/components/modals/ModalUserNew.vue";

import Utils from "@/helpers/utils";

export default {
  name: "UsersPage",
  components: { Breadcrumbs, Pagination, RTPagination, ModalUserNew },
  data() {
    return {
      paginatorOptions: {
        template: RTPagination,
      },
      paginator: {},
      users: [],
      filter: {
        type: "",
        status: "active",
        full_name: "",
      },
      current_page: 1,
      totals: {
        all: 0,
        admins: 0,
        users: 0,
        active: 0,
        inactive: 0,
        banned: 0,
        suspended: 0,
      },
    };
  },
  mounted() {
    this.fetch_users();
  },
  methods: {
    fetch_users() {
      let url =
        process.env.VUE_APP_URL +
        "/admin/users?items=10&page=" +
        this.current_page +
        "&filter[user.full_name]=" +
        encodeURIComponent(this.filter.full_name);

      if ("" !== this.filter.type) {
        url += "&filter[user.type]=" + this.filter.type;
      }

      if ("" !== this.filter.status) {
        url += "&filter[user.status]=" + this.filter.status;
      }

      axios
        .get(url, {
          headers: {
            Authorization: process.appdata.tokens.access_token,
          },
        })
        .then((response) => {
          if (200 === response.status) {
            let item;
            this.totals = {
              all: 0,
              admins: 0,
              users: 0,
              active: 0,
              inactive: 0,
              banned: 0,
              suspended: 0,
            };

            for (item in response.data.totals.admin) {
              this.totals.all += parseInt(response.data.totals.admin[item]);
              this.totals.admins += parseInt(response.data.totals.admin[item]);

              this.totals[item] += parseInt(response.data.totals.admin[item]);
            }
            for (item in response.data.totals.user) {
              this.totals.all += parseInt(response.data.totals.user[item]);
              this.totals.users += parseInt(response.data.totals.user[item]);

              this.totals[item] += parseInt(response.data.totals.user[item]);
            }

            this.users = response.data.data;
            this.paginator = response.data.paginator;
          } else {
            process.appdata.logout();
          }
        });
    },
    capitalizeText(text, allwords) {
      return Utils.capitalizeText(text, allwords);
    },
    timeAgo(dateString) {
      return Utils.timeAgo(dateString);
    },
    localReadableTime(dateString) {
      return Utils.localReadableTime(dateString);
    },
    localMachineTime(dateString) {
      return Utils.localMachineTime(dateString);
    },
  },
  watch: {
    "filter.status": function () {
      this.fetch_users();
    },
    "filter.type": function () {
      this.fetch_users();
    },
    current_page: function () {
      this.fetch_users();
    },
  },
};
</script>