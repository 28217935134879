<template>
  <div class="modal fade" tabindex="-1" :id="id">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Restore Site</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="alert alert-info text-center">
                Are you sure you to restore <b>{{ name }}</b
                >?
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <button class="btn btn-secondary w-100" data-bs-dismiss="modal">
                Cancel
              </button>
            </div>
            <div class="col-md-6">
              <div
                class="btn btn-success w-100"
                @click="restore_site()"
              >
                Confirm
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ModalSiteRestore",
  props: ["id"],
  data() {
    return {
      site_id: null,
      name: "",
    };
  },
  mounted() {
    this.emitter.on("site_details_loaded", (data) => {
      this.site_id = data.id ? data.id : null;
      this.name = data.name ? data.name : "";
    });
  },
  methods: {
    restore_site() {
      axios
        .get(
          process.env.VUE_APP_URL +
            "/sites/" +
            this.site_id +
            "/restore",
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then(
          (response) => {
            if (200 === response.status) {
              this.emitter.emit("site_restored", this.user);
            } else {
            }
          },
          (error) => {
            console.log(error);
          }
        );
    },
  },
};
</script>