<template>
  <Breadcrumbs
    :breaditems="[
      { title: 'My Account', link: '/account' },
      { title: 'Security', link: '/account/logs' },
      { title: 'Activity ' + activity.id },
    ]"
  />
  <div class="container">
    <div class="card mb-2">
      <div class="card-header bg-light">
        <i class="bi bi-calendar-week me-2"></i>
        Log {{ id }}
      </div>
      <div class="card-body">
        <ul class="list-group">
          <li class="list-group-item d-flex p-0 border-0 mt-1">
            <div class="w-25 py-2 px-3 bg-light">
              <i class="bi bi-hash me-3"></i>ID:
            </div>
            <div class="w-75 py-2 px-3">{{ activity.id }}</div>
          </li>

          <li class="list-group-item d-flex p-0 border-0 mt-1">
            <div class="w-25 py-2 px-3 bg-light">
              <i class="bi bi-window-sidebar me-3"></i>Service:
            </div>
            <div class="w-75 py-2 px-3">
              <router-link :to="'/services/' + activity.service_uid"
                >{{ activity.service_name }}
              </router-link>
            </div>
          </li>

          <li class="list-group-item d-flex p-0 border-0 mt-1">
            <div class="w-25 py-2 px-3 bg-light">
              <i class="bi bi-disc me-3"></i>Action:
            </div>
            <div class="w-75 py-2 px-3">{{ activity.type }}</div>
          </li>

          <!-- Causer -->
          <li
            v-if="'user' === activity.causer_type"
            class="list-group-item d-flex p-0 border-0 mt-1"
          >
            <div class="w-25 py-2 px-3 bg-light">
              <i class="bi bi-person-lines-fill me-3"></i>Causer:
            </div>
            <div class="w-75 py-2 px-3">
              <router-link :to="'/users/' + activity.causer_id">{{
                activity.causer_name
              }}</router-link>
            </div>
          </li>
          <li
            v-else-if="'organization' === activity.causer_type"
            class="list-group-item d-flex p-0 border-0 mt-1"
          >
            <div class="w-25 py-2 px-3 bg-light">
              <i class="bi bi-person-lines-fill me-3"></i>Causer:
            </div>
            <div class="w-75 py-2 px-3">
              <router-link :to="'/organisations/' + activity.causer_id">{{
                activity.causer_name
              }}</router-link>
            </div>
          </li>
          <li
            v-else-if="'service' === activity.causer_type"
            class="list-group-item d-flex p-0 border-0 mt-1"
          >
            <div class="w-25 py-2 px-3 bg-light">
              <i class="bi bi-person-lines-fill me-3"></i>Causer:
            </div>
            <div class="w-75 py-2 px-3">
              <router-link :to="'/services/' + activity.causer_id">{{
                activity.causer_name
              }}</router-link>
            </div>
          </li>
          <li
            v-else-if="'site' === activity.causer_type"
            class="list-group-item d-flex p-0 border-0 mt-1"
          >
            <div class="w-25 py-2 px-3 bg-light">
              <i class="bi bi-person-lines-fill me-3"></i>Causer:
            </div>
            <div class="w-75 py-2 px-3">
              <router-link :to="'/sites/' + activity.causer_id">{{
                activity.causer_name
              }}</router-link>
            </div>
          </li>
          <li v-else class="list-group-item d-flex p-0 border-0 mt-1">
            <div class="w-25 py-2 px-3 bg-light">
              <i class="bi bi-person-lines-fill me-3"></i>Causer:
            </div>
            <div class="w-75 py-2 px-3">
              {{
                activity.causer_name +
                " ( " +
                activity.causer_type +
                " " +
                activity.causer_id +
                " )"
              }}
            </div>
          </li>

          <!-- Entity -->
          <li
            v-if="'user' === activity.entity_type"
            class="list-group-item d-flex p-0 border-0 mt-1"
          >
            <div class="w-25 py-2 px-3 bg-light">
              <i class="bi bi-file-earmark-person me-3"></i>Entity:
            </div>
            <div class="w-75 py-2 px-3">
              <router-link :to="'/users/' + activity.entity_id">{{
                activity.entity_name
              }}</router-link>
            </div>
          </li>
          <li
            v-else-if="'organization' === activity.entity_type"
            class="list-group-item d-flex p-0 border-0 mt-1"
          >
            <div class="w-25 py-2 px-3 bg-light">
              <i class="bi bi-file-earmark-person me-3"></i>Entity:
            </div>
            <div class="w-75 py-2 px-3">
              <router-link :to="'/organisations/' + activity.entity_id">{{
                activity.entity_name
              }}</router-link>
            </div>
          </li>
          <li
            v-else-if="'service' === activity.entity_type"
            class="list-group-item d-flex p-0 border-0 mt-1"
          >
            <div class="w-25 py-2 px-3 bg-light">
              <i class="bi bi-file-earmark-person me-3"></i>Entity:
            </div>
            <div class="w-75 py-2 px-3">
              <router-link :to="'/services/' + activity.entity_id">{{
                activity.entity_name
              }}</router-link>
            </div>
          </li>
          <li
            v-else-if="'site' === activity.entity_type"
            class="list-group-item d-flex p-0 border-0 mt-1"
          >
            <div class="w-25 py-2 px-3 bg-light">
              <i class="bi bi-file-earmark-person me-3"></i>Entity:
            </div>
            <div class="w-75 py-2 px-3">
              <router-link :to="'/sites/' + activity.entity_id">{{
                activity.entity_name
              }}</router-link>
            </div>
          </li>
          <li v-else class="list-group-item d-flex p-0 border-0 mt-1">
            <div class="w-25 py-2 px-3 bg-light">
              <i class="bi bi-file-earmark-person me-3"></i>Entity:
            </div>
            <div class="w-75 py-2 px-3">
              {{
                activity.entity_name +
                " ( " +
                activity.entity_type +
                " " +
                activity.entity_id +
                " )"
              }}
            </div>
          </li>

          <li class="list-group-item d-flex p-0 border-0 mt-1">
            <div class="w-25 py-2 px-3 bg-light">
              <i class="bi bi-card-text me-3"></i>Message:
            </div>
            <div
              class="w-75 py-2 px-3"
              v-html="
                activity.templates.default
                  ? activity.templates.default.plaintext || ''
                  : ''
              "
            ></div>
          </li>

          <li class="list-group-item d-flex p-0 border-0 mt-1">
            <div class="w-25 py-2 px-3 bg-light">
              <i class="bi bi-list-nested me-3"></i>Properties:
            </div>
            <div class="w-75 py-2 px-3 text-secondary">
              <pre>{{ JSON.stringify(this.activity.properties, null, 2) }}</pre>
            </div>
          </li>
          <li class="list-group-item d-flex p-0 border-0 mt-1">
            <div class="w-25 py-2 px-3 bg-light">
              <i class="bi bi-node-minus-fill me-3"></i>Old State:
            </div>
            <div class="w-75 py-2 px-3 text-secondary">
              <pre>{{ JSON.stringify(this.activity.old_state, null, 2) }}</pre>
            </div>
          </li>
          <li class="list-group-item d-flex p-0 border-0 mt-1">
            <div class="w-25 py-2 px-3 bg-light">
              <i class="bi bi-node-plus-fill me-3"></i>New State:
            </div>
            <div class="w-75 py-2 px-3 text-secondary">
              <pre>{{ JSON.stringify(this.activity.new_state, null, 2) }}</pre>
            </div>
          </li>

          <li class="list-group-item d-flex p-0 border-0 mt-1">
            <div class="w-25 py-2 px-3 bg-light">
              <i class="bi bi-circle-fill me-3"></i>Device:
            </div>
            <div class="w-75 font-monospace small py-2 px-3">
              {{
                (user_agent.os.name || "") +
                " " +
                (user_agent.os.version || "") +
                " · " +
                (user_agent.browser.name || "") +
                " " +
                (user_agent.browser.version || "")
              }}
              <button
                type="button"
                class="btn btn-link btn-sm p-0 float-end text-decoration-none"
                data-bs-toggle="modal"
                data-bs-target="#userAgent"
              >
                View Agent
              </button>
            </div>
          </li>

          <li class="list-group-item d-flex p-0 border-0 mt-1">
            <div class="w-25 py-2 px-3 bg-light">
              <i class="bi bi-circle-fill me-3"></i>IP Address:
            </div>
            <div class="w-75 font-monospace small py-2 px-3">
              {{ this.activity.ip_address }}
              <button
                type="button"
                class="btn btn-link btn-sm p-0 float-end text-decoration-none"
                data-bs-toggle="modal"
                data-bs-target="#userLocation"
              >
                View Info
              </button>
            </div>
          </li>

          <li class="list-group-item d-flex p-0 border-0 mt-1">
            <div class="w-25 py-2 px-3 bg-light">
              <i class="bi bi-circle-fill me-3"></i>Location:
            </div>
            <div class="w-75 font-monospace small py-2 px-3">
              {{
                (undefined === activity.location.city
                  ? ""
                  : activity.location.city + ", ") +
                (undefined === activity.location.stateprov
                  ? ""
                  : activity.location.stateprov + ", ") +
                (activity.location.country || "")
              }}
            </div>
          </li>

          <li class="list-group-item d-flex p-0 border-0">
            <div class="w-25 py-2 px-3 bg-light">
              <i class="bi bi-circle-fill me-3"></i>Timestamp:
            </div>
            <div class="w-75 py-2 px-3 font-monospace small align-self-center">
              {{
                formattedTimestamp(activity.created_at) +
                " · " +
                timeAgo(activity.created_at)
              }}
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <ModalUserAgent id="userAgent" />
  <ModalUserLocation id="userLocation" />
</template>

<script>
import axios from "axios";
import moment from "moment";
import UAParser from "ua-parser-js";

import Breadcrumbs from "@/components/Breadcrumbs.vue";
import ModalUserAgent from "@/components/modals/ModalUserAgent.vue";
import ModalUserLocation from "@/components/modals/ModalUserLocation.vue";

import Utils from "@/helpers/utils";

export default {
  name: "MyLogPage",
  components: { Breadcrumbs, ModalUserAgent, ModalUserLocation },
  data() {
    return {
      activity: {
        id: "",
        properties: {},
        old_state: {},
        new_state: {},
        location: {},
        templates: {},
      },
      user_agent: {
        device: {},
        browser: {},
        os: {},
        ua: "",
        device_type: "",
      },
    };
  },
  mounted() {
    this.fetch_entry();
  },
  methods: {
    fetch_entry() {
      axios
        .get(
          process.env.VUE_APP_LOGS_ENDPOINT +
            "/activity?id=" +
            this.$route.params.log_id,
          {
            headers: {
              Authorization: process.env.VUE_APP_LOGS_SECRET,
            },
          }
        )
        .then((response) => {
          this.activity = response.data;
          this.activity.properties = response.data.properties || {};
          this.activity.old_state = response.data.old_state || {};
          this.activity.new_state = response.data.new_state || {};
          this.activity.location = response.data.location || {};

          const parser = new UAParser(this.activity.user_agent);
          this.user_agent = parser.getResult();
          this.user_agent.device_type = this.activity.device_type;

          this.emitter.emit("user_agent_loaded", this.user_agent);

          this.emitter.emit("user_location_loaded", this.activity.location);
        });
    },
    timeAgo(dateString) {
      return Utils.timeAgo(dateString);
    },
    localMachineTime(dateString) {
      return Utils.localMachineTime(dateString);
    },
    formattedTimestamp(date) {
      return moment(this.localMachineTime(date)).format("HH:mm:ss DD/MM/YY");
    },
  },
};
</script>