<template>
  <div class="d-flex bd-highlight">
    <div class="flex-grow-1 me-2">
      <div class="input-group">
        <button
          v-on:click="fetch_user_organizations()"
          type="button"
          class="input-group-text bg-white"
        >
          <i class="bi bi-search"></i>
        </button>
        <input
          type="text"
          class="form-control"
          placeholder="Search..."
          v-model="filter.organization_name"
          v-on:keyup.enter="fetch_user_organizations()"
        />
      </div>
    </div>
    <div class="bd-highlight">
      <select class="form-select" v-model="filter.organization_type">
        <option value="" selected="">
          {{ "All (" + totals.all + ")" }}
        </option>
        <option value="owner">
          {{ "Owner (" + totals.owner + ")" }}
        </option>
        <option value="member">
          {{ "Member (" + totals.member + ")" }}
        </option>
        <option value="collaborator">
          {{ "Collaborator (" + totals.collaborator + ")" }}
        </option>
        <option value="limited">
          {{ "Limited (" + totals.limited + ")" }}
        </option>
      </select>
    </div>
  </div>

  <div class="card mt-2">
    <div class="card-header bg-light">
      <i class="bi bi-building me-2"></i>
      Organisations
    </div>

    <div v-if="0 === organizations.length" class="card-body">
      <div class="text-center">
        <img src="/images/empty-organizations.svg" class="img-fluid py-5" />
      </div>
    </div>

    <div v-else class="list-group list-group-flush">
      <router-link
        v-for="organization in organizations"
        v-bind:key="organization.id"
        :to="'/organisations/' + organization.id"
        class="list-group-item list-group-item-action"
      >
        <div class="row">
          <div class="col-md-4 d-flex">
            <div class="d-flex align-items-center">
              <div class="flex-shrink-0">
                <i class="bi bi-building fs-4"></i>
              </div>
              <div class="flex-grow-1 ms-3">
                <b class="text-dark">{{ organization.name }}</b>
                <p class="small text-muted mb-0">
                  <span>{{ organization.industry }}</span>
                </p>
              </div>
            </div>
          </div>
          <div class="col d-flex">
            <div class="align-self-center">
              <span
                class="badge bg-light text-dark border w-100 d-block mb-1"
                >{{ capitalizeText(organization.membership.role) }}</span
              >
              <span class="badge bg-light text-dark border w-100 d-block"
                >{{ organization.membership.sites }} Sites</span
              >
            </div>
          </div>
          <div class="col d-flex">
            <div class="d-block text-end me-3 ms-auto">
              <p class="small text-muted mb-0"><span>Member since</span></p>
              <b class="text-dark text-muted small">{{
                format_date(organization.membership.created)
              }}</b>
            </div>
            <i class="bi bi-chevron-right align-self-center"></i>
          </div>
        </div>
      </router-link>
    </div>
    <div class="card-footer bg-light">
      <pagination
        v-if="paginator.current_page"
        :records="paginator.total_items"
        :per-page="10"
        v-model="current_page"
        :options="paginatorOptions"
      ></pagination>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import Pagination from "v-pagination-3";

import RTPagination from "@/components/RTPagination.vue";

import Utils from "@/helpers/utils";

export default {
  name: "UserOrganizations",
  components: { Pagination, RTPagination },
  data() {
    return {
      paginatorOptions: {
        template: RTPagination,
      },
      paginator: {},
      filter: {
        organization_name: "",
        organization_type: "",
      },
      organizations: [],
      current_page: 1,
      totals: {
        all: 0,
        owner: 0,
        member: 0,
        collaborator: 0,
        limited: 0,
      },
    };
  },
  mounted() {
    this.fetch_user_organizations();
  },
  methods: {
    fetch_user_organizations() {
      axios
        .get(
          process.env.VUE_APP_URL +
            "/admin/users/" +
            this.$route.params.user_id +
            "/organizations?items=10&page=" +
            this.current_page +
            "&filter[organization.name]=" +
            encodeURIComponent(this.filter.organization_name) +
            "&filter[organization.type]=" +
            this.filter.organization_type,
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then((response) => {
          if (200 === response.status) {
            let item;
            this.totals = {
              all: 0,
              owner: 0,
              member: 0,
              collaborator: 0,
              limited: 0,
            };

            for (item in response.data.totals.member) {
              this.totals.all += parseInt(response.data.totals.member[item]);
              this.totals.member += parseInt(response.data.totals.member[item]);

              this.totals[item] += parseInt(response.data.totals.member[item]);
            }
            for (item in response.data.totals.collaborator) {
              this.totals.all += parseInt(
                response.data.totals.collaborator[item]
              );
              this.totals.collaborator += parseInt(
                response.data.totals.collaborator[item]
              );

              this.totals[item] += parseInt(
                response.data.totals.collaborator[item]
              );
            }
            for (item in response.data.totals.limited) {
              this.totals.all += parseInt(response.data.totals.limited[item]);
              this.totals.limited += parseInt(
                response.data.totals.limited[item]
              );

              this.totals[item] += parseInt(response.data.totals.limited[item]);
            }

            this.organizations = response.data.data;
            this.paginator = response.data.paginator;
          } else {
            process.appdata.logout();
          }
        });
    },
    capitalizeText(text, allwords) {
      return Utils.capitalizeText(text, allwords);
    },
    format_date(date) {
      return moment(date).format("DD MMM YYYY");
    },
  },
  watch: {
    "filter.organization_type": function () {
      this.fetch_user_organizations();
    },
    current_page: function () {
      this.fetch_user_organizations();
    },
  },
};
</script>