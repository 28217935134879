<template>
  <Breadcrumbs
    :breaditems="[
      { title: 'Admin', link: '/admin' },
      { title: 'Services', link: '/admin/services' },
      { title: service.name },
    ]"
  />
  <div id="main" class="container">
    <div class="row g-2">
      <div class="col-md-3 mt-2">
        <div class="card">
          <div class="card-header bg-white">
            <div class="d-flex align-items-center">
              <div class="flex-shrink-0">
                <img
                  :src="service.logo"
                  :alt="service.name"
                  class="align-self-center"
                  width="36"
                  height="36"
                  onerror="this.src='/images/window-sidebar.svg';"
                />
              </div>
              <div class="flex-grow-1 ms-3">
                <b class="text-dark full-name">{{ service.name }}</b>
                <p class="small text-muted mb-0 email">
                  <span>{{ service.description }}</span>
                </p>
              </div>
            </div>
          </div>
          <div class="list-group list-group-flush">
            <router-link
              :to="'/admin/services/' + service.id"
              :class="
                ('overview' === active_menu ? 'active ' : '') +
                'list-group-item list-group-item-action d-flex gap-2 align-items-center'
              "
              @click="setActiveMenu('overview')"
            >
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                  <i class="bi bi-app-indicator"></i>
                </div>
                <div class="flex-grow-1 ms-3">Overview</div>
              </div>
            </router-link>
            <router-link
              :to="'/admin/services/' + service.id + '/roles'"
              :class="
                ('roles' === active_menu ? 'active ' : '') +
                'list-group-item list-group-item-action d-flex gap-2 align-items-center'
              "
              @click="setActiveMenu('roles')"
            >
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                  <i class="bi bi-people"></i>
                </div>
                <div class="flex-grow-1 ms-3">Roles</div>
              </div>
            </router-link>
            <router-link
              :to="'/admin/services/' + service.id + '/logs'"
              :class="
                ('logs' === active_menu ? 'active ' : '') +
                'list-group-item list-group-item-action d-flex gap-2 align-items-center'
              "
              @click="setActiveMenu('logs')"
            >
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                  <i class="bi bi-calendar-week"></i>
                </div>
                <div class="flex-grow-1 ms-3">Activity Logs</div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-md-9 mt-2">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import Breadcrumbs from "@/components/Breadcrumbs.vue";

export default {
  name: "ServicePage",
  components: { Breadcrumbs },
  data() {
    return {
      active_menu: "overview",
      service: {
        id: this.$route.params.service_id,
        stats: {},
      },
    };
  },
  created() {
    this.active_menu = this.$route.meta.submenu;
  },
  mounted() {
    this.emitter.on("service_logo_updated", () => {
      this.fetch_service_stats();
    });

    this.fetch_service_stats();
  },
  methods: {
    setActiveMenu(menu) {
      this.active_menu = menu;
    },
    fetch_service_stats() {
      axios
        .get(
          process.env.VUE_APP_URL +
            "/services/" +
            this.$route.params.service_id +
            "/stats",
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then((response) => {
          if (200 === response.status) {
            this.service = response.data;
          } else {
            process.appdata.logout();
          }
        });
    },
  },
};
</script>