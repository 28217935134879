<template>
  <div class="modal fade" tabindex="-1" :id="id">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Change Password</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12 mb-3">
              <label for="passwordOne" class="form-label">New Password</label><input type="password" id="passwordOne"
                minlength="3" maxlength="50" class="form-control" v-model="password" @keyup="validate" />
              <small class="text-secondary">
                <i>Must be at least 8 characters</i>
              </small>
            </div>
          </div>

          <div class="row">
            <div class="col-12 mb-3">
              <label for="passwordTwo">Confirm New Password</label><input type="password" id="passwordTwo" maxlength="50"
                class="form-control" v-model="password_confirmation" @keyup="validate" />
              <small class="text-secondary">
                <i>Must be at least 8 characters</i>
              </small>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <button type="button" class="btn btn-primary w-100" :disabled="!valid_input" @click="change_password">
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ModalUserPassword",
  props: ["id"],
  data() {
    return {
      password: "",
      password_confirmation: "",
      valid_input: false,
    };
  },
  methods: {
    validate() {
      this.valid_input =
        this.password.length >= 8 && this.password_confirmation.length >= 8 && this.password === this.password_confirmation;
    },

    change_password() {
      let app = this;

      axios
        .patch(
          process.env.VUE_APP_URL +
          "/admin/users/" +
          this.$route.params.user_id + "/password",
          {
            password: this.password,
            password_confirmation: this.password_confirmation
          },
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then(
          (response) => {
            if (200 === response.status) {
              app.emitter.emit("user_password_updated");
            } else {
            }
          },
          (error) => {
            console.log(error);
          }
        );
    },
  },
};
</script>