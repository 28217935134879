import CryptoJS from "crypto-js";
import Storage from './storage.js';
import axios from "axios";

export default {
    name: "RTAuth",
    version: "0.0.1",
    requestToken() {
        let verifierBytes = CryptoJS.lib.WordArray.random(32);
        let verifier = this.base64URLEncode(CryptoJS.enc.Base64.stringify(verifierBytes));
        let challenge = this.base64URLEncode(CryptoJS.SHA256(verifier).toString(CryptoJS.enc.Base64));

        let stateBytes = CryptoJS.lib.WordArray.random(32);
        let state = stateBytes.toString(CryptoJS.enc.Base64);

        let nonceBytes = CryptoJS.lib.WordArray.random(32);
        let nonce = nonceBytes.toString(CryptoJS.enc.Base64);

        let clientObj = { "name": this.name, "version": this.version };
        let client = this.base64URLEncode(btoa(JSON.stringify(clientObj)));

        Storage.set("verifier", verifier);

        let url = "https://" + process.env.VUE_APP_DOMAIN + "/authorize";
        url += "?client_id=" + process.env.VUE_APP_CLIENT_ID;
        url += "&scope=identity";
        url += "&ignoreCache=true";
        url += "&response_type=code";
        url += "&response_mode=query";
        url += "&state=" + state;
        url += "&nonce=" + nonce;
        url += "&code_challenge=" + challenge;
        url += "&code_challenge_method=S256";
        url += "&auth0Client=" + client;

        window.location.href = url;
    },

    accessToken(callback) {
        let params = new URLSearchParams(window.location.search);

        let verifier = Storage.get("verifier");

        axios({
            method: "post",
            url: process.env.VUE_APP_URL + "/token",
            data: {
                "grant_type": "authorization_code",
                "code": params.get("code"),
                "client_id": process.env.VUE_APP_CLIENT_ID,
                "client_secret": process.env.VUE_APP_CLIENT_SECRET,
                "redirect_uri": process.env.VUE_APP_REDIRECT_URL,
                "code_verifier": verifier
            }
        }).then((response) => {
            var data = response.data, now = new Date();
            data.ttl = now.setHours(now.getHours() + 1);
            Storage.set("tokens", JSON.stringify(data));
            Storage.remove("verifier");

            if (callback) {
                callback();
            }
        }, (err) => {
            this.requestToken();
        });
    },

    refreshToken(callback) {
        axios({
            method: "post",
            url: process.env.VUE_APP_URL + "/token",
            data: {
                "grant_type": "refresh_token",
                "client_id": process.env.VUE_APP_CLIENT_ID,
                "client_secret": process.env.VUE_APP_CLIENT_SECRET,
                "refresh_token": process.appdata.tokens.refresh_token
            }
        }).then((response) => {
            var data = response.data, now = new Date();
            data.ttl = now.setHours(now.getHours() + 1);
            Storage.set("tokens", JSON.stringify(data));

            if (callback) {
                callback();
            }
        }, (error) => {
            this.requestToken();
        });
    },

    base64URLEncode(str) {
        return str
            .replace(/\+/g, '-')
            .replace(/\//g, '_')
            .replace(/=/g, '');
    }
}
