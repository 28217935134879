<template>
  <div class="modal fade" tabindex="-1" :id="id">
    <div class="modal-dialog modal-sm" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Invite Member</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12 mb-3">
              <label for="email" class="form-label">Email Address</label>
              <input
                v-model="email"
                type="text"
                class="form-control"
                id="email"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-12 mb-3">
              <label class="form-label">Organization Role</label>

              <div
                class="btn-group w-100"
                role="group"
                aria-label="Basic radio toggle button group"
              >
                <input
                  v-model="role"
                  type="radio"
                  class="btn-check"
                  id="radioLimitedInvitation"
                  value="limited"
                  autocomplete="off"
                />
                <label
                  class="btn btn-outline-primary"
                  for="radioLimitedInvitation"
                  >Limited</label
                >

                <input
                  v-model="role"
                  type="radio"
                  class="btn-check"
                  id="radioMemberInvitation"
                  value="member"
                  autocomplete="off"
                />
                <label
                  class="btn btn-outline-primary"
                  for="radioMemberInvitation"
                  >Member</label
                >

                <input
                  v-model="role"
                  type="radio"
                  class="btn-check"
                  id="radioOwnerInvitation"
                  value="owner"
                  autocomplete="off"
                />
                <label
                  class="btn btn-outline-primary"
                  for="radioOwnerInvitation"
                  >Owner</label
                >
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12" v-if="role == 'limited'">
              <label class="form-label">Sites</label>
              <TreeView
                id="treeViewSites"
                v-bind:key="treeViewSites"
                :treeData="sites"
                :values="selected_sites"
                :onUpdate="refreshMembership"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <label class="form-label">Services</label>
              <TreeView
                id="treeViewServices"
                v-bind:key="treeViewServices"
                :treeData="services"
                :values="selected_services"
                :onUpdate="refreshMembership"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <button
                type="button"
                @click="send_invitation()"
                class="btn btn-primary w-100"
              >
                Send Invitation
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import TreeView from "@/components/TreeView.vue";

export default {
  name: "ModalOrganizationInvitation",
  props: ["id", "membership", "sites", "services"],
  components: { TreeView },
  data() {
    return {
      email: "",
      role: "",
      sites: [],
      selected_sites: [],
      services: [],
      selected_services: [],
    };
  },
  mounted() {
    this.fetch_sites();
    this.fetch_services();
  },
  methods: {
    fetch_sites() {
      axios
        .get(
          process.env.VUE_APP_URL +
            "/organizations/" +
            this.$route.params.organization_id +
            "/sites?items=500&page=1",
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then((response) => {
          this.sites = response.data.data;
        });
    },
    fetch_services() {
      axios
        .get(
          process.env.VUE_APP_URL +
            "/organizations/" +
            this.$route.params.organization_id +
            "/services?items=500&page=1",
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then((response) => {
          this.services = response.data.data.map((item) => {
            item.children = item.roles;

            return item;
          });
        });
    },
    refreshMembership(data) {
      if ("treeViewServices" === data.id) {
        this.selected_services = data.values;
      }

      if ("treeViewSites" === data.id) {
        this.selected_sites = data.values;
      }
    },
    send_invitation() {
      var app = this;

      axios
        .post(
          process.env.VUE_APP_URL +
            "/organizations/" +
            this.$route.params.organization_id +
            "/membership",
          {
            type: this.membership,
            user: this.email,
            role: this.role,
            sites: this.selected_sites,
            services: this.selected_services,
          },
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then((response) => {
          this.emitter.emit("organization_member_invited", this.email);

          this.email = null;
          this.role = null;
          this.selected_sites = [];
          this.selected_services = [];
        })
        .catch(function (error) {
          if (error.response) {
            app.emitter.emit("popup_alert", {
              type: "danger",
              text: error.response.data.error_description,
            });
          } else {
            app.emitter.emit("popup_alert", {
              type: "danger",
              text: "Error occurred",
            });
          }
        });
    },
  },
  watch: {
    role() {
      if (this.role == "limited") {
        this.fetch_sites(this.current_page);
      }
    },
  },
};
</script>
