<template>
  <div class="modal fade" tabindex="-1" :id="id">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">User Location</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row" v-if="ip_address">
            <div class="col-md-4 mb-3">
              <span class="fw-bold">IP Address</span>
            </div>
            <div class="col-md-8 mb-3">
              <div class="font-monospace">{{ ip_address }}</div>
            </div>
          </div>

          <div class="row" v-if="country">
            <div class="col-md-4 mb-3">
              <span class="fw-bold">Country</span>
            </div>
            <div class="col-md-8 mb-3">
              <div class="font-monospace">{{ country }}</div>
            </div>
          </div>

          <div class="row" v-if="province">
            <div class="col-md-4 mb-3">
              <span class="fw-bold">State/Province</span>
            </div>
            <div class="col-md-8 mb-3">
              <div class="font-monospace">{{ province }}</div>
            </div>
          </div>

          <div class="row" v-if="city">
            <div class="col-md-4 mb-3">
              <span class="fw-bold">City</span>
            </div>
            <div class="col-md-8 mb-3">
              <div class="font-monospace">{{ city }}</div>
            </div>
          </div>

          <div class="row" v-if="isp_name">
            <div class="col-md-4 mb-3">
              <span class="fw-bold">ISP Name</span>
            </div>
            <div class="col-md-8 mb-3">
              <div class="font-monospace">
                {{ isp_name }}
              </div>
            </div>
          </div>

          <div class="row" v-if="connection_type">
            <div class="col-md-4">
              <span class="fw-bold">Connection type</span>
            </div>
            <div class="col-md-8">
              <div class="font-monospace">
                {{ connection_type }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Utils from "@/helpers/utils";

export default {
  name: "ModalUserLocation",
  props: ["id"],
  data() {
    return {
      ip_address: "",
      country: "",
      province: "",
      city: "",
      isp_name: "",
      connection_type: "",
    };
  },
  mounted() {
    this.emitter.on("user_location_loaded", (data) => {
      this.ip_address = data.ip_address ? data.ip_address : "";
      this.country = data.country ? data.country : "";
      this.province = data.stateprov ? data.stateprov : "";
      this.city = data.city ? data.city : "";
      this.isp_name = data.isp_name ? data.isp_name : "";
      this.connection_type = data.connection_type
        ? this.capitalizeText(data.connection_type)
        : "";
    });
  },
  methods: {
    capitalizeText(text, allwords) {
      return Utils.capitalizeText(text, allwords);
    },
  },
};
</script>