<template>
  <Breadcrumbs :breaditems="[{ title: 'Organisations' }]" />
  <div id="main" class="container">
    <div class="d-flex bd-highlight">
      <div class="flex-grow-1 me-2">
        <div class="input-group">
          <button class="input-group-text bg-white" @click="fetch_organizations()">
            <i class="bi bi-search"></i>
          </button>
          <input type="text" class="form-control" placeholder="Search..." v-model="filter.name"
            v-on:keyup.enter="fetch_organizations()" />
        </div>
      </div>
      <div class="bd-highlight me-2">
        <select class="form-select" v-model="filter.type">
          <option value="">
            {{ "All Organisations (" + totals.all + ")" }}
          </option>
          <option value="owner">
            {{ "Owner (" + totals.owner + ")" }}
          </option>
          <option value="member">
            {{ "Member (" + totals.member + ")" }}
          </option>
          <option value="collaborator">
            {{ "Collaborator (" + totals.collaborator + ")" }}
          </option>
          <option value="none" v-if="0 < totals.none">
            {{ "None (" + totals.none + ")" }}
          </option>
        </select>
      </div>
      <div class="bd-highlight">
        <select class="form-select" v-model="sortby">
          <option value="created_desc">Date created DESC</option>
          <option value="created_asc">Date created ASC</option>
          <option value="name_desc">Name DESC</option>
          <option value="name_asc">Name ASC</option>
        </select>
      </div>
    </div>

    <div class="card my-2">
      <div class="card-header bg-light">Organisations</div>
      <div class="list-group list-group-flush">
        <router-link v-for="organization in organizations" v-bind:key="organization.id"
          :to="'/organisations/' + organization.id" class="list-group-item list-group-item-action">
          <div class="row">
            <div class="col-md-6 d-flex">
              <div class="d-flex align-items-center" style="max-width: 100%">
                <div class="flex-shrink-0">
                  <img :src="null == organization.logo ? '/images/building.svg' : organization.logo" :alt="organization.name"
                    class="align-self-center" width="24" onerror="this.src='/images/building.svg';" />
                </div>
                <div class="flex-grow-1 ms-3 text-truncate">
                  <b class="text-dark">{{ organization.name }}</b>
                  <p class="small text-muted mb-0">
                    <span>{{ organization.industry }}</span>
                  </p>
                </div>
              </div>
            </div>
            <div class="col d-flex">
              <div class="align-self-center">
                <span v-if="organization.membership.role" class="badge bg-light text-dark border w-100 d-block mb-1">{{
                  capitalizeText(organization.membership.role)
                }}</span>
                <span v-else class="badge bg-white">&nbsp;</span>
                <span class="badge bg-light text-dark border w-100 d-block">{{
                  organization.membership.sites + " sites"
                }}</span>
              </div>
            </div>
            <div class="col d-flex">
              <i class="bi bi-chevron-right align-self-center ms-auto"></i>
            </div>
          </div>
        </router-link>
      </div>
      <div class="card-footer bg-light">
        <pagination v-if="paginator.current_page" :records="paginator.total_items" :per-page="10" v-model="current_page"
          :options="paginatorOptions"></pagination>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Pagination from "v-pagination-3";

import Breadcrumbs from "@/components/Breadcrumbs.vue";
import RTPagination from "@/components/RTPagination.vue";

import Utils from "@/helpers/utils";

export default {
  name: "OrganizationsPage",
  components: { Breadcrumbs, Pagination, RTPagination },
  data() {
    return {
      paginatorOptions: {
        template: RTPagination,
      },
      paginator: {},
      organizations: [],
      totals: {
        all: 0,
        owner: 0,
        member: 0,
        collaborator: 0,
        none: 0,
      },
      filter: {
        name: "",
        type: "",
      },
      sortby: "created_desc",
      current_page: 1,
    };
  },
  mounted() {
    this.fetch_organizations();
  },
  methods: {
    fetch_organizations() {
      let url =
        process.env.VUE_APP_URL +
        "/organizations?items=10&page=" +
        this.current_page +
        "&type=" +
        this.filter.type +
        "&name=" +
        encodeURIComponent(this.filter.name) +
        "&order=" +
        this.sortby;

      axios
        .get(url, {
          headers: {
            Authorization: process.appdata.tokens.access_token,
          },
        })
        .then((response) => {
          if (200 === response.status) {
            let item;
            this.totals = {
              all: 0,
              owner: 0,
              member: 0,
              collaborator: 0,
            };

            this.totals = response.data.totals;
            this.organizations = response.data.data;
            this.paginator = response.data.paginator;
          } else {
            process.appdata.logout();
          }
        });
    },
    capitalizeText(text, allwords) {
      return Utils.capitalizeText(text, allwords);
    },
    timeAgo(dateString) {
      return Utils.timeAgo(dateString);
    },
    localReadableTime(dateString) {
      return Utils.localReadableTime(dateString);
    },
    localMachineTime(dateString) {
      return Utils.localMachineTime(dateString);
    },
  },
  watch: {
    "filter.type": function () {
      this.current_page = 1;
      this.fetch_organizations();
    },
    "filter.name": function () {
      this.current_page = 1;
    },
    sortby: function () {
      this.current_page = 1;
      this.fetch_organizations();
    },
    current_page: function () {
      this.fetch_organizations();
    },
  },
};
</script>