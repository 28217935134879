<template>
  <div class="d-flex bd-highlight">
    <div class="flex-grow-1 me-2">
      <div class="input-group">
        <button
          v-on:click="fetch_organization_members()"
          type="button"
          class="input-group-text bg-white"
        >
          <i class="bi bi-search"></i>
        </button>
        <input
          type="text"
          class="form-control"
          placeholder="Search..."
          v-model="filter.name"
          v-on:keyup.enter="fetch_organization_members()"
        />
      </div>
    </div>
    <div class="bd-highlight me-2">
      <select class="form-select" v-model="filter.role">
        <option value="">{{ "All Users (" + totals.all + ")" }}</option>
        <option value="owner">{{ "Owners (" + totals.owner + ")" }}</option>
        <option value="member">{{ "Members (" + totals.member + ")" }}</option>
        <option value="limited">
          {{ "Limited Members (" + totals.limited + ")" }}
        </option>
      </select>
    </div>
    <div class="bd-highlight">
      <button
        type="button"
        class="btn border bg-white float-end text-decoration-none"
        data-bs-toggle="modal"
        data-bs-target="#organizationInvitation"
      >
        New Invite
      </button>
    </div>
  </div>
  <div class="card mt-2">
    <div class="card-header bg-light">
      <i class="bi bi-people-fill me-2"></i>
      Members
    </div>

    <div v-if="0 === members.length" class="card-body">
      <div class="text-center">
        <img src="/images/empty-users.svg" class="img-fluid py-5" />
      </div>
    </div>

    <div v-else class="list-group list-group-flush">
      <div
        class="list-group-item"
        v-for="member in members"
        v-bind:key="member.id"
      >
        <div class="row">
          <div class="col">
            <router-link
              class="d-flex align-items-center text-decoration-none"
              :to="'/admin/users/' + member.user.id"
            >
              <div class="flex-shrink-0">
                <img
                  :src="member.user.picture"
                  :alt="member.user.first_name + ' ' + member.user.last_name"
                  class="rounded-circle align-self-center"
                  width="36"
                  height="36"
                  onerror="this.src='/images/person-circle.svg';"
                />
              </div>
              <div class="flex-grow-1 ms-3">
                <b class="text-dark">{{
                  member.user.first_name + " " + member.user.last_name
                }}</b>
                <p class="small text-muted mb-0">
                  <span>{{ member.user.email || "&nbsp;" }}</span>
                </p>
              </div>
            </router-link>
          </div>
          <div class="col-1 d-flex">
            <div class="align-self-center">
              <span
                class="badge bg-light text-dark border w-100 d-block mb-1"
                >{{ capitalizeText(member.role) }}</span
              >
              <span class="badge bg-light text-dark border w-100 d-block"
                >All Sites</span
              >
            </div>
          </div>
          <div class="col-3 d-flex">
            <div class="align-self-center">
              <p class="mb-0">
                <span class="badge bg-primary">{{
                  member.rootOrganization.name
                }}</span>
              </p>
              <p class="mb-0">
                <span class="badge bg-secondary" v-if="member.user.job_title">{{
                  member.user.job_title
                }}</span>
                <span class="badge bg-white" v-else>&nbsp;</span>
              </p>
            </div>
          </div>
          <div class="col-3 d-flex pe-0">
            <div class="d-block text-end me-2 ms-auto">
              <p class="small text-muted mb-0"><span>Last Active</span></p>
              <b class="text-dark text-muted small">{{
                timeAgo(member.user.last_activity)
              }}</b>
            </div>
            <div class="align-self-center me-2">
              <div class="dropstart">
                <button
                  type="button"
                  class="btn btn-link"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i class="bi bi-gear-fill mr-1"></i>
                </button>
                <div class="dropdown-menu" style="width: 250px">
                  <h6 class="dropdown-header">Member Options</h6>
                  <a
                    href="#"
                    class="btn dropdown-item"
                    @click="
                      openManageModal(member.id, member.role, member.user)
                    "
                    >Manage <i class="bi bi-gear-fill float-end"></i
                  ></a>

                  <router-link
                    :to="'/admin/users/' + member.user.id"
                    class="btn dropdown-item"
                    >View user in admin panel
                    <i class="bi bi-person-fill float-end"></i
                  ></router-link>

                  <div class="dropdown-divider"></div>
                  <a
                    href="#"
                    class="btn dropdown-item text-danger"
                    @click="openRemoveModal(member.id, member.user)"
                    >Remove from organization
                    <i class="bi bi-person-dash-fill float-end"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer bg-light">
      <pagination
        v-if="paginator.members.current_page"
        :records="paginator.members.total_items"
        :per-page="10"
        v-model="current_page.members"
        :options="paginatorOptions"
      ></pagination>
    </div>
  </div>

  <div class="card mt-2">
    <div class="card-header bg-light">
      <i class="bi bi-mailbox me-2"></i>
      Pending Invitations
    </div>

    <div
      v-if="0 === this.invitations.length && 0 === this.requests.length"
      class="card-body"
    >
      <div class="text-center">
        <img src="/images/empty-invites.svg" class="img-fluid py-5" />
      </div>
    </div>
    <div v-else class="list-group list-group-flush">
      <div
        v-for="invitation in invitations"
        v-bind:key="invitation.id"
        class="list-group-item"
      >
        <div class="row">
          <div class="col">
            <div class="d-flex align-items-center">
              <div class="flex-shrink-0">
                <i class="bi bi-envelope-fill fs-4"></i>
              </div>
              <div class="flex-grow-1 ms-3">
                <span class="text-dark">{{ invitation.user_email }}</span>
                <p class="small text-muted mb-0">
                  <span>Expires {{ timeAgo(invitation.expired_time) }}</span>
                </p>
              </div>
              <div class="">
                <div class="btn-group" role="group" aria-label="Basic example">
                  <button
                    type="button"
                    class="
                      btn btn-link
                      bg-light
                      text-decoration-none text-primary
                    "
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title=""
                    data-bs-original-title="Resend e-mail"
                    aria-label="Resend e-mail"
                    @click="
                      resend_invitation(invitation.id, invitation.user_email)
                    "
                  >
                    <i class="bi bi-arrow-clockwise"></i>
                  </button>
                  <button
                    type="button"
                    class="
                      btn btn-link
                      bg-light
                      text-decoration-none text-danger
                    "
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title=""
                    data-bs-original-title="Delete invite"
                    aria-label="Delete invite"
                    @click="
                      delete_invitation(invitation.id, invitation.user_email)
                    "
                  >
                    <i class="bi bi-trash"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-for="request in requests"
        v-bind:key="request.id"
        class="list-group-item"
      >
        <div class="row">
          <div class="col">
            <div class="d-flex align-items-center">
              <div class="flex-shrink-0">
                <i class="bi bi-envelope-fill fs-4"></i>
              </div>
              <div class="flex-grow-1 ms-3">
                <span class="text-dark">{{ request.user_email }}</span>
                <p class="small text-muted mb-0">
                  <span>Expires {{ timeAgo(request.expired_time) }}</span>
                </p>
              </div>
              <div class="">
                <div class="btn-group" role="group" aria-label="Basic example">
                  <button
                    type="button"
                    class="
                      btn btn-link
                      bg-light
                      text-decoration-none text-primary
                    "
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title=""
                    data-bs-original-title="Accept request"
                    aria-label="Accept request"
                    @click="openRequestModal(request.id, request.user)"
                  >
                    <i class="bi bi-plus-circle-fill"></i>
                  </button>
                  <button
                    type="button"
                    class="
                      btn btn-link
                      bg-light
                      text-decoration-none text-danger
                    "
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title=""
                    data-bs-original-title="Delete invite"
                    aria-label="Delete invite"
                    @click="
                      delete_invitation(invitation.id, invitation.user_email)
                    "
                  >
                    <i class="bi bi-trash"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer bg-light">
      <pagination
        v-if="paginator.invitations.current_page"
        :records="paginator.invitations.total_items"
        :per-page="10"
        v-model="current_page.invitations"
        :options="paginatorOptions"
      ></pagination>
    </div>
  </div>

  <ModalOrganizationMember id="organizationMember" />
  <ModalOrganizationInvitation
    membership="member"
    id="organizationInvitation"
  />
  <ModalOrganizationRequest membership="member" id="organizationRequest" />
  <ModalOrganizationUserRemove v-if="isAdmin" id="organizationUserRemove" />
</template>

<script>
import axios from "axios";

import Pagination from "v-pagination-3";
import RTPagination from "@/components/RTPagination.vue";
import ModalOrganizationMember from "@/components/modals/ModalOrganizationMember.vue";
import ModalOrganizationInvitation from "@/components/modals/ModalOrganizationInvitation.vue";
import ModalOrganizationRequest from "@/components/modals/ModalOrganizationRequest.vue";
import ModalOrganizationUserRemove from "@/components/modals/ModalOrganizationUserRemove.vue";

import Utils from "@/helpers/utils";

export default {
  name: "OrganizationMembers",
  components: {
    Pagination,
    RTPagination,
    ModalOrganizationMember,
    ModalOrganizationInvitation,
    ModalOrganizationRequest,
    ModalOrganizationUserRemove,
  },
  data() {
    return {
      isAdmin:
        "admin" === process.appdata.user.type ||
        (process.appdata.user.rootOrganization &&
          process.env.VUE_APP_RT_NUMBER ===
            process.appdata.user.rootOrganization.company_number)
          ? true
          : false,
      paginatorOptions: {
        template: RTPagination,
      },
      paginator: {
        members: {},
        invitations: {},
      },
      members: [],
      totals: {
        all: 0,
        owner: 0,
        member: 0,
        limited: 0,
      },
      filter: {
        name: "",
        role: "",
      },
      invitations: [],
      requests: [],
      current_page: {
        members: 1,
        invitations: 1,
      },
    };
  },
  mounted() {
    this.emitter.on("member_updated", () => {
      let organizationMemberModal = bootstrap.Modal.getInstance(
        document.getElementById("organizationMember")
      );
      organizationMemberModal.hide();

      this.emitter.emit("popup_alert", {
        type: "success",
        text: "Member was succesfuly updated!",
      });

      this.fetch_organization_members();
    });

    this.emitter.on("organization_member_removed", () => {
      let organizationUserRemoveModal = bootstrap.Modal.getInstance(
        document.getElementById("organizationUserRemove")
      );
      organizationUserRemoveModal.hide();

      this.emitter.emit("popup_alert", {
        type: "success",
        text: "Member was succesfuly removed!",
      });

      this.fetch_organization_members();
    });

    this.emitter.on("organization_member_invited", (email) => {
      let organizationInvitationModal = bootstrap.Modal.getInstance(
        document.getElementById("organizationInvitation")
      );
      organizationInvitationModal.hide();

      this.emitter.emit("popup_alert", {
        type: "success",
        text: "User " + email + " was succesfuly invited to the organization!",
      });

      this.fetch_organization_invitations();
    });

    this.emitter.on("membership_approved", (user) => {
      let organizationRequestModal = bootstrap.Modal.getInstance(
        document.getElementById("organizationRequest")
      );
      organizationRequestModal.hide();

      this.emitter.emit("popup_alert", {
        type: "success",
        text: "Request of " + user.email + " was accepted!",
      });

      this.fetch_organization_members();
      this.fetch_organization_invitations();
    });

    this.fetch_organization_members();
    this.fetch_organization_invitations();
  },
  methods: {
    fetch_organization_members() {
      axios
        .get(
          process.env.VUE_APP_URL +
            "/organizations/" +
            this.$route.params.organization_id +
            "/members?items=10&page=" +
            this.current_page.members +
            "&role=" +
            this.filter.role +
            "&name=" +
            encodeURIComponent(this.filter.name),
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then((response) => {
          if (200 === response.status) {
            this.members = response.data.data;
            this.paginator.members = response.data.paginator;

            this.totals = response.data.totals;
          } else {
            process.appdata.logout();
          }
        });
    },

    openManageModal(member_id, role, user) {
      this.emitter.emit("manage_member", {
        id: member_id,
        role: role,
        user: user,
      });

      var updateOrganizationMember = new bootstrap.Modal(
        document.getElementById("organizationMember"),
        null
      );
      updateOrganizationMember.show();
    },

    openRemoveModal(member_id, user) {
      this.emitter.emit("remove_member", {
        id: member_id,
        user: user,
      });

      var organizationUserRemoveModal = new bootstrap.Modal(
        document.getElementById("organizationUserRemove"),
        null
      );
      organizationUserRemoveModal.show();
    },

    openRequestModal(request_id, user) {
      this.emitter.emit("manage_request", {
        request_id: request_id,
        user: user,
      });

      var organizationUserRequestModal = new bootstrap.Modal(
        document.getElementById("organizationRequest"),
        null
      );
      organizationUserRequestModal.show();
    },

    fetch_organization_invitations() {
      axios
        .get(
          process.env.VUE_APP_URL +
            "/organizations/" +
            this.$route.params.organization_id +
            "/membership/invitations?items=10&page=" +
            this.current_page.invitations,
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then((response) => {
          if (200 === response.status) {
            this.invitations = response.data.data;
            this.paginator.invitations = response.data.paginator;

            this.fetch_organization_requests();
          } else {
            process.appdata.logout();
          }
        });
    },
    fetch_organization_requests() {
      axios
        .get(
          process.env.VUE_APP_URL +
            "/organizations/" +
            this.$route.params.organization_id +
            "/membership/joins?items=10&page=1",
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then((response) => {
          if (200 === response.status) {
            this.requests = response.data.data;
          } else {
            process.appdata.logout();
          }
        });
    },
    delete_invitation(invitation_id, invitation_email) {
      axios
        .delete(
          process.env.VUE_APP_URL +
            "/organizations/" +
            this.$route.params.organization_id +
            "/membership/" +
            invitation_id,
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then((response) => {
          if (200 === response.status) {
            this.emitter.emit("popup_alert", {
              type: "success",
              text: "Invitation to " + invitation_email + " was was cancelled!",
            });

            this.fetch_organization_invitations();
          } else {
            process.appdata.logout();
          }
        });
    },
    resend_invitation(invitation_id, invitation_email) {
      axios
        .post(
          process.env.VUE_APP_URL +
            "/organizations/" +
            this.$route.params.organization_id +
            "/membership/" +
            invitation_id +
            "/resend",
          {},
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then((response) => {
          if (200 === response.status) {
            this.emitter.emit("popup_alert", {
              type: "success",
              text: "Invitation to " + invitation_email + " was resent!",
            });

            this.fetch_organization_invitations();
          } else {
            process.appdata.logout();
          }
        });
    },
    capitalizeText(text, allwords) {
      return Utils.capitalizeText(text, allwords);
    },
    timeAgo(dateString) {
      return Utils.timeAgo(dateString);
    },
    localReadableTime(dateString) {
      return Utils.localReadableTime(dateString);
    },
    localMachineTime(dateString) {
      return Utils.localMachineTime(dateString);
    },
  },
  watch: {
    "filter.role": function () {
      this.current_page = 1;
      this.fetch_organization_members();
    },
    "current_page.members": function () {
      this.fetch_organization_members();
    },
    "current_page.invitations": function () {
      this.fetch_organization_invitations();
    },
  },
};
</script>
