<template>
  <Breadcrumbs
    :breaditems="[
      { title: 'Admin', link: '/admin' },
      { title: 'Organisations', link: '/admin/organisations' },
      { title: organization.name },
    ]"
  />
  <div class="container">
    <div class="row g-2">
      <div class="col-md-3 mt-2">
        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="flex-grow-1">
                <b class="text-dark">{{ organization.name }}</b>
                <p class="small text-muted mb-0">{{ organization.industry }}</p>
              </div>
            </div>
          </div>
          <div class="list-group list-group-flush">
            <router-link
              :to="'/admin/organisations/' + organization.id"
              :class="
                ('overview' === active_menu ? 'active ' : '') +
                'list-group-item list-group-item-action d-flex gap-2 align-items-center'
              "
              @click="setActiveMenu('overview')"
            >
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                  <i class="bi bi-app-indicator"></i>
                </div>
                <div class="flex-grow-1 ms-3">Overview</div>
              </div>
            </router-link>
            <router-link
              :to="'/admin/organisations/' + organization.id + '/sites'"
              :class="
                ('sites' === active_menu ? 'active ' : '') +
                'list-group-item list-group-item-action d-flex gap-2 align-items-center'
              "
              @click="setActiveMenu('sites')"
            >
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                  <i class="bi bi-geo"></i>
                </div>
                <div class="flex-grow-1 ms-3">Sites</div>
              </div>
              <span class="badge bg-light ms-auto text-dark border fw-normal">{{
                organization.stats.sites
              }}</span>
            </router-link>
            <router-link
              :to="'/admin/organisations/' + organization.id + '/members'"
              :class="
                ('members' === active_menu ? 'active ' : '') +
                'list-group-item list-group-item-action d-flex gap-2 align-items-center'
              "
              @click="setActiveMenu('members')"
            >
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                  <i class="bi bi-people-fill"></i>
                </div>
                <div class="flex-grow-1 ms-3">Members</div>
              </div>
              <span class="badge bg-light ms-auto text-dark border fw-normal">{{
                organization.stats.members
              }}</span>
            </router-link>
            <router-link
              :to="'/admin/organisations/' + organization.id + '/collaborators'"
              :class="
                ('collaborators' === active_menu ? 'active ' : '') +
                'list-group-item list-group-item-action d-flex gap-2 align-items-center'
              "
              @click="setActiveMenu('collaborators')"
            >
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                  <i class="bi bi-people"></i>
                </div>
                <div class="flex-grow-1 ms-3">Collaborators</div>
              </div>
              <span class="badge bg-light ms-auto text-dark border fw-normal">{{
                organization.stats.collaborators
              }}</span>
            </router-link>
            <router-link
              :to="'/admin/organisations/' + organization.id + '/services'"
              :class="
                ('services' === active_menu ? 'active ' : '') +
                'list-group-item list-group-item-action d-flex gap-2 align-items-center'
              "
              @click="setActiveMenu('services')"
            >
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                  <i class="bi bi-window-sidebar"></i>
                </div>
                <div class="flex-grow-1 ms-3">Services</div>
              </div>
              <span class="badge bg-light ms-auto text-dark border fw-normal">{{
                organization.stats.services
              }}</span>
            </router-link>
            <router-link
              :to="'/admin/organisations/' + organization.id + '/logs'"
              :class="
                ('logs' === active_menu ? 'active ' : '') +
                'list-group-item list-group-item-action d-flex gap-2 align-items-center'
              "
              @click="setActiveMenu('logs')"
            >
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                  <i class="bi bi-calendar-week"></i>
                </div>
                <div class="flex-grow-1 ms-3">Activity Logs</div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-md-9 mt-2">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import Breadcrumbs from "@/components/Breadcrumbs.vue";

export default {
  name: "AdminOrganizationPage",
  components: { Breadcrumbs },
  data() {
    return {
      active_menu: "overview",
      organization: {
        id: this.$route.params.organization_id,
        stats: {},
      },
    };
  },
  created() {
    this.active_menu = this.$route.meta.submenu;
  },
  mounted() {
    this.emitter.on("organization_logo_updated", () => {
      this.fetch_organization_stats();
    });

    this.emitter.on("organization_industry_updated", () => {
      this.fetch_organization_stats();
    });

    this.emitter.on("organization_site_added", () => {
      this.fetch_organization_stats();
    });

    this.fetch_organization_stats();
  },
  methods: {
    setActiveMenu(menu) {
      this.active_menu = menu;
    },
    fetch_organization_stats() {
      axios
        .get(
          process.env.VUE_APP_URL +
            "/organizations/" +
            this.$route.params.organization_id +
            "/stats",
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then((response) => {
          if (200 === response.status) {
            this.organization = response.data;
          } else {
            process.appdata.logout();
          }
        });
    },
  },
};
</script>