<template>
  <div class="modal fade" tabindex="-1" :id="id">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add a Service</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="d-flex mb-2">
            <div class="flex-fill">
              <div class="input-group">
                <button
                  class="input-group-text bg-white"
                  @click="fetch_all_services()"
                >
                  <i class="bi bi-search"></i>
                </button>
                <input
                  type="text"
                  class="form-control"
                  v-model="filter.name"
                  placeholder="Search..."
                  v-on:keyup.enter="fetch_all_services()"
                />
              </div>
            </div>
          </div>

          <div>
            <div
              class="d-flex py-2"
              v-for="service in services"
              :key="service.id"
            >
              <div class="d-flex pe-3">
                <img
                  :src="service.logo"
                  :alt="service.name"
                  class="align-self-center"
                  onerror="this.src='/images/building.svg';"
                  width="28"
                />
              </div>

              <div class="flex-grow-1">
                <b class="text-dark">{{ service.name }}</b>
                <p class="small text-muted mb-0">{{ service.description }}</p>
              </div>

              <div v-if="isAdmin" class="d-flex">
                <button
                  v-if="selected.includes(service.id)"
                  type="button"
                  class="btn btn-outline-danger"
                  v-on:click="toggle_service(service.id, service.name)"
                >
                  Remove service
                </button>
                <button
                  v-else
                  type="button"
                  class="btn btn-outline-success"
                  v-on:click="toggle_service(service.id, service.name)"
                >
                  Add service
                </button>
              </div>

              <div v-else class="d-flex">
                <button
                  v-if="selected.includes(service.id)"
                  type="button"
                  class="btn btn-outline-danger"
                  v-on:click="toggle_service(service.id, service.name)"
                >
                  Remove service
                </button>
                <button
                  v-else
                  type="button"
                  class="btn btn-outline-success"
                  v-on:click="register_interest(service.id, service.name)"
                >
                  Register interest
                </button>
              </div>
            </div>
          </div>

          <nav class="text-center mt-2" v-if="paginator">
            <pagination
              v-if="paginator.current_page"
              :records="paginator.total_items"
              :per-page="10"
              v-model="current_page"
              :options="paginatorOptions"
            ></pagination>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Pagination from "v-pagination-3";

import RTPagination from "@/components/RTPagination.vue";

export default {
  name: "ModalOrganizationNewService",
  props: ["selected"],
  components: { Pagination, RTPagination },
  data() {
    return {
      isAdmin:
        "admin" === process.appdata.user.type ||
        (process.appdata.user.rootOrganization &&
          process.env.VUE_APP_RT_NUMBER ===
            process.appdata.user.rootOrganization.company_number)
          ? true
          : false,
      paginatorOptions: {
        template: RTPagination,
      },
      paginator: {},
      organization: {},
      services: [],
      current_page: 1,
      filter: {
        name: "",
      },
    };
  },
  mounted() {
    this.fetch_all_services();
  },
  methods: {
    fetch_all_services() {
      var app = this;

      axios
        .get(
          process.env.VUE_APP_URL +
            "/organizations/" +
            this.$route.params.organization_id +
            "/services/all?page=" +
            this.current_page +
            "&name=" +
            encodeURIComponent(this.filter.name),
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then((response) => {
          this.services = response.data.data;
          this.paginator = response.data.paginator;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    register_interest(service_id, service_name) {
      var app = this;
      
      axios
        .get(
          process.env.VUE_APP_URL +
            "/organizations/" +
            this.$route.params.organization_id +
            "/services/" +
            service_id +
            "/interest",
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then((response) => {
          app.emitter.emit("service_interest_registered", {
            name: service_name,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    toggle_service(service_id, service_name) {
      var app = this;

      axios
        .patch(
          process.env.VUE_APP_URL +
            "/organizations/" +
            this.$route.params.organization_id +
            "/services/" +
            service_id,
          {},
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then(() => {
          app.emitter.emit("services_list_updated", {
            name: service_name,
          });
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
  },
  watch: {
    current_page() {
      this.fetch_all_services();
    },
  },
};
</script>