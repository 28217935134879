<template>
  <div class="modal fade" tabindex="-1" :id="id">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Leave {{ organization_name }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="alert alert-danger text-center">
                Are you sure you to leave <b>{{ organization_name }}</b
                >?
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <button class="btn btn-secondary w-100" data-bs-dismiss="modal">
                Cancel
              </button>
            </div>
            <div class="col-md-6">
              <div class="btn btn-danger w-100" @click="remove_me">Confirm</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ModalMeRemove",
  props: ["id"],
  data() {
    return {
      member_id: null,
      organization_id: null,
      organization_name: null,
    };
  },
  mounted() {
    this.emitter.on("user_details_loaded", (data) => {
      this.member_id = data.rootOrganization
        ? data.rootOrganization.member_id
        : null;
      this.organization_id = data.rootOrganization
        ? data.rootOrganization.id
        : null;
      this.organization_name = data.rootOrganization
        ? data.rootOrganization.name
        : null;
    });
  },
  methods: {
    remove_me() {
      axios
        .delete(
          process.env.VUE_APP_URL +
            "/organizations/" +
            this.organization_id +
            "/members/" +
            this.member_id,
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then(
          (response) => {
            if (200 === response.status) {
              this.emitter.emit("me_removed", this.user);
            } else {
            }
          },
          (error) => {
            console.log(error);
          }
        );
    },
  },
};
</script>