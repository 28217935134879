<template>
	<div class="modal fade" tabindex="-1" :id="id">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">Change Latitude / Longitude</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<div class="row">
						<div class="col-md-12 mb-3">
							<label for="latLong" class="form-label">Latitude / Longitude</label>
							<input type="text" id="latLong" minlength="3" maxlength="50" class="form-control" v-model="lat_long"
								@keyup="validate" />
						</div>
					</div>

					<div class="row">
						<div class="col-12">
							<button type="button" class="btn btn-primary w-100" :disabled="!valid_input" @click="update_latlong">
								Update
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";

export default {
	name: "ModalSiteLatLong",
	props: ["id"],
	data() {
		return {
			lat_long: "",
			latitude: "",
			longitude: "",
			valid_input: false,
		};
	},
	mounted() {
		this.emitter.on("site_lat_long_loaded", (data) => {
			this.lat_long = data.lat_long ? data.lat_long : "";

			this.validate();
		});
	},
	methods: {
		validate() {
			if ("" !== this.lat_long.trim()) {
				let coords = this.lat_long.match(/[\-|\d|\.]+/g);
				this.latitude = parseFloat(coords[0]);
				this.longitude = parseFloat(coords[1]);

				this.valid_input =
					this.latitude >= -90
					&& this.latitude <= 90
					&& this.longitude >= -180
					&& this.longitude <= 180;
			} else {
				this.latitude = "";
				this.longitude = "";

				this.valid_input = true;
			}
		},

		update_latlong() {
			let app = this;

			axios
				.patch(
					process.env.VUE_APP_URL +
					"/sites/" +
					this.$route.params.site_id + "/latlong",
					{
						latitude: this.latitude,
						longitude: this.longitude,
					},
					{
						headers: {
							Authorization: process.appdata.tokens.access_token,
						},
					}
				)
				.then(
					(response) => {
						this.lat_long = "";

						app.emitter.emit("site_lat_long_updated");
					},
					(error) => {
						console.log(error);
					}
				);
		},
	},
};
</script>