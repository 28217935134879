<template>
  <div class="modal fade" tabindex="-1" :id="id">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Delete Organisation</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="alert alert-danger text-center">
                Are you sure you to delete <b>{{ name }}</b
                >?
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <button class="btn btn-secondary w-100" data-bs-dismiss="modal">
                Cancel
              </button>
            </div>
            <div class="col-md-6">
              <div class="btn btn-danger w-100" @click="delete_organization()">
                Confirm
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ModalOrganizationDelete",
  props: ["id"],
  data() {
    return {
      organization_id: null,
      name: "",
    };
  },
  mounted() {
    this.emitter.on("organization_details_loaded", (data) => {
      this.organization_id = data.id ? data.id : null;
      this.name = data.name ? data.name : "";
    });
  },
  methods: {
    delete_organization() {
      axios
        .delete(
          process.env.VUE_APP_URL +
            "/organizations/" +
            this.organization_id,
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then(
          (response) => {
            if (200 === response.status) {
              this.emitter.emit("organization_deleted", this.user);

              let organizationDeleteModal = bootstrap.Modal.getInstance(
                document.getElementById(this.id)
              );
              organizationDeleteModal.hide();
            } else {
            }
          },
          (error) => {
            console.log(error);
          }
        );
    },
  },
};
</script>