<template>
  <div class="d-flex bd-highlight">
    <div class="flex-grow-1 me-2">
      <div class="input-group">
        <button
          class="input-group-text bg-white"
          @click="fetch_organization_sites()"
        >
          <i class="bi bi-search"></i>
        </button>
        <input
          type="text"
          class="form-control"
          v-model="filter.name"
          placeholder="Search..."
          v-on:keyup.enter="fetch_organization_sites()"
        />
      </div>
    </div>
    <div class="bd-highlight me-1">
      <button
        type="button"
        class="btn bg-white border float-end text-decoration-none"
        data-bs-toggle="modal"
        data-bs-target="#newSite"
      >
        New Site
      </button>
    </div>

    <div class="bd-highlight">
      <select class="form-select" v-model="sortby">
        <option value="created_desc">Date created DESC</option>
        <option value="created_asc">Date created ASC</option>
        <option value="name_desc">Name DESC</option>
        <option value="name_asc">Name ASC</option>
      </select>
    </div>
  </div>

  <div class="card mt-2">
    <div class="card-header bg-light">
      <i class="bi bi-geo me-2"></i>
      Sites
    </div>

    <div v-if="0 === sites.length" class="card-body">
      <div class="text-center">
        <img src="/images/empty-sites.svg" class="img-fluid py-5" />
      </div>
    </div>

    <div v-else class="list-group list-group-flush">
      <router-link
        v-for="site in sites"
        v-bind:key="site.id"
        :to="'/sites/' + site.id"
        class="list-group-item list-group-item-action"
      >
        <div class="row">
          <div class="col d-flex">
            <div class="d-flex align-items-center">
              <div class="flex-shrink-0">
                <i class="bi bi-geo fs-4"></i>
              </div>
              <div class="flex-grow-1 ms-3">
                <b class="text-dark">{{ site.name }}</b>
                <p class="small text-muted mb-0">
                  <span>{{
                    (site.address_line1 ? site.address_line1 + ", " : "") +
                    (site.city ? site.city + ", " : "") +
                    (site.county ? site.county + ", " : "") +
                    (site.post_code || "")
                  }}</span>
                </p>
              </div>
            </div>
          </div>
          <div class="col-2 d-flex">
            <div class="d-block text-end me-3 ms-auto">
              <p class="small text-muted mb-0"><span>Created on</span></p>
              <b class="text-dark text-muted small">{{
                formattedTimestamp(site.created_time)
              }}</b>
            </div>
            <i class="bi bi-chevron-right align-self-center"></i>
          </div>
        </div>
      </router-link>
    </div>
    <div class="card-footer bg-light">
      <pagination
        v-if="paginator.current_page"
        :records="paginator.total_items"
        :per-page="10"
        v-model="current_page"
        :options="paginatorOptions"
      ></pagination>
    </div>
  </div>

  <ModalSiteNew id="newSite" />
</template>

<script>
import axios from "axios";
import Pagination from "v-pagination-3";
import moment from "moment";

import RTPagination from "@/components/RTPagination.vue";

import ModalSiteNew from "@/components/modals/ModalSiteNew.vue";

import Utils from "@/helpers/utils";

export default {
  name: "OrganizationSites",
  components: { Pagination, RTPagination, ModalSiteNew },
  data() {
    return {
      paginatorOptions: {
        template: RTPagination,
      },
      paginator: {},
      filter: {
        name: "",
      },
      sites: [],
      sortby: "created_desc",
      current_page: 1,
    };
  },
  mounted() {
    this.emitter.on("organization_site_added", (data) => {
      let newSiteModal = bootstrap.Modal.getInstance(
        document.getElementById("newSite")
      );
      newSiteModal.hide();

      this.emitter.emit("popup_alert", {
        type: "success",
        text: "Site " + data + " was successfully created!",
      });

      this.fetch_organization_sites();
    });

    this.fetch_organization_sites();
  },
  methods: {
    fetch_organization_sites() {
      axios
        .get(
          process.env.VUE_APP_URL +
            "/organizations/" +
            this.$route.params.organization_id +
            "/sites?items=10&page=" +
            this.current_page +
            "&filter[site.name]=" +
            encodeURIComponent(this.filter.name) +
            "&order=" +
            this.sortby,
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then((response) => {
          if (200 === response.status) {
            let item;
            this.organizations = [];

            this.sites = response.data.data;
            this.paginator = response.data.paginator;
          } else {
            process.appdata.logout();
          }
        });
    },
    capitalizeText(text, allwords) {
      return Utils.capitalizeText(text, allwords);
    },
    timeAgo(dateString) {
      return Utils.timeAgo(dateString);
    },
    localMachineTime(dateString) {
      return Utils.localMachineTime(dateString);
    },
    formattedTimestamp(date) {
      return moment(this.localMachineTime(date)).format("DD MMM YYYY");
    },
  },
  watch: {
    sortby: function () {
      this.current_page = 1;
      this.fetch_organization_sites();
    },
    current_page: function () {
      this.fetch_organization_sites();
    },
  },
};
</script>
