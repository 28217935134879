<template>
  <Breadcrumbs
    :breaditems="[{ title: 'Admin', link: '/admin' }, { title: 'Sites' }]"
  />
  <div id="main" class="container">
    <div class="d-flex bd-highlight">
      <div class="flex-grow-1 me-2">
        <div class="input-group">
          <button class="input-group-text bg-white" @click="fetch_sites()">
            <i class="bi bi-search"></i>
          </button>
          <input
            type="text"
            class="form-control"
            placeholder="Search..."
            v-model="filter.name"
            v-on:keyup.enter="fetch_sites()"
          />
        </div>
      </div>
      <div class="bd-highlight me-2">
        <select class="form-select" v-model="filter.organization">
          <option
            v-for="organization in organizations"
            v-bind:key="organization.id"
            :value="organization.id"
          >
            {{ organization.name + " (" + organization.site_count + ")" }}
          </option>
        </select>
      </div>
      <div class="bd-highlight me-2">
        <select class="form-select" v-model="filter.status">
          <option value="all">{{ "All Sites (" + totals.all + ")" }}</option>
          <option value="active">
            {{ "Active (" + totals.active + ")" }}
          </option>
          <option value="deleted">
            {{ "Deleted (" + totals.deleted + ")" }}
          </option>
        </select>
      </div>
      <div class="bd-highlight">
        <select class="form-select" v-model="sortby">
          <option value="created_desc">Date created DESC</option>
          <option value="created_asc">Date created ASC</option>
          <option value="name_desc">Name DESC</option>
          <option value="name_asc">Name ASC</option>
        </select>
      </div>
    </div>

    <div class="card mt-2">
      <div class="card-header bg-light">Sites</div>
      <div class="list-group list-group-flush">
        <router-link
          v-for="site in sites"
          v-bind:key="site.id"
          :to="'/sites/' + site.id"
          class="list-group-item list-group-item-action"
        >
          <div class="row">
            <div class="col d-flex">
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                  <i class="bi bi-geo fs-4"></i>
                </div>
                <div class="flex-grow-1 ms-3">
                  <b class="text-dark">{{ site.name }}</b>
                  <p class="small text-muted mb-0">
                    <span>
                      {{
                        (site.address_line1 || "") +
                        ", " +
                        (site.city || "") +
                        ", " +
                        (site.post_code || "")
                      }}</span
                    >
                  </p>
                </div>
              </div>
            </div>
            <div class="col-4 d-flex">
              <div class="d-block text-end me-3 ms-auto">
                <b class="text-dark">{{
                  site.organization ? site.organization.name : ""
                }}</b>
                <p class="small text-muted mb-0">
                  <span>{{
                    site.organization ? site.organization.industry : ""
                  }}</span>
                </p>
              </div>
              <i class="bi bi-chevron-right align-self-center"></i>
            </div>
          </div>
        </router-link>
      </div>
      <div class="card-footer bg-light">
        <pagination
          v-if="paginator.current_page"
          :records="paginator.total_items"
          :per-page="10"
          v-model="current_page"
          :options="paginatorOptions"
        ></pagination>
      </div>
    </div>
  </div>
</template>
  
  <script>
import axios from "axios";
import Pagination from "v-pagination-3";

import Breadcrumbs from "@/components/Breadcrumbs.vue";
import RTPagination from "@/components/RTPagination.vue";

import Utils from "@/helpers/utils";

export default {
  name: "SitesPage",
  components: { Breadcrumbs, Pagination, RTPagination },
  data() {
    return {
      paginatorOptions: {
        template: RTPagination,
      },
      paginator: {},
      sites: [],
      organizations: [],
      totals: {
        all: 0,
        active: 0,
        deleted: 0,
      },
      filter: {
        name: "",
        organization: "",
        status: "active",
      },
      sortby: "created_desc",
      current_page: 1,
    };
  },
  mounted() {
    this.fetch_sites();
  },
  methods: {
    fetch_sites() {
      let url =
        process.env.VUE_APP_URL +
        "/admin/sites?items=10&page=" +
        this.current_page +
        "&order=" +
        this.sortby;

      if ("" !== this.filter.name) {
        url += "&name=" + encodeURIComponent(this.filter.name);
      }

      if ("" !== this.filter.organization) {
        url += "&organization=" + this.filter.organization;
      }

      if ("" !== this.filter.status) {
        url += "&status=" + this.filter.status;
      }

      axios
        .get(url, {
          headers: {
            Authorization: process.appdata.tokens.access_token,
          },
        })
        .then((response) => {
          if (200 === response.status) {
            this.sites = response.data.data;
            this.paginator = response.data.paginator;

            this.organizations = response.data.organizations;
            this.organizations.unshift({
              id: "",
              name: "All Organisations",
              site_count: response.data.organizations.reduce((prev, curr) => {
                return prev + (parseInt(curr.site_count) || 0);
              }, 0),
            });

            this.totals.active = response.data.totals.active || 0;
            this.totals.deleted = response.data.totals.deleted || 0;
            this.totals.all = this.totals.active + this.totals.deleted;
          } else {
            process.appdata.logout();
          }
        });
    },
    capitalizeText(text, allwords) {
      return Utils.capitalizeText(text, allwords);
    },
    timeAgo(dateString) {
      return Utils.timeAgo(dateString);
    },
    localReadableTime(dateString) {
      return Utils.localReadableTime(dateString);
    },
    localMachineTime(dateString) {
      return Utils.localMachineTime(dateString);
    },
  },
  watch: {
    "filter.organization": function () {
      this.current_page = 1;
      this.fetch_sites();
    },
    "filter.status": function () {
      this.current_page = 1;
      this.fetch_sites();
    },
    sortby: function () {
      this.current_page = 1;
      this.fetch_sites();
    },
    current_page: function () {
      this.fetch_sites();
    },
  },
};
</script>