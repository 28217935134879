<template>
  <div class="tree-view mb-3" :id="id">
    <div class="d-flex">
      <input type="text" v-on:keyup="filterNodes" class="form-control" placeholder="Type to filter" />
      <button type="button" class="btn btn-link text-decoration-none font-weight-bold"
        style="max-height: 36px; overflow: hidden;" v-on:click="toggleTree">
        <i class="bi bi-caret-down-fill"></i>
      </button>
    </div>
    <ul class="d-none p-0 my-2">
      <TreeNode v-for="item in treeData" v-bind:key="item.id" :node="item" :onChildSelect="selectChild"></TreeNode>
    </ul>
  </div>
</template>

<script>
import TreeNode from "@/components/TreeNode.vue";

export default {
  name: "TreeView",
  components: { TreeNode },
  props: {
    id: String,
    treeData: {
      type: Array,
      default: [],
    },
    values: {
      type: Array,
      default: [],
    },
    onUpdate: Function,
  },
  data() {
    return {
      valuesData: this.values,
    };
  },
  mounted() {
    jQuery("#" + this.id + ">ul").addClass("d-none");

    this.valuesData = this.values;
    this.fillValues();
  },
  updated() {
    this.valuesData = this.values;
    this.fillValues();
  },
  methods: {
    toggleTree(ev) {
      if (jQuery("#" + this.id + ">ul").hasClass("d-none")) {
        jQuery(ev.target)
          .removeClass("bi-caret-down-fill")
          .addClass("bi-caret-up-fill");

        jQuery("#" + this.id + ">ul").removeClass("d-none");
      } else {
        jQuery(ev.target)
          .removeClass("bi-caret-up-fill")
          .addClass("bi-caret-down-fill");

        jQuery("#" + this.id + ">ul").addClass("d-none");
      }
    },
    fillValues() {
      jQuery("i.bi-caret-up-fill")
        .removeClass("bi-caret-up-fill")
        .addClass("bi-caret-down-fill");

      jQuery("i.bi-check-square")
        .removeClass("bi-check-square")
        .addClass("bi-square");
      jQuery("#" + this.id + " input[type='checkbox']").prop("checked", false);

      this.valuesData.forEach(function (item) {
        let target = jQuery("#" + item),
          targetParent = target.closest(".tree-node");

        target.prop("checked", true);
        targetParent
          .find("i")
          .removeClass("bi-square")
          .addClass("bi-check-square");
      });
    },
    selectChild(ev) {
      const target = jQuery(ev.target),
        targetChecked = target.prop("checked"),
        targetId = target.prop("id"),
        targetParent = target.closest(".tree-node");

      targetParent.find("input[type='checkbox']").each(function (ndx, item) {
        let element = jQuery(item);

        if (element.prop("id") != targetId) {
          element.prop("checked", false);
        }
      });

      if (targetChecked) {
        targetParent
          .find("i")
          .removeClass("bi-square")
          .addClass("bi-check-square");
      } else {
        targetParent
          .find("i")
          .removeClass("bi-check-square")
          .addClass("bi-square");
      }

      this.syncValues();
    },
    filterNodes(ev) {
      var filter = ev.target.value;

      jQuery("#" + this.id + " li").each(function (ndx, item) {
        let element = jQuery(item);
        if (0 === element.find("label:contains('" + filter + "')").length) {
          element.addClass("tree-node-hidden");
        } else {
          element.removeClass("tree-node-hidden");
        }
      });
    },
    syncValues() {
      let app = this;

      this.valuesData = [];

      jQuery("#" + this.id + " input[type='checkbox']:checked").each(function (
        ndx,
        item
      ) {
        app.valuesData.push(item.id);
      });

      this.onUpdate({
        id: this.id,
        values: this.valuesData,
      });
    },
  },
};
</script>