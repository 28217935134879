<template>
  <div class="d-flex bd-highlight">
    <div class="flex-grow-1">
      <div class="input-group">
        <button
          v-on:click="fetch_user_services()"
          type="button"
          class="input-group-text bg-white"
        >
          <i class="bi bi-search"></i>
        </button>
        <input
          type="text"
          class="form-control"
          placeholder="Search..."
          v-model="filter.service_name"
          v-on:keyup.enter="fetch_user_services()"
        />
      </div>
    </div>
  </div>

  <div class="card mt-2">
    <div class="card-header bg-light">
      <i class="bi bi-window-sidebar me-2"></i>
      Services
    </div>

    <div v-if="0 === services.length" class="card-body">
      <div class="text-center">
        <img src="/images/empty-services.svg" class="img-fluid py-5" />
      </div>
    </div>

    <div v-else class="list-group list-group-flush">
      <div
        class="list-group-item"
        v-for="service in services"
        v-bind:key="service.service.id"
      >
        <div class="row">
          <div class="col">
            <div class="d-flex align-items-center">
              <div class="flex-shrink-0">
                <img
                  :src="service.service.logo"
                  :alt="service.service.name"
                  class="align-self-center"
                  width="24"
                  height="24"
                  onerror="this.onerror=null;this.src='/images/window-sidebar.svg';"
                />
              </div>
              <div class="flex-grow-1 ms-3">
                <b class="text-dark">{{ service.service.name }}</b>
                <p class="small text-muted mb-0">
                  {{ service.service.description }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer bg-light">
      <pagination
        v-if="paginator.current_page"
        :records="paginator.total_items"
        :per-page="10"
        v-model="current_page"
        :options="paginatorOptions"
      ></pagination>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Pagination from "v-pagination-3";

import RTPagination from "@/components/RTPagination.vue";

import Utils from "@/helpers/utils";

export default {
  name: "UserServices",
  components: { Pagination, RTPagination },
  data() {
    return {
      paginatorOptions: {
        template: RTPagination,
      },
      paginator: {},
      filter: {
        service_name: "",
      },
      services: [],
      current_page: 1,
    };
  },
  mounted() {
    this.fetch_user_services();
  },
  methods: {
    fetch_user_services() {
      axios
        .get(
          process.env.VUE_APP_URL +
            "/admin/users/" +
            this.$route.params.user_id +
            "/services?items=10&page=" +
            this.current_page +
            "&filter[service.name]=" +
            encodeURIComponent(this.filter.service_name),
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then((response) => {
          if (200 === response.status) {
            this.services = response.data.data;
            this.paginator = response.data.paginator;
          } else {
            process.appdata.logout();
          }
        });
    },
    capitalizeText(text, allwords) {
      return Utils.capitalizeText(text, allwords);
    },
  },
  watch: {
    "filter.service_name": function () {
      this.fetch_user_services();
    },
    current_page: function () {
      this.fetch_user_services();
    },
  },
};
</script>