<template>
  <Breadcrumbs :breaditems="[{ title: 'My account' }]" />
  <div class="container">
    <div class="row g-2">
      <div class="col-md-3 mt-2">
        <div class="card">
          <div class="card-header bg-light">Menu</div>
          <div class="list-group list-group-flush">
            <router-link
              to="/account"
              :class="
                ('overview' === active_menu ? 'active ' : '') +
                'list-group-item list-group-item-action d-flex gap-2 align-items-center'
              "
              @click="setActiveMenu('overview')"
            >
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                  <i class="bi bi-app-indicator"></i>
                </div>
                <div class="flex-grow-1 ms-3">My Account</div>
              </div>
            </router-link>
            <router-link
              :to="'/account/sessions'"
              :class="
                ('sessions' === active_menu ? 'active ' : '') +
                'list-group-item list-group-item-action d-flex gap-2 align-items-center'
              "
              @click="setActiveMenu('sessions')"
            >
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                  <i class="bi bi-geo"></i>
                </div>
                <div class="flex-grow-1 ms-3">Sessions</div>
              </div>
              <span class="badge bg-light ms-auto text-dark border fw-normal">{{
                me.stats.sessions
              }}</span>
            </router-link>
            <router-link
              :to="'/account/logs'"
              :class="
                ('logs' === active_menu ? 'active ' : '') +
                'list-group-item list-group-item-action d-flex gap-2 align-items-center'
              "
              @click="setActiveMenu('logs')"
            >
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                  <i class="bi bi-people-fill"></i>
                </div>
                <div class="flex-grow-1 ms-3">Activity Logs</div>
              </div>
              <span
                class="badge bg-light ms-auto text-dark border fw-normal"
              ></span>
            </router-link>


            <router-link v-if="isTester" :to="'/account/roles'" :class="
              ('roles' === active_menu ? 'active ' : '') +
              'list-group-item list-group-item-action d-flex gap-2 align-items-center'
            " @click="setActiveMenu('roles')">
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                  <i class="bi bi-person-square"></i>
                </div>
                <div class="flex-grow-1 ms-3">Service roles</div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-md-9 mt-2">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import Breadcrumbs from "@/components/Breadcrumbs.vue";

export default {
  name: "MyAccountPage",
  components: { Breadcrumbs },
  data() {
    return {
      active_menu: "overview",
      me: {
        stats: {},
      },
      isTester: "tester" === process.appdata.user.type ? true : false,
    };
  },
  created() {
    this.active_menu = this.$route.meta.submenu;
  },
  mounted() {
    this.emitter.on("user_avatar_updated", () => {
      this.fetch_me_stats();
    });
    this.emitter.on("user_privacy_updated", () => {
      this.fetch_me_stats();
    });

    this.fetch_me_stats();
  },
  methods: {
    setActiveMenu(menu) {
      this.active_menu = menu;
    },
    fetch_me_stats() {
      axios
        .get(process.env.VUE_APP_URL + "/me/stats", {
          headers: {
            Authorization: process.appdata.tokens.access_token,
          },
        })
        .then((response) => {
          if (200 === response.status) {
            this.me = response.data;
          } else {
            process.appdata.logout();
          }
        });
    },
  },
};
</script>