<template>
	<div class="position-relative">
		<p class="mb-1">Mute organisations and Sites</p>
		<input v-model="site_filter" :id="id + '_filter'" type="text" class="form-control form-control-sm"
			placeholder="Search" />
		<button v-on:click="reset_filter" class="btn btn-sm position-absolute" style="margin-top: -30px; right: 0">
			<i class="bi bi-x-circle"></i>
		</button>
	</div>

	<div v-for="(organisation, organisation_id) in organisations">
		<i v-if="expanded" class="bi bi-caret-down-fill small me-2"></i>
		<i v-else class="bi bi-caret-right-fill small me-2"></i>
		<input type="checkbox" class="me-2" :data-organisation-id="organisation_id"
			:checked="-1 < preferences.muted_organisations.indexOf(organisation_id)" v-on:change="toggleOrganisation" />
		<button class="btn btn-link btn-sm text-decoration-none p-0 me-2" v-on:click="expand(organisation_id)">
			<span>{{ organisation.name }}</span>
		</button>
		<ul class="list-unstyled d-none" :id="id + '_sites_' + organisation_id">
			<li v-for="site in organisation.sites" v-bind:key="site.id" class="px-5">
				<div v-if="-1 < site.name.toLowerCase().indexOf(site_filter.toLowerCase())">
					<input type="checkbox" class="me-2" :data-org-id="organisation_id" :data-site-id="site.id"
						:id="id + '_site_' + site.id" :checked="-1 < preferences.muted_organisations.indexOf(organisation_id) ||
							-1 < preferences.muted_sites.indexOf(site.id)
							" v-on:change="toggleSite" />
					<label :for="id + '_site_' + site.id" class="small fw-bold">{{
						site.name
					}}</label>
				</div>
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: "OrganisationSites",
	props: {
		id: String,
		prefs: {
			type: Object,
		},
		orgs: {
			type: Object,
		},
		updated: {
			type: Function,
		},
	},
	data() {
		return {
			expanded: false,
			preferences: {
				muted_organisations: [],
				muted_sites: [],
			},
			organisations: {},
			site_filter: ""
		};
	},
	mounted() {
		this.preferences = this.prefs;
	},
	updated() {
		this.organisations = this.orgs;
	},
	methods: {
		expand(orgid) {
			this.expanded = !this.expanded;

			jQuery("#" + this.id + "_sites_" + orgid).toggleClass("d-none");
		},

		reset_filter() {
			this.site_filter = "";
		},

		toggleOrganisation(ev) {
			let organisationId = jQuery(ev.target).data().organisationId,
				checked = jQuery(ev.target).prop("checked"),
				mutedOrganisations = this.preferences.muted_organisations,
				mutedSites = this.preferences.muted_sites,
				mutedIndex =
					this.preferences.muted_organisations.indexOf(organisationId),
				app = this,
				tmpSiteId = "";

			jQuery("#" + this.id + "_sites_" + organisationId)
				.find("input[type='checkbox']")
				.prop("checked", checked);

			if (checked) {
				jQuery("[data-site-id][data-org-id='" + organisationId + "']").each(
					function () {
						tmpSiteId = jQuery(this).data("site-id");

						mutedSites.splice(
							app.preferences.muted_sites.indexOf(tmpSiteId),
							1
						);
					}
				);
			}

			if (true === checked) {
				if (-1 === mutedIndex) {
					mutedOrganisations.push(organisationId);
				}
			} else {
				mutedOrganisations.splice(mutedIndex, 1);
			}

			this.preferences.muted_organisations = mutedOrganisations;
			this.preferences.muted_sites = mutedSites;

			this.update_settings();
		},

		toggleSite(ev) {
			let siteId = jQuery(ev.target).data().siteId,
				organisationId = jQuery(ev.target).data().orgId,
				checked = jQuery(ev.target).prop("checked"),
				mutedSites = this.preferences.muted_sites,
				mutedIndex = this.preferences.muted_sites.indexOf(siteId);

			if (true === checked) {
				if (-1 === mutedIndex) {
					mutedSites.push(siteId);
				}
			} else {
				if (-1 < mutedIndex) {
					mutedSites.splice(mutedIndex, 1);
				}

				jQuery("input[data-organisation-id='" + organisationId + "']").prop(
					"checked",
					false
				);
			}

			this.preferences.muted_sites = mutedSites;

			this.update_settings();
		},

		update_settings() {
			this.updated({
				event: this.id,
				preferences: this.preferences,
			});
		},
	},
};
</script>