<template>
  <div class="card">
    <div class="card-header bg-light d-flex">
      <i class="bi bi-app-indicator me-2"></i>
      Overview
      <span class="ms-auto text-muted small align-self-center">Last activity {{ timeAgo(organization.last_activity)
      }}</span>
    </div>
    <div class="card-body">
      <ul class="list-group">
        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-app me-3"></i>Photo:
          </div>
          <div class="w-75 py-2 px-3">
            <img :src="organization.logo" :alt="organization.name" class="align-self-center" width="21" height="21"
              onerror="this.src='/images/building.svg';" />
            <button type="button" class="btn btn-link p-0 float-end text-decoration-none" data-bs-toggle="modal"
              data-bs-target="#organizationLogo">
              Update
            </button>
          </div>
        </li>

        <li class="list-group-item d-flex p-0 border-0 mt-1">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-building me-3"></i>Name:
          </div>
          <div class="w-75 py-2 px-3">
            {{ organization.name }}
            <button type="button" class="btn btn-link p-0 float-end text-decoration-none"
              @click="refresh_organization()">
              Refresh
            </button>
          </div>
        </li>

        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-hash me-3"></i>Company Number:
          </div>
          <div class="w-75 py-2 px-3">
            <span class="font-monospace small">{{
                organization.company_number
            }}</span>
            <a v-if="organization.company_number" :href="
              'https://find-and-update.company-information.service.gov.uk/company/' +
              organization.company_number
            " target="_blank" class="float-end text-decoration-none">Companies House</a>
          </div>
        </li>

        <li class="list-group-item d-flex p-0 border-0 mt-1">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-card-text me-3"></i>Description:
          </div>
          <div class="w-75 py-2 px-3">
            {{ organization.description }}
            <button type="button" class="btn btn-link p-0 float-end text-decoration-none" data-bs-toggle="modal"
              data-bs-target="#organizationDescription">
              Update
            </button>
          </div>
        </li>

        <li class="list-group-item d-flex p-0 border-0 mt-1">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-geo me-3"></i>Address:
          </div>
          <div class="w-75 py-2 px-3">
            {{
                organization.address_line1 +
                ", " +
                organization.city +
                ", " +
                organization.post_code
            }}
            <button type="button" class="btn btn-link p-0 float-end text-decoration-none" data-bs-toggle="modal"
              data-bs-target="#organizationAddress">
              Update
            </button>
          </div>
        </li>

        <li class="list-group-item d-flex p-0 border-0 mt-1">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-briefcase me-3"></i>Industry:
          </div>
          <div class="w-75 py-2 px-3">
            {{ organization.industry }}
            <button type="button" class="btn btn-link p-0 float-end text-decoration-none" data-bs-toggle="modal"
              data-bs-target="#organizationIndustry">
              Update
            </button>
          </div>
        </li>

        <li class="list-group-item d-flex p-0 border-0 mt-1">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-card-list me-3"></i>Type:
          </div>
          <div class="w-75 py-2 px-3">
            {{ organization_type_text }}
            <button v-if="isAdmin" type="button" class="btn btn-link p-0 float-end text-decoration-none"
              data-bs-toggle="modal" data-bs-target="#organizationType">
              Update
            </button>
          </div>
        </li>

        <li v-if="'customer' === organization.type.id" class="list-group-item d-flex p-0 border-0 mt-1">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-star-fill me-3"></i>Partner:
          </div>
          <div class="w-75 py-2 px-3">
            <a :href="'/organisations/' + organization.partner.id" style="margin-right:15px;">{{ organization.partner.name
            }}</a>
          </div>
        </li>

        <li v-if="organization.customers && 0 < organization.customers.length" class="list-group-item d-flex p-0 border-0 mt-1">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-star-fill me-3"></i>Customers:
          </div>
          <div class="w-75 py-2 px-3">
            <a v-for="customer in organization.customers" v-bind:key="customer.id"
              :href="'/organisations/' + customer.id" style="margin-right:15px;">{{ customer.name }}</a>
          </div>
        </li>
      </ul>
    </div>
  </div>

  <div class="card mt-2">
    <div class="card-header bg-light">
      <i class="bi bi-calendar-check me-2"></i>
      Created
    </div>
    <div class="card-body">
      <ul class="list-group">
        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-person-circle me-3"></i>Created By:
          </div>
          <div class="w-75 py-2 px-3">
            <router-link v-if="organization.creator.id" :to="'/admin/users/' + organization.creator.id"
              class="text-decoration-none">{{ organization.creator.full_name }}</router-link>
            <router-link v-if="organization.creator.organization" :to="
              '/admin/organisations/' + organization.creator.organization.id
            " class="float-end text-decoration-none">{{ organization.creator.organization.name }}</router-link>
          </div>
        </li>
        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-calendar-event me-3"></i>Created On:
          </div>
          <div class="w-75 py-2 px-3">
            {{
                formattedTimestamp(organization.created_time) +
                " · " +
                timeAgo(organization.created_time)
            }}
          </div>
        </li>
      </ul>
    </div>
  </div>

  <div class="card mt-2" v-if="isAdmin">
    <ul class="list-group list-group-flush">
      <li class="list-group-item">
        <div v-if="0 === organization.deleted" class="d-flex align-items-center">
          <div class="flex-shrink-0">
            <i class="bi bi-exclamation-triangle-fill fs-4 text-danger"></i>
          </div>
          <div class="flex-grow-1 ms-3">
            <b class="text-dark">Delete this Organisation</b>
            <p class="small text-muted mb-0">
              This will be marked as inactive and will dissapear for normal
              users.
            </p>
          </div>
          <button type="button" class="btn btn-outline-danger" data-bs-toggle="modal"
            data-bs-target="#organizationDelete">
            Delete Organisation
          </button>
        </div>
        <div v-else class="d-flex align-items-center">
          <div class="flex-shrink-0">
            <i class="bi bi-exclamation-triangle-fill fs-4 text-info"></i>
          </div>
          <div class="flex-grow-1 ms-3">
            <b class="text-dark">Restore this Organisation</b>
            <p class="small text-muted mb-0">
              This will be marked as active and will appear again for normal
              users.
            </p>
          </div>
          <button type="button" class="btn btn-outline-success" data-bs-toggle="modal"
            data-bs-target="#organizationRestore">
            Restore Organisation
          </button>
        </div>
      </li>
    </ul>
  </div>

  <ModalOrganizationLogo id="organizationLogo" />
  <ModalOrganizationDescription id="organizationDescription" />
  <ModalOrganizationIndustry id="organizationIndustry" />
  <ModalOrganizationType id="organizationType" />
  <ModalOrganizationAddress id="organizationAddress" />
  <ModalOrganizationDelete v-if="isAdmin" id="organizationDelete" />
  <ModalOrganizationRestore v-if="isAdmin" id="organizationRestore" />
</template>

<script>
import axios from "axios";
import moment from "moment";

import ModalOrganizationLogo from "@/components/modals/ModalOrganizationLogo.vue";
import ModalOrganizationDescription from "@/components/modals/ModalOrganizationDescription.vue";
import ModalOrganizationIndustry from "@/components/modals/ModalOrganizationIndustry.vue";
import ModalOrganizationType from "@/components/modals/ModalOrganizationType.vue";
import ModalOrganizationAddress from "@/components/modals/ModalOrganizationAddress.vue";
import ModalOrganizationDelete from "@/components/modals/ModalOrganizationDelete.vue";
import ModalOrganizationRestore from "@/components/modals/ModalOrganizationRestore.vue";

import Utils from "@/helpers/utils";

export default {
  name: "OrganizationOverview",
  components: {
    ModalOrganizationLogo,
    ModalOrganizationDescription,
    ModalOrganizationIndustry,
    ModalOrganizationType,
    ModalOrganizationAddress,
    ModalOrganizationDelete,
    ModalOrganizationRestore,
  },
  computed: {
    organization_type_text: function () {
      let typeText = "";

      switch (this.organization.type.id) {
        case "customer":
          typeText = "Customer";
          break;
        case "partner_certified":
          typeText = "Certified Partner";
          break;
        case "partner_premier":
          typeText = "Premier Partner";
          break;
        case "partner_elite":
          typeText = "Elite Partner";
          break;
      }

      return typeText;
    },
  },
  data() {
    return {
      organization: {
        id: this.$route.params.organization_id,
        name: "",
        address_line1: "",
        address_line2: "",
        post_code: "",
        city: "",
        descripton: "",
        industry: "",
        deleted: 0,
        creator: {},
        type: "",
        partner: "",
        customers: []
      },
      isAdmin:
        "admin" === process.appdata.user.type ||
          (process.appdata.user.rootOrganization &&
            process.env.VUE_APP_RT_NUMBER ===
            process.appdata.user.rootOrganization.company_number)
          ? true
          : false,
    };
  },
  mounted() {
    this.emitter.on("organization_logo_updated", () => {
      let organizationLogoModal = bootstrap.Modal.getInstance(
        document.getElementById("organizationLogo")
      );
      organizationLogoModal.hide();

      this.emitter.emit("popup_alert", {
        type: "success",
        text:
          "The logo of " + this.organization.name + " was succesfuly updated!",
      });

      this.fetch_organization();
    });

    this.emitter.on("organization_description_updated", () => {
      let organizationDescriptionModal = bootstrap.Modal.getInstance(
        document.getElementById("organizationDescription")
      );
      organizationDescriptionModal.hide();

      this.emitter.emit("popup_alert", {
        type: "success",
        text:
          "The description of " +
          this.organization.name +
          " was succesfuly updated!",
      });

      this.fetch_organization();
    });

    this.emitter.on("organization_industry_updated", () => {
      let organizationIndustrynModal = bootstrap.Modal.getInstance(
        document.getElementById("organizationIndustry")
      );
      organizationIndustrynModal.hide();

      this.emitter.emit("popup_alert", {
        type: "success",
        text:
          "The industry of " +
          this.organization.name +
          " was succesfuly updated!",
      });

      this.fetch_organization();
    });

    this.emitter.on("organization_address_updated", () => {
      let organizationAddressModal = bootstrap.Modal.getInstance(
        document.getElementById("organizationAddress")
      );
      organizationAddressModal.hide();

      this.emitter.emit("popup_alert", {
        type: "success",
        text:
          "The address of " +
          this.organization.name +
          " was succesfuly updated!",
      });

      this.fetch_organization();
    });

    this.emitter.on("organization_type_updated", () => {
      let organizationTypeModal = bootstrap.Modal.getInstance(
        document.getElementById("organizationType")
      );
      organizationTypeModal.hide();

      this.emitter.emit("popup_alert", {
        type: "success",
        text:
          "The type of " + this.organization.name + " was succesfuly changed!",
      });

      this.fetch_organization();
    });

    this.emitter.on("organization_deleted", () => {
      this.emitter.emit("popup_alert", {
        type: "success",
        text: this.organization.name + " was succesfuly deleted!",
      });

      setTimeout(() => {
        window.location.href = "/admin/organisations";
      }, 2500);
    });

    this.emitter.on("organization_restored", () => {
      let organizationRestoreModal = bootstrap.Modal.getInstance(
        document.getElementById("organizationRestore")
      );
      organizationRestoreModal.hide();

      this.emitter.emit("popup_alert", {
        type: "success",
        text: this.organization.name + " was succesfuly restored!",
      });

      this.fetch_organization();
    });

    this.fetch_organization();
  },
  methods: {
    fetch_organization() {
      axios
        .get(
          process.env.VUE_APP_URL +
          "/organizations/" +
          this.$route.params.organization_id,
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then((response) => {
          if (200 === response.status) {
            this.organization = response.data;

            this.emitter.emit("organization_details_loaded", this.organization);
          } else {
            window.location.href = "/404";
          }
        });
    },
    refresh_organization() {
      let app = this;

      axios
        .get(
          process.env.VUE_APP_URL +
          "/organizations/" +
          this.$route.params.organization_id +
          "/refresh",
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .catch(function (error) {
          if (error.response) {
            app.emitter.emit("popup_alert", {
              type: "danger",
              text: error.response.data.error_description,
            });
          } else {
            app.emitter.emit("popup_alert", {
              type: "danger",
              text: "Error occurred",
            });
          }
        })
        .then((response) => {
          if (200 === response.status) {
            this.organization.name = response.data.name;
            this.organization.address_line1 = response.data.address_line1;
            this.organization.address_line2 = response.data.address_line2;
            this.organization.city = response.data.city;
            this.organization.county = response.data.county;
            this.organization.post_code = response.data.post_code;

            this.emitter.emit("organization_details_loaded", this.organization);

            this.emitter.emit("popup_alert", {
              type: "success",
              text:
                "Details of " +
                this.organization.name +
                " were succesfuly refreshed!",
            });
          } else {
            process.appdata.logout();
          }
        });
    },
    capitalizeText(text, allwords) {
      return Utils.capitalizeText(text, allwords);
    },
    timeAgo(dateString) {
      return Utils.timeAgo(dateString);
    },
    formattedTimestamp(date) {
      return moment(this.localMachineTime(date)).format("HH:mm:ss DD/MM/YY");
    },
    localMachineTime(dateString) {
      return Utils.localMachineTime(dateString);
    },
  },
};
</script>
