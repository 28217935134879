<template>
	<div class="dropdown-menu d-block p-0 position-relative">
		<input type="search" :id="'search_' + id" class="form-control" autocomplete="false" :value="selected_label"
			:placeholder="placeholder || ''" @keyup="filter()" @click="toggle_expanded()">
		<ul class="list-unstyled position-absolute" :class="expanded ? '' : 'd-none'" :id="'options_' + id">
			<li v-for="option in options" v-bind:key="option.value">
				<span v-if="true === option.group" class="dropdown-item px-1 fw-bold">
					{{ option.label }}
				</span>
				<span v-else class="dropdown-item" :data-value="option.value" @click="select_value(option)">
					{{ option.label }}
				</span>
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: "SearchableDropdown",
	props: ["id", "value", "placeholder"],
	data() {
		return {
			options_original: [],
			options: [],
			expanded: false,
			selected_label: "",
			selected_value: ""
		};
	},
	mounted() {
		this.emitter.on("dropdown_options_" + this.id, (data) => {
			this.options_original = data;
			this.options = data;
		});
	},
	methods: {
		toggle_expanded() {
			this.expanded = !this.expanded;
		},
		filter() {
			this.selected_label = jQuery.trim(jQuery("#search_" + this.id).val());

			if ("" === this.selected_label) {
				this.options = this.options_original;
			} else {
				this.options = this.options.filter(item => {
					return (true === item.group) || (-1 !== item.label.indexOf(this.selected_label))
				});
			}
		},
		select_value(option) {
			this.selected_label = option.label;
			this.selected_value = option.value;

			this.expanded = false;

			this.emitter.emit("dropdown_selected_" + this.id, option);
		}
	},
};
</script>