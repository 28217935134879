<template>
  <div class="modal fade" tabindex="-1" :id="id">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Update Organization Industry</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12 mb-3">
              <label for="industry" class="form-label">Industry</label>
              <select class="form-control" v-model="industry">
                <option>- -</option>
                <option>Accommodation and Food Service Activities</option>
                <option>Administrative and Support Service Activities</option>
                <option>Agriculture, Forestry and Fishing</option>
                <option>Arts, Entertainment and Recreation</option>
                <option>Construction</option>
                <option>Education</option>
                <option>
                  Electricity, Gas, Steam and Air Conditioning Supply
                </option>
                <option>Financial and Insurance Activities</option>
                <option>Human Health and Social Work Activities</option>
                <option>Information and Communication</option>
                <option>Manufacturing</option>
                <option>Mining and Quarrying</option>
                <option>Other Service Activities</option>
                <option>
                  Professional, Scientific and Technical Activities
                </option>
                <option>Real Estate Activities</option>
                <option>Transportation and Storage</option>
                <option>Water Supply</option>
                <option>
                  Sewerage, Waste Management and Remediation Activities
                </option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <button
                type="button"
                class="btn btn-primary w-100"
                @click="update_organization"
              >
                Update Organization
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ModalOrganizationIndustry",
  props: ["id"],
  data() {
    return {
      industry: "",
    };
  },
  mounted() {
    this.emitter.on("organization_details_loaded", (data) => {
      this.industry = data.industry ? data.industry : "";
    });
  },
  methods: {
    update_organization() {
      let app = this;

      axios
        .patch(
          process.env.VUE_APP_URL +
            "/organizations/" +
            this.$route.params.organization_id +
            "/industry",
          {
            industry: this.industry,
          },
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then(
          (response) => {
            if (200 === response.status) {
              app.emitter.emit("organization_industry_updated");
            } else {
            }
          },
          (error) => {
            console.log(error);
          }
        );
    },
  },
};
</script>