<template>
  <Breadcrumbs
    :breaditems="[{ title: 'Admin', link: '/admin' }, { title: 'Services' }]"
  />
  <div id="main" class="container">
    <div class="d-flex bd-highlight">
      <div class="flex-grow-1 me-2">
        <div class="input-group">
          <button class="input-group-text bg-white" @click="fetch_services()">
            <i class="bi bi-search"></i>
          </button>
          <input
            type="text"
            class="form-control"
            v-model="filter.name"
            v-on:keyup.enter="fetch_services()"
            placeholder="Search..."
          />
        </div>
      </div>
      <div class="bd-highlight me-2">
        <select class="form-select" v-model="filter.type">
          <option value="" selected="selected">
            {{ "All Services (" + totals.all + ")" }}
          </option>
          <option value="public">
            {{ "Public (" + totals.public + ")" }}
          </option>
          <option value="confidential">
            {{ "Confidential (" + totals.confidential + ")" }}
          </option>
        </select>
      </div>
      <div class="bd-highlight">
        <button
          type="button"
          class="btn bg-white border float-end text-decoration-none"
          data-bs-toggle="modal"
          data-bs-target="#newService"
        >
          New Service
        </button>
      </div>
    </div>

    <div class="card mt-2">
      <div class="card-header bg-light">Services</div>

      <div v-if="0 === services.length" class="card-body">
        <div class="text-center">
          <img src="/images/empty-services.svg" class="img-fluid py-5" />
        </div>
      </div>

      <div v-else class="list-group list-group-flush">
        <div class="list-group-item bg-light">
          <div class="row">
            <div class="col-md-3">
              <b class="text-dark small">Name / Description</b>
            </div>
            <div class="col-md-3">
              <b class="text-dark small">Service Type</b>
            </div>
            <div class="col-md-3">
              <b class="text-dark small">Consent Required</b>
            </div>
            <div class="col-md-2">
              <b class="text-dark small">Public Interest</b>
            </div>
            <div class="col-1"></div>
          </div>
        </div>
        <router-link
          v-for="service in services"
          v-bind:key="service.id"
          :to="'/admin/services/' + service.id"
          class="list-group-item list-group-item-action"
        >
          <div class="row">
            <div class="col-md-3">
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                  <img
                    :src="service.logo"
                    :alt="service.name"
                    class="align-self-center"
                    width="36"
                    height="36"
                    onerror="this.onerror=null;this.src='/images/window-sidebar.svg';"
                  />
                </div>
                <div class="flex-grow-1 ms-3">
                  <b class="text-dark">{{ service.name }}</b>
                  <p class="small text-muted mb-0">{{ service.description }}</p>
                </div>
              </div>
            </div>
            <div class="col-md-3 d-flex">
              <div class="align-self-center">
                <span class="badge bg-light text-dark border">{{
                  capitalizeText(service.type)
                }}</span>
              </div>
            </div>
            <div class="col-md-3 d-flex">
              <div class="align-self-center">
                <span class="badge bg-light text-dark border">{{
                  service.require_accept ? "Yes" : "No"
                }}</span>
              </div>
            </div>
            <div class="col-md-2 d-flex">
              <div class="align-self-center">
                <span class="badge bg-light text-dark border">{{
                  service.eligibility ? "Yes" : "No"
                }}</span>
              </div>
            </div>
            <div class="col-1 d-flex">
              <i class="bi bi-chevron-right align-self-center ms-auto"></i>
            </div>
          </div>
        </router-link>
      </div>
      <div class="card-footer bg-light">
        <pagination
          v-if="paginator.current_page"
          :records="paginator.total_items"
          :per-page="10"
          v-model="current_page"
          :options="paginatorOptions"
        ></pagination>
      </div>
    </div>
  </div>

  <ModalServiceNew id="newService" />
</template>
  
<script>
import axios from "axios";
import Pagination from "v-pagination-3";

import Breadcrumbs from "@/components/Breadcrumbs.vue";
import RTPagination from "@/components/RTPagination.vue";

import ModalServiceNew from "@/components/modals/ModalServiceNew.vue";

import Utils from "@/helpers/utils";

export default {
  name: "ServicesPage",
  components: { Breadcrumbs, Pagination, RTPagination, ModalServiceNew },
  data() {
    return {
      paginatorOptions: {
        template: RTPagination,
      },
      paginator: {},
      services: [],
      totals: { all: 0, public: 0, confidential: 0 },
      filter: {
        name: "",
        type: "",
      },
      current_page: 1,
    };
  },
  mounted() {
    this.emitter.on("service_created", (data) => {
      let newServiceModal = bootstrap.Modal.getInstance(
        document.getElementById("newService")
      );
      newServiceModal.hide();

      this.emitter.emit("popup_alert", {
        type: "success",
        text: "Service was successfully created!",
      });

      this.fetch_services();
    });

    this.fetch_services();
  },
  methods: {
    fetch_services() {
      axios
        .get(
          process.env.VUE_APP_URL +
            "/services?items=10&page=" +
            this.current_page +
            "&filter[client.name]=" +
            encodeURIComponent(this.filter.name) +
            "&filter[client.type]=" +
            this.filter.type,
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then((response) => {
          if (200 === response.status) {
            this.services = response.data.data;

            this.paginator = response.data.paginator;

            this.totals = {
              public: response.data.totals.public,
              confidential: response.data.totals.confidential,
              all:
                response.data.totals.public + response.data.totals.confidential,
            };
          } else {
            process.appdata.logout();
          }
        });
    },
    capitalizeText(text, allwords) {
      return Utils.capitalizeText(text, allwords);
    },
    timeAgo(dateString) {
      return Utils.timeAgo(dateString);
    },
    localReadableTime(dateString) {
      return Utils.localReadableTime(dateString);
    },
    localMachineTime(dateString) {
      return Utils.localMachineTime(dateString);
    },
  },
  watch: {
    "filter.type": function () {
      this.fetch_services();
    },
    current_page: function () {
      this.fetch_services();
    },
  },
};
</script>