<template>
  <div class="d-flex bd-highlight">
    <div class="flex-grow-1 me-2">
      <div class="input-group">
        <button
          class="input-group-text bg-white"
          @click="fetch_organization_services()"
        >
          <i class="bi bi-search"></i>
        </button>
        <input
          type="text"
          class="form-control"
          placeholder="Search..."
          v-model="filter.name"
          v-on:keyup.enter="fetch_organization_services()"
        />
      </div>
    </div>
    <div class="bd-highlight">
      <button
        type="button"
        class="btn bg-white border float-end text-decoration-none"
        data-bs-toggle="modal"
        data-bs-target="#newOrganizationService"
      >
        New Service
      </button>
    </div>
  </div>
  <div class="card mt-2">
    <div class="card-header bg-light">
      <i class="bi bi-window-sidebar me-2"></i>
      Services
    </div>

    <div v-if="0 === services.length" class="card-body">
      <div class="text-center">
        <img src="/images/empty-services.svg" class="img-fluid py-5" />
      </div>
    </div>

    <div v-else class="list-group list-group-flush">
      <div
        v-for="service in services"
        v-bind:key="service.key"
        class="list-group-item"
      >
        <div class="row">
          <div class="col">
            <div class="d-flex align-items-center">
              <div class="flex-shrink-0">
                <i class="bi bi-window-sidebar fs-4"></i>
              </div>
              <div class="flex-grow-1 ms-3">
                <b class="text-dark">{{ service.name }}</b>
                <p class="small text-muted mb-0">{{ service.description }}</p>
              </div>
              <div class="">
                <div class="btn-group" role="group" aria-label="Basic example">
                  <a
                    :href="service.redirect_uri"
                    target="_blank"
                    class="text-decoration-none text-secondary"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title=""
                    data-bs-original-title="Open App"
                    aria-label="Open App"
                  >
                    <i class="bi bi-chevron-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer bg-light">
      <pagination
        v-if="paginator.current_page"
        :records="paginator.total_items"
        :per-page="10"
        v-model="current_page"
        :options="paginatorOptions"
      ></pagination>
    </div>
  </div>

  <ModalOrganizationNewService
    id="newOrganizationService"
    :selected="selected_services"
  />
  <ModalOrganizationRemoveService id="removeOrganizationService" />
</template>

<script>
import axios from "axios";
import Pagination from "v-pagination-3";
import moment from "moment";

import RTPagination from "@/components/RTPagination.vue";

import ModalOrganizationNewService from "@/components/modals/ModalOrganizationNewService.vue";
import ModalOrganizationRemoveService from "@/components/modals/ModalOrganizationRemoveService.vue";

import Utils from "@/helpers/utils";

export default {
  name: "OrganizationServices",
  components: {
    Pagination,
    RTPagination,
    ModalOrganizationNewService,
    ModalOrganizationRemoveService,
  },
  data() {
    return {
      paginatorOptions: {
        template: RTPagination,
      },
      paginator: {},
      filter: {
        name: "",
      },
      services: [],
      selected_services: [],
      current_page: 1,
    };
  },
  mounted() {
    this.emitter.on("organization_service_added", (data) => {
      let newServiceModal = bootstrap.Modal.getInstance(
        document.getElementById("newOrganizationService")
      );
      newServiceModal.hide();

      this.emitter.emit("popup_alert", {
        type: "success",
        text: "Service " + data + " was successfully added!",
      });

      this.fetch_organization_services();
    });

    this.emitter.on("organization_service_removed", (data) => {
      let removeServiceModal = bootstrap.Modal.getInstance(
        document.getElementById("removeOrganizationService")
      );
      removeServiceModal.hide();

      this.emitter.emit("popup_alert", {
        type: "success",
        text: "Service " + data + " was successfully removed!",
      });

      this.fetch_organization_services();
    });

    this.emitter.on("services_list_updated", (data) => {
      this.emitter.emit("popup_alert", {
        type: "success",
        text: "Service list was updated!",
      });

      this.fetch_organization_services();
    });

    this.emitter.on("service_interest_registered", (data) => {
      this.emitter.emit("popup_alert", {
        type: "success",
        text: "Access to " + data.name + " was successfully requested!",
      });
    });

    this.fetch_organization_services();

    setTimeout(() => {
      jQuery("[data-bs-toggle='tooltip']").each(function () {
        jQuery(this).tooltip();
      });
    }, 1000);
  },
  methods: {
    fetch_organization_services() {
      axios
        .get(
          process.env.VUE_APP_URL +
            "/organizations/" +
            this.$route.params.organization_id +
            "/services?items=100&page=" +
            this.current_page +
            "&name=" +
            encodeURIComponent(this.filter.name),
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then((response) => {
          if (200 === response.status) {
            this.selected_services = response.data.data.map(function (obj) {
              return obj.id;
            });
            this.services = response.data.data;
            this.paginator = response.data.paginator;
          } else {
            process.appdata.logout();
          }
        });
    },
    capitalizeText(text, allwords) {
      return Utils.capitalizeText(text, allwords);
    },
    timeAgo(dateString) {
      return Utils.timeAgo(dateString);
    },
    openRemoveServiceModal(id, name) {
      this.emitter.emit("organization_service_loaded", {
        service_id: id,
        name: name,
      });

      var removeServiceRole = new bootstrap.Modal(
        document.getElementById("removeOrganizationService"),
        null
      );
      removeServiceRole.show();
    },
  },
};
</script>