import axios from "axios";
import { createApp } from "vue";
import mitt from "mitt";
import VueTelInput from "vue3-tel-input";

import App from "./App.vue";
import router from "./router";

import Storage from "@/helpers/storage";
import RTAuth from "@/helpers/rt-auth";
import Socket from "@/helpers/rt-socket";

/* Import the custom stylesheet */
import "@/assets/styles.css";

const Emitter = mitt();

process.appdata = {};

process.appdata.user = Storage.get("user", true);
process.appdata.tokens = Storage.get("tokens", true);

/* Check every minute if user's token is expired or user's session is revoked */
clearInterval(process.appdata.loginCheck);
process.appdata.loginCheck = setInterval(() => {
    process.appdata.getme();
}, 60000);


/* Remove storage data and redirect to logout URL */
process.appdata.logout = () => {
    Storage.remove("tokens");
    Storage.remove("user");

    window.location.href = "https://" + process.env.VUE_APP_DOMAIN + "/logout?client_id=" + process.env.VUE_APP_CLIENT_ID
};


/* Get information about current user from UMS API */
process.appdata.getme = (callback) => {
    let tokens = Storage.get("tokens", true);

    if (new Date() > tokens.ttl) {
        RTAuth.requestToken();
    }

    axios.get(process.env.VUE_APP_URL + "/me", {
        headers: {
            Authorization: tokens.access_token
        },
    }).then((response) => {
        Storage.set("user", JSON.stringify(response.data));

        if (callback) {
            callback();
        }
    }).catch(function (error) {
        RTAuth.refreshToken();
    });
}


/* Check for ongoing login attempt */
if (
    window.location.search.includes("code=") &&
    window.location.search.includes("state=")
) {
    RTAuth.accessToken(() => {
        process.appdata.getme(() => {
            window.location.href = "/";
        })
    });
} else {
    try {
        if (new Date() > process.appdata.tokens.ttl) {
            RTAuth.requestToken();
        }

        // Finally create the application instance after all information is retrieved
        const app = createApp(App);
        app.config.globalProperties.emitter = Emitter;
        app.use(router);
        app.use(VueTelInput);
        app.mount("#app");

        // Create Websocket
        // app.config.globalProperties.socket = new Socket(
        //     process.env.VUE_APP_SOCKET_URL + "?authorization=" + process.env.VUE_APP_SOCKET_SECRET + "&user=" + process.appdata.user.id,
        //     (data) => { },
        //     (data) => {
        //         switch (data.action) {
        //             case "logout":
        //                 process.appdata.logout();
        //                 break;
        //         }
        //     });
    } catch (err) {
        RTAuth.requestToken();

        console.log(err);
    }
}
