<template>
  <div class="card">
    <div class="card-header bg-light">
      <i class="bi bi-person-badge me-2"></i>
      Sessions
      <button
        type="button"
        class="
          btn btn-small btn-link
          text-danger
          p-0
          text-decoration-none
          float-end
        "
        @click="revoke_all_sessions"
      >
        Revoke all
      </button>
    </div>

    <div v-if="0 === sessions.length" class="card-body">
      <div class="text-center">
        <img src="/images/empty-sessions.svg" class="img-fluid py-5" />
      </div>
    </div>

    <div v-else class="list-group list-group-flush">
      <div
        class="list-group-item"
        v-for="session in sessions"
        v-bind:key="session.id"
      >
        <div class="row g-1">
          <div class="col d-flex">
            <div class="d-flex align-items-center">
              <div class="flex-shrink-0">
                <i
                  v-if="'Desktop' === session.device_type"
                  class="bi bi-laptop fs-4"
                ></i>
                <i v-else class="bi bi-phone-fill fs-4"></i>
              </div>
              <div class="flex-grow-1 ms-3">
                <b class="text-dark d-block">{{
                  (session.ua.os.name || "") +
                  " · " +
                  (session.ua.os.version || "")
                }}</b>
                <span
                  class="small text-muted m-0 text-decoration-none d-block"
                  style="cursor: pointer"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  :data-bs-original-title="session.ip_address"
                  @click="show_location_details(session)"
                  >{{
                    ("ZZ" === session.location.country
                      ? "Local connection"
                      : (session.location.city || "") +
                        ", " +
                        (session.location.country || "")) +
                    " - " +
                    timeAgo(session.created_time)
                  }}</span
                >
              </div>
            </div>
          </div>
          <div class="col d-flex">
            <div
              class="
                bg-light
                align-self-center
                py-1
                px-2
                rounded
                small
                me-3
                ms-auto
                border
              "
            >
              <span class="fw-bold">
                <img
                  :src="session.client.logo"
                  width="15"
                  height="15"
                  class="me-1"
                />
              </span>
              <span class="">{{ session.client.name }}</span>
            </div>
          </div>
          <div class="col-1 d-flex">
            <div
              class="btn-group align-self-center"
              role="group"
              aria-label="Basic example"
            >
              <button
                type="button"
                class="btn btn-link bg-light text-decoration-none text-primary"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title=""
                data-bs-original-title="View Session"
                aria-label="View Session"
                @click="show_ua_details(session)"
              >
                <i class="bi bi-eye"></i>
              </button>
              <button
                type="button"
                class="btn btn-link bg-light text-decoration-none text-danger"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title=""
                data-bs-original-title="Revoke Session"
                aria-label="Revoke Session"
                @click="revoke_session(session)"
              >
                <i class="bi bi-trash"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer bg-light">
      <pagination
        v-if="paginator.current_page"
        :records="paginator.total_items"
        :per-page="10"
        v-model="current_page"
        :options="paginatorOptions"
      ></pagination>
    </div>
  </div>

  <ModalUserAgent id="userAgent" />
  <ModalUserLocation id="userLocation" />
</template>

<script>
import axios from "axios";
import Pagination from "v-pagination-3";
import UAParser from "ua-parser-js";

import ModalUserAgent from "@/components/modals/ModalUserAgent.vue";
import ModalUserLocation from "@/components/modals/ModalUserLocation.vue";
import RTPagination from "@/components/RTPagination.vue";
import Utils from "@/helpers/utils";

export default {
  name: "UserSessions",
  components: { ModalUserAgent, ModalUserLocation, Pagination, RTPagination },
  data() {
    return {
      paginatorOptions: {
        template: RTPagination,
      },
      paginator: {},
      sessions: [],
      current_page: 1,
    };
  },
  mounted() {
    this.fetch_user_services();
  },
  methods: {
    fetch_user_services() {
      axios
        .get(
          process.env.VUE_APP_URL +
            "/admin/users/" +
            this.$route.params.user_id +
            "/sessions?items=10&page=" +
            this.current_page,
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then((response) => {
          if (200 === response.status) {
            let i;

            this.sessions = response.data.data;
            this.paginator = response.data.paginator;

            for (let session in this.sessions) {
              const parser = new UAParser(this.sessions[session].user_agent);
              this.sessions[session].ua = parser.getResult();
            }

            setTimeout(() => {
              jQuery("[data-bs-toggle='tooltip']").each(function () {
                jQuery(this).tooltip();
              });
            }, 1000);
          } else {
            process.appdata.logout();
          }
        });
    },
    show_location_details(session) {
      session.location.ip_address = session.ip_address;

      this.emitter.emit("user_location_loaded", session.location);

      jQuery("#userLocation").modal("show");
    },
    show_ua_details(session) {
      const parser = new UAParser(session.user_agent);
      let userAgent = parser.getResult();
      userAgent.device_type = session.device_type;

      this.emitter.emit("user_agent_loaded", userAgent);

      jQuery("#userAgent").modal("show");
    },
    revoke_all_sessions() {
      this.socket.send({ to: this.$route.params.user_id, action: "logout" });

      axios
        .delete(
          process.env.VUE_APP_URL +
            "/admin/users/" +
            this.$route.params.user_id +
            "/sessions",
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then((response) => {
          if (process.appdata.user.id == this.$route.params.user_id) {
            process.appdata.logout();
          }
        });
    },
    revoke_session(session) {
      this.socket.send({ to: this.$route.params.user_id, action: "logout" });

      axios
        .delete(process.env.VUE_APP_URL + "/sessions/" + session.id, {
          headers: {
            Authorization: process.appdata.tokens.access_token,
          },
        })
        .then((response) => {
          if (process.appdata.user.id == this.$route.params.user_id) {
            process.appdata.logout();
          }
        });
    },
    timeAgo(dateString) {
      return Utils.timeAgo(dateString);
    },
  },
};
</script>