<template>
  <div class="modal fade" tabindex="-1" :id="id">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Update Organization Type</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12 mb-3">
              <label for="type" class="form-label">Organization Type</label>
              <select class="form-control" id="type" v-model="type">
                <option value="">- -</option>
                <option value="customer">Customer</option>
                <option value="partner_certified">Certified Partner</option>
                <option value="partner_premier">Premier Partner</option>
                <option value="partner_elite">Elite Partner</option>
              </select>
            </div>
          </div>

          <div v-if="'customer' === type" class="row">
            <div class="col-md-12 mb-3">
              <label for="partner" class="form-label">Organization Partner</label>
              <select class="form-control" id="partner" v-model="partner">
                <option value="">- -</option>
                <option v-for="organization in partners" v-bind:key="organization.id" :value="organization.id">
                  {{ organization.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <button type="button" class="btn btn-primary w-100" @click="update_organization">
                Update Organization
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ModalOrganizationType",
  props: ["id"],
  data() {
    return {
      organization_id: "",
      type: "",
      partner: "",
      partners: [],
    };
  },
  mounted() {
    this.emitter.on("organization_details_loaded", (data) => {
      this.organization_id = data.id ? data.id : "";
      this.type = data.type ? (null === data.type.id ? "" : data.type.id) : "";
      this.partner = data.partner ? (null === data.partner.id ? "" : data.partner.id) : "";

      this.fetch_partner_organizations();
    });
  },
  methods: {
    fetch_partner_organizations() {
      axios
        .get(
          process.env.VUE_APP_URL + "/partnerorganizations?exclude=" + this.organization_id,
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then((response) => {
          if (200 === response.status) {
            this.partners = response.data.data;
          } else {
            process.appdata.logout();
          }
        });
    },

    update_organization() {
      let app = this;

      axios
        .patch(
          process.env.VUE_APP_URL +
          "/organizations/" +
          this.$route.params.organization_id,
          {
            type: this.type,
            partner: this.partner,
          },
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then(
          (response) => {
            if (200 === response.status) {
              app.emitter.emit("organization_type_updated");
            } else {
            }
          },
          (error) => {
            console.log(error);
          }
        );
    },
  },
};
</script>