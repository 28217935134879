<template>
  <Breadcrumbs
    :breaditems="[
      { title: 'Admin', link: '/admin' },
      { title: 'Organisations' },
    ]"
  />
  <div id="main" class="container">
    <div class="d-flex bd-highlight">
      <div class="flex-grow-1 me-2">
        <div class="input-group">
          <button
            class="input-group-text bg-white"
            @click="fetch_organizations()"
          >
            <i class="bi bi-search"></i>
          </button>
          <input
            type="text"
            class="form-control"
            placeholder="Search..."
            v-model="filter.name"
            v-on:keyup.enter="fetch_organizations()"
          />
        </div>
      </div>
      <div class="bd-highlight me-2">
        <select class="form-select" v-model="filter.status">
          <option value="all">
            {{ "All Organisations (" + totals.all + ")" }}
          </option>
          <option value="active">
            {{ "Active (" + totals.active + ")" }}
          </option>
          <option value="deleted">
            {{ "Deleted (" + totals.deleted + ")" }}
          </option>
        </select>
      </div>
      <div class="bd-highlight me-2">
        <select class="form-select" v-model="sortby">
          <option value="created_desc">Date created DESC</option>
          <option value="created_asc">Date created ASC</option>
          <option value="name_desc">Name DESC</option>
          <option value="name_asc">Name ASC</option>
          <option value="type_desc">Type DESC</option>
          <option value="type_asc">Type ASC</option>
        </select>
      </div>
      <div class="bd-highlight">
        <button
          type="button"
          class="btn border bg-white float-end text-decoration-none"
          data-bs-toggle="modal"
          data-bs-target="#organizationNew"
        >
          New Organisation
        </button>
      </div>
    </div>

    <div class="card my-2">
      <div class="card-header bg-light">Organisations</div>
      <div class="list-group list-group-flush">
        <div class="list-group-item bg-light">
          <div class="row">
            <div class="col-md-3">
              <b class="text-dark small">Name</b>
            </div>
            <div class="col-md-2">
              <b class="text-dark small">Users</b>
            </div>
            <div class="col-md-2">
              <b class="text-dark small">Info</b>
            </div>
            <div class="col-md-1">
              <b class="text-dark small">Type</b>
            </div>
            <div class="col-md-2">
              <b class="text-dark small">Status</b>
            </div>
            <div class="col-md-2">
              <b class="text-dark small">Last Activity</b>
            </div>
          </div>
        </div>
        <router-link
          v-for="organization in organizations"
          v-bind:key="organization.id"
          :to="'/admin/organisations/' + organization.id"
          class="list-group-item list-group-item-action filtered-list-item"
        >
          <div class="row">
            <div class="col-md-3 d-flex">
              <div class="align-items-center text-truncate">
                <b class="text-dark">{{ organization.name }}</b>
                <p class="small text-muted mb-0">
                  <span>{{ organization.industry }}</span>
                </p>
              </div>
            </div>
            <div class="col-md-2 d-flex">
              <div class="align-self-center">
                <p class="mb-0">
                  <span class="badge bg-primary">{{
                    organization.members_count + " members"
                  }}</span>
                </p>
                <p class="mb-0">
                  <span class="badge bg-secondary">{{
                    organization.collaborator_count + " collaborators"
                  }}</span>
                </p>
              </div>
            </div>
            <div class="col-md-2 d-flex">
              <div class="align-self-center">
                <p class="mb-0">
                  <span class="badge bg-secondary">{{
                    organization.sites_count + " sites"
                  }}</span>
                </p>
                <p class="mb-0">
                  <span class="badge bg-secondary">{{
                    organization.subscriptions_count + " services"
                  }}</span>
                </p>
              </div>
            </div>
            <div class="col-1 d-flex">
              <p class="small">{{ organization.type.label }}</p>
            </div>
            <div class="col-md-2 d-flex">
              <div class="align-self-center">
                <b v-if="0 === organization.deleted" class="small text-success"
                  >Active</b
                >
                <b v-else class="small text-danger">Deleted</b>
                <p class="small text-muted mb-0">
                  <span>{{ timeAgo(organization.updated_time) }}</span>
                </p>
              </div>
            </div>
            <div class="col-md-2 d-flex">
              <div class="align-self-center">
                <p class="small text-muted mb-0">
                  <span>{{ timeAgo(organization.last_activity) }}</span>
                </p>
                <p class="small text-muted mb-0">
                  {{ localReadableTime(organization.last_activity) }}
                </p>
              </div>
            </div>
          </div>
        </router-link>
      </div>
      <div class="card-footer bg-light">
        <pagination
          v-if="paginator.current_page"
          :records="paginator.total_items"
          :per-page="10"
          v-model="current_page"
          :options="paginatorOptions"
        ></pagination>
      </div>
    </div>
  </div>

  <ModalOrganizationNew id="organizationNew" />
</template>

<script>
import axios from "axios";
import Pagination from "v-pagination-3";

import Breadcrumbs from "@/components/Breadcrumbs.vue";
import RTPagination from "@/components/RTPagination.vue";

import ModalOrganizationNew from "@/components/modals/ModalOrganizationNew.vue";

import Utils from "@/helpers/utils";

export default {
  name: "AdminOrganizationsPage",
  components: { Breadcrumbs, Pagination, RTPagination, ModalOrganizationNew },
  data() {
    return {
      paginatorOptions: {
        template: RTPagination,
      },
      paginator: {},
      organizations: [],
      totals: {
        all: 0,
        active: 0,
        deleted: 0,
      },
      filter: {
        name: "",
        status: "active",
      },
      sortby: "created_desc",
      current_page: 1,
    };
  },
  mounted() {
    this.emitter.on("organization_created", (data) => {
      let newOrganizationModal = bootstrap.Modal.getInstance(
        document.getElementById("organizationNew")
      );
      newOrganizationModal.hide();

      this.emitter.emit("popup_alert", {
        type: "success",
        text: "Organization " + data + " was successfully created!",
      });

      this.fetch_organizations();
    });

    this.fetch_organizations();
  },
  methods: {
    fetch_organizations() {
      let url =
        process.env.VUE_APP_URL +
        "/admin/organizations?items=10&page=" +
        this.current_page +
        "&status=" +
        this.filter.status +
        "&name=" +
        encodeURIComponent(this.filter.name) +
        "&order=" +
        this.sortby;

      axios
        .get(url, {
          headers: {
            Authorization: process.appdata.tokens.access_token,
          },
        })
        .then((response) => {
          if (200 === response.status) {
            let item;
            this.totals = {
              all: 0,
              active: 0,
              deleted: 0,
            };

            this.totals.active = parseInt(response.data.totals.active);
            this.totals.deleted = parseInt(response.data.totals.deleted);
            this.totals.all = this.totals.active + this.totals.deleted;

            this.organizations = response.data.data;
            this.paginator = response.data.paginator;
          } else {
            process.appdata.logout();
          }
        });
    },
    capitalizeText(text, allwords) {
      return Utils.capitalizeText(text, allwords);
    },
    timeAgo(dateString) {
      return Utils.timeAgo(dateString);
    },
    localReadableTime(dateString) {
      return Utils.localReadableTime(dateString);
    },
    localMachineTime(dateString) {
      return Utils.localMachineTime(dateString);
    },
  },
  watch: {
    "filter.status": function () {
      this.current_page = 1;
      this.fetch_organizations();
    },
    sortby: function () {
      this.current_page = 1;
      this.fetch_organizations();
    },
    current_page: function () {
      this.fetch_organizations();
    },
  },
};
</script>