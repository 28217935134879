<template>
  <Breadcrumbs :breaditems="[{ title: 'Admin' }]" />
  <div id="main" class="container">
    <!-- Stats -->
    <div class="card-group text-center">
      <div class="card">
        <router-link
          class="card-body list-group-item-action"
          to="/admin/organisations"
        >
          <div class="d-flex">
            <div
              class="flex-shrink-0 border border-2 rounded-circle d-flex"
              style="height: 44px; width: 44px"
            >
              <i
                class="
                  bi bi-building
                  fs-4
                  rounded-circle
                  align-self-center
                  mx-auto
                  text-primary
                "
              ></i>
            </div>
            <div class="flex-grow-1 ms-3">
              <div class="float-start text-start">
                <span class="text-black fw-bold mb-0 d-block">{{
                  stats.total_organizations
                }}</span>
                <span
                  class="text-muted d-block text-uppercase"
                  style="font-size: 12px"
                  >Organisations</span
                >
              </div>
            </div>
          </div>
        </router-link>
      </div>
      <div class="card">
        <router-link class="card-body list-group-item-action" to="/sites">
          <div class="d-flex">
            <div
              class="flex-shrink-0 border border-2 rounded-circle d-flex"
              style="height: 44px; width: 44px"
            >
              <i
                class="
                  bi bi-geo
                  fs-4
                  rounded-circle
                  align-self-center
                  mx-auto
                  text-primary
                "
              ></i>
            </div>
            <div class="flex-grow-1 ms-3">
              <div class="float-start text-start">
                <span class="text-black fw-bold mb-0 d-block">{{
                  stats.total_sites
                }}</span>
                <span
                  class="text-muted d-block text-uppercase"
                  style="font-size: 12px"
                  >Sites</span
                >
              </div>
            </div>
          </div>
        </router-link>
      </div>
      <div class="card">
        <router-link
          class="card-body list-group-item-action"
          to="/admin/services"
        >
          <div class="d-flex">
            <div
              class="flex-shrink-0 border border-2 rounded-circle d-flex"
              style="height: 44px; width: 44px"
            >
              <i
                class="
                  bi bi-window-sidebar
                  fs-4
                  rounded-circle
                  align-self-center
                  mx-auto
                  text-primary
                "
              ></i>
            </div>
            <div class="flex-grow-1 ms-3">
              <div class="float-start text-start">
                <span class="text-black fw-bold mb-0 d-block">{{
                  stats.total_services
                }}</span>
                <span
                  class="text-muted d-block text-uppercase"
                  style="font-size: 12px"
                  >Services</span
                >
              </div>
            </div>
          </div>
        </router-link>
      </div>
      <div class="card">
        <router-link class="card-body list-group-item-action" to="/admin/users">
          <div class="d-flex">
            <div
              class="flex-shrink-0 border border-2 rounded-circle d-flex"
              style="height: 44px; width: 44px"
            >
              <i
                class="
                  bi bi-people-fill
                  fs-4
                  rounded-circle
                  align-self-center
                  mx-auto
                  text-primary
                "
              ></i>
            </div>
            <div class="flex-grow-1 ms-3">
              <div class="float-start text-start">
                <span class="text-black fw-bold mb-0 d-block">{{
                  stats.total_users
                }}</span>
                <span
                  class="text-muted d-block text-uppercase"
                  style="font-size: 12px"
                  >Users</span
                >
              </div>
            </div>
          </div>
        </router-link>
      </div>
      <div class="card">
        <router-link class="card-body list-group-item-action" to="/admin/logs">
          <div class="d-flex">
            <div
              class="flex-shrink-0 border border-2 rounded-circle d-flex"
              style="height: 44px; width: 44px"
            >
              <i
                class="
                  bi bi-calendar-week
                  fs-4
                  rounded-circle
                  align-self-center
                  mx-auto
                  text-primary
                "
              ></i>
            </div>
            <div class="flex-grow-1 ms-3">
              <div class="float-start text-start">
                <span class="text-black fw-bold mb-0 d-block">{{
                  stats.total_logs
                }}</span>
                <span
                  class="text-muted d-block text-uppercase"
                  style="font-size: 12px"
                  >Logs Today</span
                >
              </div>
            </div>
          </div>
        </router-link>
      </div>
      <div class="card">
        <router-link
          class="card-body list-group-item-action"
          to="/admin/notifications"
        >
          <div class="d-flex">
            <div
              class="flex-shrink-0 border border-2 rounded-circle d-flex"
              style="height: 44px; width: 44px"
            >
              <i
                class="
                  bi bi-bell-fill
                  fs-4
                  rounded-circle
                  align-self-center
                  mx-auto
                  text-primary
                "
              ></i>
            </div>
            <div class="flex-grow-1 ms-3">
              <div class="float-start text-start">
                <span class="text-black fw-bold mb-0 d-block">{{
                  stats.total_notifications
                }}</span>
                <span
                  class="text-muted d-block text-uppercase"
                  style="font-size: 12px"
                  >Notifications</span
                >
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>

    <!-- Chart -->
    <div class="card mt-2" id="activityStatsControls">
      <div class="card-header bg-light">
        <button
          type="button"
          @click="setChartTypes('member_created')"
          :class="
            'member_created' === chartTypes
              ? 'badge bg-primary'
              : 'badge bg-light text-muted'
          "
        >
          Register
        </button>
        <button
          type="button"
          @click="setChartTypes('login,login_google')"
          :class="
            'login,login_google' === chartTypes
              ? 'badge bg-primary'
              : 'badge bg-light text-muted'
          "
        >
          Login
        </button>
        <button
          type="button"
          @click="setChartTypes('')"
          :class="
            '' === chartTypes ? 'badge bg-primary' : 'badge bg-light text-muted'
          "
        >
          Logs
        </button>
        <span class="float-end">
          <button
            type="button"
            @click="setChartInterval('day')"
            :class="
              'day' === chartInterval
                ? 'badge bg-primary'
                : 'badge bg-light text-muted'
            "
          >
            D
          </button>
          <button
            type="button"
            @click="setChartInterval('week')"
            :class="
              'week' === chartInterval
                ? 'badge bg-primary'
                : 'badge bg-light text-muted'
            "
          >
            W
          </button>
          <button
            type="button"
            @click="setChartInterval('month')"
            :class="
              'month' === chartInterval
                ? 'badge bg-primary'
                : 'badge bg-light text-muted'
            "
          >
            M
          </button>
          <button
            type="button"
            @click="setChartInterval('year')"
            :class="
              'year' === chartInterval
                ? 'badge bg-primary'
                : 'badge bg-light text-muted'
            "
          >
            Y
          </button>
        </span>
      </div>
      <div class="card-body" id="activityStatsContainer">
        <div id="activityStatsPlot"></div>
      </div>
    </div>

    <!-- Last entries -->
    <div class="row row-cols-1 row-cols-md-2 g-2 mt-0 mb-2">
      <div class="col mt-2">
        <div class="card">
          <div class="card-header bg-light small">
            Last 5 registered users
            <router-link
              to="/admin/users"
              class="float-end text-decoration-none"
              >View All</router-link
            >
          </div>
          <div
            v-if="0 === latestStats.latest_registrations.length"
            class="card-body"
          >
            <div class="d-flex justify-content-center">
              <div
                class="spinner-border"
                style="width: 7rem; height: 7rem"
                role="status"
              >
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
          <div v-else class="list-group list-group-flush">
            <router-link
              v-for="registration in latestStats.latest_registrations"
              v-bind:key="registration.id"
              :to="'/admin/users/' + registration.id"
              class="list-group-item list-group-item-action"
            >
              <div class="row">
                <div class="col-8">
                  <div class="d-flex align-items-center">
                    <div class="flex-shrink-0">
                      <img
                        :src="registration.picture || ''"
                        :alt="registration.name"
                        class="rounded-circle align-self-center"
                        width="36"
                        height="36"
                        onerror="this.src='/images/person-circle.svg';"
                      />
                    </div>
                    <div class="flex-grow-1 ms-3">
                      <b class="text-dark">{{ registration.name }}</b>
                      <p class="small text-muted mb-0">
                        <span>{{
                          registration.email ? registration.email : "&nbsp;"
                        }}</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col d-flex text-end">
                  <div class="align-self-center ms-auto">
                    <span class="small text-muted small">{{
                      timeAgo(registration.created_time)
                    }}</span>
                    <p class="small text-muted mb-0">
                      <span>{{
                        localReadableTime(registration.created_time)
                      }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <div class="col mt-2">
        <div class="card">
          <div class="card-header bg-light small">
            Last 5 loggedin users
            <router-link
              to="/admin/users"
              class="float-end text-decoration-none"
              >View All</router-link
            >
          </div>
          <div v-if="0 === latestStats.latest_logins.length" class="card-body">
            <div class="d-flex justify-content-center">
              <div
                class="spinner-border"
                style="width: 7rem; height: 7rem"
                role="status"
              >
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
          <div v-else class="list-group list-group-flush">
            <router-link
              v-for="login in latestStats.latest_logins"
              v-bind:key="login.id"
              :to="'/admin/users/' + login.id"
              class="list-group-item list-group-item-action"
            >
              <div class="row">
                <div class="col-8">
                  <div class="d-flex align-items-center">
                    <div class="flex-shrink-0">
                      <img
                        :src="login.avatar"
                        :alt="login.name"
                        class="rounded-circle align-self-center"
                        width="36"
                        height="36"
                        onerror="this.src='/images/person-circle.svg';"
                      />
                    </div>
                    <div class="flex-grow-1 ms-3">
                      <b class="text-dark">{{ login.name }}</b>
                      <p class="small text-muted mb-0">
                        <span>{{ login.email ? login.email : "&nbsp;" }}</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col d-flex text-end">
                  <div class="align-self-center ms-auto">
                    <span class="small text-muted small">{{
                      timeAgo(login.created_at)
                    }}</span>
                    <p class="small text-muted mb-0">
                      <span>{{ localReadableTime(login.created_at) }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <div class="col mt-2">
        <div class="card">
          <div class="card-header bg-light small">
            Last 5 organisation created
            <router-link
              to="/admin/organisations"
              class="float-end text-decoration-none"
              >View All</router-link
            >
          </div>
          <div
            v-if="0 === latestStats.latest_organizations.length"
            class="card-body"
          >
            <div class="d-flex justify-content-center">
              <div
                class="spinner-border"
                style="width: 7rem; height: 7rem"
                role="status"
              >
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
          <div v-else class="list-group list-group-flush">
            <router-link
              v-for="organization in latestStats.latest_organizations"
              v-bind:key="organization.id"
              :to="'/organisations/' + organization.id"
              class="list-group-item list-group-item-action"
            >
              <div class="row">
                <div class="col-8 d-flex">
                  <div class="d-flex align-items-center">
                    <div class="flex-shrink-0">
                      <img
                        :src="organization.logo || ''"
                        :alt="organization.name"
                        class="align-self-center"
                        width="30"
                        height="30"
                        onerror="this.src='/images/building.svg';"
                      />
                    </div>
                    <div class="flex-grow-1 ms-3">
                      <b class="text-dark">{{ organization.name }}</b>
                      <p class="small text-muted mb-0">
                        <span>{{
                          organization.industry
                            ? organization.industry
                            : "&nbsp;"
                        }}</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col d-flex text-end">
                  <div class="align-self-center ms-auto">
                    <span class="small text-muted small">{{
                      timeAgo(organization.created_time)
                    }}</span>
                    <p class="small text-muted mb-0">
                      <span>{{
                        localReadableTime(organization.created_time)
                      }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <div class="col mt-2">
        <div class="card">
          <div class="card-header bg-light small">
            Last 5 sites created
            <router-link to="/sites" class="float-end text-decoration-none"
              >View All</router-link
            >
          </div>
          <div v-if="0 === latestStats.latest_sites.length" class="card-body">
            <div class="d-flex justify-content-center">
              <div
                class="spinner-border"
                style="width: 7rem; height: 7rem"
                role="status"
              >
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
          <div v-else class="list-group list-group-flush">
            <router-link
              v-for="site in latestStats.latest_sites"
              v-bind:key="site.id"
              :to="'/sites/' + site.id"
              class="list-group-item list-group-item-action"
            >
              <div class="row">
                <div class="col-8 d-flex">
                  <div class="d-flex align-items-center">
                    <div class="flex-shrink-0">
                      <i class="bi bi-geo-alt-fill fs-3"></i>
                    </div>
                    <div class="flex-grow-1 ms-3">
                      <b class="text-dark">{{ site.organization_name }}</b>
                      <p class="small text-muted mb-0">
                        <span>{{
                          site.organization_industry
                            ? site.organization_industry
                            : "&nbsp;"
                        }}</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col d-flex text-end">
                  <div class="align-self-center ms-auto">
                    <span class="small text-muted small">{{
                      timeAgo(site.created_time)
                    }}</span>
                    <p class="small text-muted mb-0">
                      <span>{{ localReadableTime(site.created_time) }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <!-- Last logs -->
    <div class="card mb-2">
      <div class="card-header bg-light small">
        Last 5 Logs
        <router-link to="/admin/logs" class="float-end text-decoration-none"
          >View All</router-link
        >
      </div>
      <div v-if="0 === latestStats.latest_logs.length" class="card-body">
        <div class="d-flex justify-content-center">
          <div
            class="spinner-border"
            style="width: 7rem; height: 7rem"
            role="status"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
      <div v-else class="list-group list-group-flush activiy-log">
        <router-link
          v-for="log in latestStats.latest_logs"
          v-bind:key="log.id"
          :to="'/admin/logs/' + log.id"
          class="list-group-item list-group-item-action"
        >
          <div class="d-flex align-items-center">
            <div class="flex-shrink-0">
              <img
                :src="log.causer_picture"
                :alt="log.causer_name"
                class="rounded-circle align-self-center"
                width="36"
                height="36"
                onerror="this.src='/images/person-circle.svg';"
              />
            </div>
            <div class="flex-grow-1 ms-3">
              <span class="text-dark">{{ log.causer_name }}</span>
              <p
                class="small text-muted mb-0 fw-normal"
                v-html="log.plaintext ? log.plaintext : '&nbsp;'"
              ></p>
            </div>
            <span
              class="text-muted float-end small"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title=""
              :data-bs-original-title="log.created_at"
              >{{ timeAgo(log.created_at) }}</span
            >
            <div class="float-end ms-3">
              <i class="bi bi-chevron-right align-self-center"></i>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Breadcrumbs from "@/components/Breadcrumbs.vue";

import Utils from "@/helpers/utils";

export default {
  name: "AdminPage",
  components: { Breadcrumbs },
  data() {
    return {
      stats: {
        total_users: 0,
        total_organizations: 0,
        total_sites: 0,
        total_services: 0,
        total_logs: 0,
        total_notifications: 0,
      },
      chart: null,
      chartTypes: "login,login_google",
      chartInterval: "day",
      latestStats: {
        latest_logins: [],
        latest_registrations: [],
        latest_organizations: [],
        latest_sites: [],
        latest_logs: [],
      },
    };
  },
  mounted() {
    this.fetch_stats();
    this.fetch_latest_stats();
    this.fetch_activities_stats();
  },
  methods: {
    fetch_stats() {
      axios
        .get(process.env.VUE_APP_URL + "/admin/stats", {
          headers: {
            Authorization: process.appdata.tokens.access_token,
          },
        })
        .then((response) => {
          if (200 === response.status) {
            this.stats = response.data;
          } else {
            process.appdata.logout();
          }
        });
    },

    fetch_latest_stats() {
      axios
        .get(process.env.VUE_APP_URL + "/admin/latest_stats", {
          headers: {
            Authorization: process.appdata.tokens.access_token,
          },
        })
        .then((response) => {
          if (200 === response.status) {
            this.latestStats = response.data;
          } else {
            process.appdata.logout();
          }
        });
    },

    fetch_activities_stats() {
      let url =
        process.env.VUE_APP_LOGS_ENDPOINT +
        "/activities_stats?interval=" +
        this.chartInterval +
        "&filter[types]=" +
        this.chartTypes;

      axios
        .get(url, {
          headers: {
            Authorization: process.env.VUE_APP_LOGS_SECRET,
          },
        })
        .then((response) => {
          if (200 === response.status) {
            var chart = null;

            try {
              chart.dispose();
            } catch (err) {}

            let xLabels = [],
              xData = [],
              pointValue = 0;
            for (let item in response.data) {
              xLabels.push(item);

              switch (this.chartTypes) {
                case "member_created":
                  pointValue = response.data[item].member_created || 0;

                  xData.push(pointValue);
                  break;
                case "login,login_google":
                  pointValue =
                    (response.data[item].login || 0) +
                    (response.data[item].login_google || 0);

                  xData.push(pointValue);
                  break;
                case "":
                  pointValue = 0;

                  for (let logType in response.data[item]) {
                    pointValue += response.data[item][logType] || 0;
                  }

                  xData.push(pointValue);
                  break;
              }
            }

            /* Dirty fix that works */
            jQuery("#activityStatsPlot").removeAttr("_echarts_instance_");

            chart = echarts.init(document.getElementById("activityStatsPlot"));

            var options = {
              toolbox: {
                show: true,
                feature: {
                  dataZoom: {
                    yAxisIndex: "none",
                  },
                  magicType: { type: ["line", "bar"] },
                  restore: {},
                  saveAsImage: {},
                },
                top: "5%",
              },
              grid: [{ left: "3%", top: "3%", width: "96%", height: "90%" }],
              tooltip: {
                trigger: "axis",
              },
              xAxis: {
                name: "Interval",
                data: xLabels,
              },
              yAxis: {
                name: "Count",
                type: "value",
              },
              series: {
                type: "line",
                showSymbol: false,
                data: xData,
              },
            };

            options && chart.setOption(options);
          } else {
            process.appdata.logout();
          }
        });
    },

    setChartTypes(types) {
      this.chartTypes = types;
      this.fetch_activities_stats();
    },

    setChartInterval(interval) {
      this.chartInterval = interval;
      this.fetch_activities_stats();
    },

    timeAgo(dateString) {
      return Utils.timeAgo(dateString);
    },

    localReadableTime(dateString) {
      return Utils.localReadableTime(dateString);
    },
    localMachineTime(dateString) {
      return Utils.localMachineTime(dateString);
    },
  },
};
</script>