<template>
  <div class="modal fade" tabindex="-1" :id="id">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Delete Role</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="alert alert-danger text-center">
                Are you sure you to delete <b>{{ role_name }}</b
                >?
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <button class="btn btn-secondary w-100" data-bs-dismiss="modal">
                Cancel
              </button>
            </div>
            <div class="col-md-6">
              <div class="btn btn-danger w-100" @click="delete_service_role()">
                Confirm
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ModalServiceDeleteRole",
  props: ["id"],
  data() {
    return {
      role_id: "",
      role_name: ""
    };
  },
  mounted() {
    this.emitter.on("remove_role", (role) => {
      this.role_id = role.id;
      this.role_name = role.name;
    });
  },
  methods: {
    delete_service_role() {
      axios
        .delete(
          process.env.VUE_APP_URL +
            "/services/" +
            this.$route.params.service_id +
            "/roles/" +
            this.role_id,
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then(
          (response) => {
            if (200 === response.status) {
              this.emitter.emit("service_role_deleted", null);
            } else {
            }
          },
          (error) => {
            console.log(error);
          }
        );
    },
  },
};
</script>