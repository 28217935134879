<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-white border-bottom">
    <div class="container">
      <a class="navbar-brand fw-bold text-primary" href="/"
        ><img
          src="https://account.dev.remote-tech.co.uk/img/logo.8444cd33.svg"
          style="height: 27px"
      /></a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarMain"
        aria-controls="navbarMain"
        :aria-expanded="expanded"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarMain">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link class="nav-link" aria-current="page" to="/"
              >Dashboard</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link"
              aria-current="page"
              to="/organisations"
              >Organisations</router-link
            >
          </li>
        </ul>
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <li v-if="isTester" class="nav-item">
            <button
              type="button"
              class="btn btn-danger"
              @click="delete_tester_data"
            >
              Delete tester data
            </button>
          </li>
          <li v-if="isAdmin" class="nav-item">
            <router-link class="nav-link" aria-current="page" to="/admin"
              ><i class="bi bi-person-bounding-box"></i>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link"
              aria-current="page"
              to="/notifications"
            >
              <span class="position-relative">
                <i class="bi bi-bell-fill"></i>
                <span
                  v-if="0 < notifications.total_unread"
                  class="
                    position-absolute
                    top-0
                    start-100
                    translate-middle
                    badge
                    rounded-pill
                    bg-danger
                  "
                  style="font-size: 0.5rem"
                  >{{ notifications.total_unread }}</span
                >
              </span>
            </router-link>
          </li>
          <li class="nav-item dropdown">
            <router-link class="nav-link" aria-current="page" to="/account">
              {{ currentUser.first_name + " " + currentUser.last_name }}
            </router-link>
          </li>
          <li class="nav-item">
            <span
              class="nav-link"
              aria-current="page"
              @click="logout"
              style="cursor: pointer"
              ><i class="bi bi-power"></i
            ></span>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import axios from "axios";

export default {
  name: "Navbar",
  data() {
    return {
      expanded: false,
      isAdmin:
        "admin" === process.appdata.user.type ||
        (process.appdata.user.rootOrganization &&
          process.env.VUE_APP_RT_NUMBER ===
            process.appdata.user.rootOrganization.company_number)
          ? true
          : false,
      isTester: "tester" === process.appdata.user.type ? true : false,
      currentUser: process.appdata.user,
      notifications: {
        total: 0,
        total_read: 0,
        total_unread: 0,
      },
    };
  },
  mounted() {
    this.fetch_notifications_stats();
  },
  methods: {
    logout() {
      process.appdata.logout();
    },
    fetch_notifications_stats() {
      axios
        .get(
          process.env.VUE_APP_NOTIFICATIONS_ENDPOINT + "/notifications/stats",
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then((response) => {
          this.notifications = response.data;
        });
    },

    delete_tester_data() {
      axios
        .get(process.env.VUE_APP_URL + "/delete_tester_data", {
          headers: {
            Authorization: process.appdata.tokens.access_token,
          },
        })
        .then((response) => {
          this.emitter.emit("popup_alert", {
            type: "success",
            text: "Tester data was deleted!",
          });
        });
    },
  },
};
</script>
