<template>
  <div class="modal fade" tabindex="-1" :id="id">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Ban User</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="alert alert-danger text-center">
                Are you sure you to ban <b>{{ full_name }}</b
                >?
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <button class="btn btn-secondary w-100" data-bs-dismiss="modal">
                Cancel
              </button>
            </div>
            <div class="col-md-6">
              <div class="btn btn-danger w-100" @click="ban_user">Confirm</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ModalUserBan",
  props: ["id"],
  data() {
    return {
      user_id: null,
      full_name: "",
    };
  },
  mounted() {
    this.emitter.on("user_details_loaded", (data) => {
      this.user_id = data.id ? data.id : null;
      this.full_name = data.full_name ? data.full_name : "";
    });
  },
  methods: {
    ban_user() {
      axios
        .patch(
          process.env.VUE_APP_URL +
            "/admin/users/" +
            this.$route.params.user_id +
            "/change-status",
          {
            status: "banned",
          },
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then(
          (response) => {
            if (200 === response.status) {
              this.emitter.emit("user_banned", this.user);
            } else {
            }
          },
          (error) => {
            console.log(error);
          }
        );
    },
  },
};
</script>