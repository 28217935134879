<template>
  <div class="d-flex bd-highlight">
    <div class="flex-grow-1 me-2">
      <div class="input-group">
        <button
          v-on:click="fetch_organization_collaborators()"
          type="button"
          class="input-group-text bg-white"
        >
          <i class="bi bi-search"></i>
        </button>
        <input
          type="text"
          class="form-control"
          placeholder="Search..."
          v-model="filter.name"
          v-on:keyup.enter="fetch_organization_collaborators()"
        />
      </div>
    </div>
    <div class="bd-highlight me-2">
      <select class="form-select" v-model="filter.role">
        <option value="">{{ "All Users (" + totals.all + ")" }}</option>
        <option value="owner">{{ "Owners (" + totals.owner + ")" }}</option>
        <option value="member">{{ "Members (" + totals.member + ")" }}</option>
        <option value="limited">
          {{ "Limited Members (" + totals.limited + ")" }}
        </option>
      </select>
    </div>
    <div class="bd-highlight">
      <button
        type="button"
        class="btn border bg-white float-end text-decoration-none"
        data-bs-toggle="modal"
        data-bs-target="#organizationInvitation"
      >
        New Invite
      </button>
    </div>
  </div>
  <div class="card mt-2">
    <div class="card-header bg-light">
      <i class="bi bi-people-fill me-2"></i>
      Collaborators
    </div>

    <div v-if="0 === collaborators.length" class="card-body">
      <div class="text-center">
        <img src="/images/empty-users.svg" class="img-fluid py-5" />
      </div>
    </div>

    <div v-else class="list-group list-group-flush">
      <div
        class="list-group-item"
        v-for="collaborator in collaborators"
        v-bind:key="collaborator.id"
      >
        <div class="row">
          <div class="col">
            <router-link
              class="d-flex align-items-center text-decoration-none"
              :to="'/admin/users/' + collaborator.user.id"
            >
              <div class="flex-shrink-0">
                <img
                  :src="collaborator.user.picture"
                  :alt="
                    collaborator.user.first_name +
                    ' ' +
                    collaborator.user.last_name
                  "
                  class="rounded-circle align-self-center"
                  width="36"
                  height="36"
                  onerror="this.src='/images/person-circle.svg';"
                />
              </div>
              <div class="flex-grow-1 ms-3">
                <b class="text-dark">{{
                  collaborator.user.first_name +
                  " " +
                  collaborator.user.last_name
                }}</b>
                <p class="small text-muted mb-0">
                  <span>{{ collaborator.user.email || "&nbsp;" }}</span>
                </p>
              </div>
            </router-link>
          </div>
          <div class="col-1 d-flex">
            <div class="align-self-center">
              <span
                class="badge bg-light text-dark border w-100 d-block mb-1"
                >{{ capitalizeText(collaborator.role) }}</span
              >
              <span class="badge bg-light text-dark border w-100 d-block"
                >All Sites</span
              >
            </div>
          </div>
          <div class="col-3 d-flex">
            <div class="align-self-center">
              <p class="mb-0">
                <span class="badge bg-primary">{{
                  collaborator.rootOrganization.name
                }}</span>
              </p>
              <p class="mb-0">
                <span
                  class="badge bg-secondary"
                  v-if="collaborator.user.job_title"
                  >{{ collaborator.user.job_title }}</span
                >
                <span class="badge bg-white" v-else>&nbsp;</span>
              </p>
            </div>
          </div>
          <div class="col-3 d-flex pe-0">
            <div class="d-block text-end me-3 ms-auto">
              <p class="small text-muted mb-0"><span>Last Active</span></p>
              <b class="text-dark text-muted small">{{
                timeAgo(collaborator.created_time)
              }}</b>
            </div>
            <div class="align-self-center mx-3">
              <div class="dropstart">
                <button
                  type="button"
                  class="btn btn-link"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i class="bi bi-gear-fill mr-1"></i>
                </button>
                <div class="dropdown-menu" style="width: 250px">
                  <h6 class="dropdown-header">collaborator Options</h6>
                  <a
                    href="#"
                    class="btn dropdown-item"
                    @click="
                      openManageModal(
                        collaborator.id,
                        collaborator.role,
                        collaborator.user
                      )
                    "
                    >Manage <i class="bi bi-gear-fill float-end"></i
                  ></a>

                  <router-link
                    :to="'/admin/users/' + collaborator.user.id"
                    class="btn dropdown-item"
                    >View user in admin panel
                    <i class="bi bi-person-fill float-end"></i
                  ></router-link>

                  <div class="dropdown-divider"></div>
                  <a
                    href="#"
                    class="btn dropdown-item text-danger"
                    @click="openRemoveModal(collaborator.id, collaborator.user)"
                    >Remove from organization
                    <i class="bi bi-person-dash-fill float-end"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer bg-light">
      <pagination
        v-if="paginator.current_page"
        :records="paginator.total_items"
        :per-page="10"
        v-model="current_page"
        :options="paginatorOptions"
      ></pagination>
    </div>
  </div>

  <ModalOrganizationCollaborator id="organizationCollaborator" />
  <ModalOrganizationInvitation membership="collaborator" id="organizationInvitation" />
  <ModalOrganizationUserRemove v-if="isAdmin" id="organizationUserRemove" />
</template>

<script>
import axios from "axios";

import Pagination from "v-pagination-3";
import RTPagination from "@/components/RTPagination.vue";
import ModalOrganizationCollaborator from "@/components/modals/ModalOrganizationCollaborator.vue";
import ModalOrganizationInvitation from "@/components/modals/ModalOrganizationInvitation.vue";
import ModalOrganizationUserRemove from "@/components/modals/ModalOrganizationUserRemove.vue";

import Utils from "@/helpers/utils";

export default {
  name: "OrganizationCollaborators",
  components: {
    Pagination,
    RTPagination,
    ModalOrganizationCollaborator,
    ModalOrganizationInvitation,
    ModalOrganizationUserRemove,
  },
  data() {
    return {
      isAdmin:
        "admin" === process.appdata.user.type ||
        (process.appdata.user.rootOrganization &&
          process.env.VUE_APP_RT_NUMBER ===
            process.appdata.user.rootOrganization.company_number)
          ? true
          : false,
      paginatorOptions: {
        template: RTPagination,
      },
      paginator: {},
      totals: {
        all: 0,
        owner: 0,
        member: 0,
        limited: 0,
      },
      collaborators: [],
      filter: {
        name: "",
        role: "",
      },
      filter: {
        name: "",
        role: "",
      },
      current_page: 1,
    };
  },
  mounted() {
    this.emitter.on("member_updated", () => {
      let organizationMemberModal = bootstrap.Modal.getInstance(
        document.getElementById("organizationMember")
      );
      organizationMemberModal.hide();

      this.emitter.emit("popup_alert", {
        type: "success",
        text: "Collaborator was succesfuly updated!",
      });

      this.fetch_organization_collaborators();
    });

    this.emitter.on("organization_member_removed", () => {
      let organizationUserRemoveModal = bootstrap.Modal.getInstance(
        document.getElementById("organizationUserRemove")
      );
      organizationUserRemoveModal.hide();

      this.emitter.emit("popup_alert", {
        type: "success",
        text: "Collaborator was succesfuly removed!",
      });

      this.fetch_organization_collaborators();
    });

    this.emitter.on("organization_member_invited", (email) => {
      let organizationInvitationModal = bootstrap.Modal.getInstance(
        document.getElementById("organizationInvitation")
      );
      organizationInvitationModal.hide();

      this.emitter.emit("popup_alert", {
        type: "success",
        text: "User " + email + " was succesfuly invited to the organization!",
      });

      this.fetch_organization_collaborators();
    });

    this.fetch_organization_collaborators();
  },
  methods: {
    fetch_organization_collaborators() {
      axios
        .get(
          process.env.VUE_APP_URL +
            "/organizations/" +
            this.$route.params.organization_id +
            "/collaborators?items=10&page=" +
            this.current_page +
            "&role=" +
            this.filter.role +
            "&name=" +
            encodeURIComponent(this.filter.name),
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then((response) => {
          if (200 === response.status) {
            this.collaborators = response.data.data;
            this.paginator = response.data.paginator;

            this.totals = response.data.totals;
          } else {
            process.appdata.logout();
          }
        });
    },

    openManageModal(collaborator_id, role, user) {
      this.emitter.emit("manage_collaborator", {
        id: collaborator_id,
        role: role,
        user: user,
      });

      var updateOrganizationcollaborator = new bootstrap.Modal(
        document.getElementById("organizationCollaborator"),
        null
      );
      updateOrganizationcollaborator.show();
    },

    openRemoveModal(collaborator_id, user) {
      this.emitter.emit("remove_member", {
        id: collaborator_id,
        user: user,
      });

      var organizationUserRemoveModal = new bootstrap.Modal(
        document.getElementById("organizationUserRemove"),
        null
      );
      organizationUserRemoveModal.show();
    },

    capitalizeText(text, allwords) {
      return Utils.capitalizeText(text, allwords);
    },
    timeAgo(dateString) {
      return Utils.timeAgo(dateString);
    },
  },
  watch: {
    "filter.role": function () {
      this.current_page = 1;
      this.fetch_organization_collaborators();
    },
    current_page: function () {
      this.fetch_organization_collaborators();
    },
  },
};
</script>
