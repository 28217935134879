<template>
  <div class="modal fade" tabindex="-1" :id="id">
    <div class="modal-dialog modal-sm" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Manage Request to Join</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body" v-if="member.user">
          <div class="row">
            <div class="col-12 mb-3">
              <label for="full_name" class="form-label">Member</label>
              <input
                type="text"
                :value="member.user.email"
                class="form-control"
                id="full_name"
                disabled
              />
            </div>
          </div>

          <div class="row">
            <div class="col-12 mb-3">
              <label class="form-label">Organization Role</label>

              <div
                class="btn-group w-100"
                role="group"
                aria-label="Basic radio toggle button group"
              >
                <input
                  v-model="member.role"
                  type="radio"
                  class="btn-check"
                  id="radioLimitedRequest"
                  value="limited"
                  autocomplete="off"
                />
                <label class="btn btn-outline-primary" for="radioLimitedRequest"
                  >Limited</label
                >

                <input
                  v-model="member.role"
                  type="radio"
                  class="btn-check"
                  id="radioMemberRequest"
                  value="member"
                  autocomplete="off"
                />
                <label class="btn btn-outline-primary" for="radioMemberRequest"
                  >Member</label
                >

                <input
                  v-model="member.role"
                  type="radio"
                  class="btn-check"
                  id="radioOwnerRequest"
                  value="owner"
                  autocomplete="off"
                />
                <label class="btn btn-outline-primary" for="radioOwnerRequest"
                  >Owner</label
                >
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12" v-if="member.role == 'limited'">
              <label class="form-label">Sites</label>
              <TreeView
                id="treeViewSitesRequest"
                v-bind:key="treeViewSites"
                :treeData="sites"
                :values="selected_sites"
                :onUpdate="refreshMembership"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <label class="form-label">Services</label>
              <TreeView
                id="treeViewServicesRequest"
                v-bind:key="treeViewServices"
                :treeData="services"
                :values="selected_services"
                :onUpdate="refreshMembership"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <button
                type="button"
                @click="accept_request()"
                class="btn btn-primary w-100"
              >
                Accept
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import axios from "axios";

import TreeView from "@/components/TreeView.vue";

export default {
  name: "ModalOrganizationRequest",
  props: ["id"],
  components: { TreeView },
  data() {
    return {
      request_id: null,
      member: { role: "", user: {}, sites: [], services: [] },
      sites: [],
      selected_sites: [],
      services: [],
      selected_services: [],
    };
  },
  mounted() {
    this.emitter.on("manage_request", (data) => {
      this.request_id = data.request_id;
      this.member.user = data.user;

      this.selected_sites = [];
      this.selected_services = [];

      this.fetch_sites();
      this.fetch_services();
    });
  },
  methods: {
    fetch_sites() {
      axios
        .get(
          process.env.VUE_APP_URL +
            "/organizations/" +
            this.$route.params.organization_id +
            "/sites?items=500&page=1",
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then((response) => {
          this.sites = response.data.data;
        });
    },
    fetch_services() {
      axios
        .get(
          process.env.VUE_APP_URL +
            "/organizations/" +
            this.$route.params.organization_id +
            "/services?items=500&page=1",
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then((response) => {
          this.services = response.data.data.map((item) => {
            item.children = item.roles;

            return item;
          });
        });
    },
    refreshMembership(data) {
      if ("treeViewServices" === data.id) {
        this.selected_services = data.values;
      }

      if ("treeViewSites" === data.id) {
        this.selected_sites = data.values;
      }
    },
    accept_request() {
      let data = {
        id: this.request_id,
        role: this.member.role,
        type: "collaborator",
        services: this.selected_services,
      };

      if ("limited" === this.member.role) {
        data.sites = this.selected_sites;
      }

      axios
        .post(
          process.env.VUE_APP_URL +
            "/organizations/" +
            this.$route.params.organization_id +
            "/membership/accept",
          data,
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then((response) => {
          if (200 === response.status) {
            this.emitter.emit("membership_approved", this.member.user);
          } else {
            process.appdata.logout();
          }
        });
    },
  },
  watch: {
    role() {
      if (this.role == "limited") {
        this.fetch_sites(this.current_page);
      }
    },
  },
};
</script>
  