<template>
  <div class="card">
    <div class="card-header bg-light d-flex">
      <i class="bi bi-app-indicator me-2"></i>
      Overview
      <span class="ms-auto text-muted small">Active</span>
    </div>
    <div class="card-body">
      <ul class="list-group">
        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-person-circle me-3"></i>Profile Photo:
          </div>
          <div class="w-75 py-2 px-3">
            <img
              :src="me.picture"
              :alt="me.full_name"
              class="rounded-circle align-self-center"
              width="21"
              height="21"
              onerror="this.src='/images/person-circle.svg';"
            /><button
              type="button"
              class="btn btn-link p-0 float-end text-decoration-none"
              data-bs-toggle="modal"
              data-bs-target="#userAvatar"
            >
              Update
            </button>
          </div>
        </li>
        <li class="list-group-item d-flex p-0 border-0 mt-1">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-person-badge me-3"></i>First Name:
          </div>
          <div class="w-75 py-2 px-3">
            {{ me.first_name }}
            <button
              type="button"
              class="btn btn-link p-0 float-end text-decoration-none"
              data-bs-toggle="modal"
              data-bs-target="#userDetails"
            >
              Update
            </button>
          </div>
        </li>
        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-person-badge me-3"></i>Last Name:
          </div>
          <div class="w-75 py-2 px-3">{{ me.last_name }}</div>
        </li>
        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-person-badge-fill me-3"></i>Job Title:
          </div>
          <div class="w-75 py-2 px-3">{{ me.job_title }}</div>
        </li>
        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-telephone me-3"></i>Phone Number:
          </div>
          <div class="w-75 py-2 px-3">{{ me.phone_number }}</div>
        </li>

        <li class="list-group-item d-flex p-0 border-0 mt-1">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-envelope me-3"></i>Email:
          </div>
          <div class="w-75 py-2 px-3">
            {{ me.email }}
            <button
              type="button"
              class="btn btn-link p-0 float-end text-decoration-none"
              data-bs-toggle="modal"
              data-bs-target="#userEmail"
              v-if="parseInt(me.google_id) > 0 && !me.privacy.email"
            >
              Update
            </button>
          </div>
        </li>

        <li class="list-group-item d-flex p-0 border-0 mt-1">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-shield-lock me-3"></i>Password:
          </div>
          <div class="w-75 py-2 px-3">
            ***********
            <button
              type="button"
              class="btn btn-link p-0 float-end text-decoration-none"
              data-bs-toggle="modal"
              data-bs-target="#userPassword"
            >
              Update
            </button>
          </div>
        </li>

        <li class="list-group-item d-flex p-0 border-0 mt-1">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-google me-3"></i>Google Sign-in:
          </div>
          <div class="w-75 py-2 px-3">
            {{ me.google_id }}
            <button
              type="button"
              class="btn btn-link p-0 float-end text-decoration-none"
              data-bs-toggle="modal"
              data-bs-target="#unlinkGoogle"
            >
              Unlink
            </button>
          </div>
        </li>

        <li class="list-group-item d-flex p-0 border-0 mt-1">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-globe me-3"></i>Timezone:
          </div>
          <div class="w-75 py-2 px-3">
            {{ readableTimezone(me.timezone) }}
            <button
              type="button"
              class="btn btn-link p-0 float-end text-decoration-none"
              data-bs-toggle="modal"
              data-bs-target="#userTimezone"
            >
              Update
            </button>
          </div>
        </li>

        <li class="list-group-item d-flex p-0 border-0 mt-1">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-shield-shaded me-3"></i>Email Privacy:
          </div>
          <div class="w-75 py-2 px-3">
            {{ me.privacy.email ? "Yes" : "No" }}
            <div
              class="
                float-end
                form-check form-switch form-control-lg
                p-0
                m-0
                d-flex
              "
            >
              <input
                class="form-check-input align-self-center m-0"
                type="checkbox"
                id="flexSwitchCheckDefault"
                v-model="me.privacy.email"
                @change="update_privacy"
              />
            </div>
          </div>
        </li>
        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-shield-shaded me-3"></i>Phone Privacy:
          </div>
          <div class="w-75 py-2 px-3">
            {{ me.privacy.phone ? "Yes" : "No" }}
            <div
              class="
                float-end
                form-check form-switch form-control-lg
                p-0
                m-0
                d-flex
              "
            >
              <input
                class="form-check-input align-self-center m-0"
                type="checkbox"
                id="flexSwitchCheckDefault"
                v-model="me.privacy.phone"
                @change="update_privacy"
              />
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div v-if="me.rootOrganization" class="card mt-2">
    <div class="card-header bg-light">
      <i class="bi bi-building me-2"></i>
      Organisation
    </div>
    <div class="card-body">
      <ul class="list-group">
        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-building me-3"></i>Name:
          </div>
          <div class="w-75 py-2 px-3">
            <router-link
              :to="'/admin/organisations/' + me.rootOrganization.id"
              class="text-decoration-none"
              >{{ me.rootOrganization.name }}</router-link
            >
            <button
              class="btn btn-link float-end text-danger"
              @click="openRemoveModal()"
            >
              Leave
            </button>
          </div>
        </li>
        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-person-square me-3"></i>Role:
          </div>
          <div class="w-75 py-2 px-3">
            <span
              v-if="me.role"
              class="badge bg-light text-dark border align-self-center me-1"
              >{{ capitalizeText(me.role) }}</span
            >
            <span class="badge bg-light text-dark border align-self-center"
              >{{ me.sites }} Sites</span
            >
          </div>
        </li>
      </ul>
    </div>
  </div>

  <ModalMyAvatar id="userAvatar" />
  <ModalMyDetails id="userDetails" />
  <ModalMyPassword id="userPassword" />
  <ModalUserEmail id="userEmail" />
  <ModalMyTimezone id="userTimezone" />
  <ModalMeUnlinkGoogle id="unlinkGoogle" />
  <ModalMeRemove id="meRemove" />
</template>

<script>
import axios from "axios";

import ModalMyAvatar from "@/components/modals/ModalMyAvatar.vue";
import ModalMyDetails from "@/components/modals/ModalMyDetails.vue";
import ModalMyPassword from "@/components/modals/ModalMePassword.vue";
import ModalUserEmail from "@/components/modals/ModalUserEmail.vue";
import ModalMyTimezone from "@/components/modals/ModalMyTimezone.vue";
import ModalMeUnlinkGoogle from "@/components/modals/ModalMeUnlinkGoogle.vue";
import ModalMeRemove from "@/components/modals/ModalMeRemove.vue";

import Storage from "@/helpers/storage";
import Utils from "@/helpers/utils";

export default {
  name: "MyAccountOverview",
  components: {
    ModalMyAvatar,
    ModalMyDetails,
    ModalMyPassword,
    ModalUserEmail,
    ModalMyTimezone,
    ModalMeUnlinkGoogle,
    ModalMeRemove,
  },
  data() {
    return {
      me: {
        full_name: "",
        rootOrganization: {},
        privacy: {
          email: false,
          phone: false,
        },
      },
      user_agent: {
        device: {},
        browser: {},
        os: {},
        ua: "",
        device_type: "",
      },
    };
  },
  mounted() {
    this.emitter.on("user_avatar_updated", () => {
      let userAvatarModal = bootstrap.Modal.getInstance(
        document.getElementById("userAvatar")
      );
      userAvatarModal.hide();

      this.emitter.emit("popup_alert", {
        type: "success",
        text: "Your avatar was succesfuly updated!",
      });

      this.fetch_me();
    });

    this.emitter.on("user_details_updated", () => {
      let userDetailsModal = bootstrap.Modal.getInstance(
        document.getElementById("userDetails")
      );
      userDetailsModal.hide();

      this.emitter.emit("popup_alert", {
        type: "success",
        text: "Your details were succesfuly updated!",
      });

      this.fetch_me();
    });

    this.emitter.on("user_password_updated", () => {
      let userPasswordModal = bootstrap.Modal.getInstance(
        document.getElementById("userPassword")
      );
      userPasswordModal.hide();

      this.emitter.emit("popup_alert", {
        type: "success",
        text: "User password was succesfuly changed!",
      });

      this.fetch_me();
    });

    this.emitter.on("user_email_updated", () => {
      let userEmailModal = bootstrap.Modal.getInstance(
        document.getElementById("userEmail")
      );
      userEmailModal.hide();

      this.emitter.emit("popup_alert", {
        type: "success",
        text: "User email was succesfuly changed!",
      });

      this.fetch_me();
    });

    this.emitter.on("user_timezone_updated", (data) => {
      let userTimezonesModal = bootstrap.Modal.getInstance(
        document.getElementById("userTimezone")
      );
      userTimezonesModal.hide();

      let user = process.appdata.user;
      user.timezone = data.timezone;
      Storage.set("user", JSON.stringify(user));
      process.appdata.user = Storage.get("user", true);

      this.emitter.emit("popup_alert", {
        type: "success",
        text: "Your timezone was succesfuly changed!",
      });

      this.fetch_me();
    });

    this.fetch_me();
  },
  methods: {
    fetch_me() {
      axios
        .get(process.env.VUE_APP_URL + "/me", {
          headers: {
            Authorization: process.appdata.tokens.access_token,
          },
        })
        .then((response) => {
          if (200 === response.status) {
            this.me = response.data;

            this.emitter.emit("user_details_loaded", this.me);
          } else {
            process.appdata.logout();
          }
        });
    },
    update_privacy() {
      axios
        .patch(
          process.env.VUE_APP_URL + "/me/privacy",
          {
            privacy: {
              email: this.me.privacy.email,
              phone: this.me.privacy.phone,
            },
          },
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then(
          (response) => {
            if (200 === response.status) {
              this.emitter.emit("popup_alert", {
                type: "success",
                text: "Your privacy settings were updated",
              });

              this.fetch_me();
            } else {
            }
          },
          (error) => {
            console.log(error);
          }
        );
    },
    changeUserStatus(status) {
      axios
        .patch(
          process.env.VUE_APP_URL +
            "/admin/users/" +
            this.$route.params.user_id +
            "/change-status",
          {
            status: status,
          },
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then(
          (response) => {
            if (200 === response.status) {
              this.fetch_me();

              this.emitter.emit("user_status_updated", this.user);
            } else {
            }
          },
          (error) => {
            console.log(error);
          }
        );
    },

    openRemoveModal(member_id, user) {
      this.emitter.emit("remove_me", {
        id: member_id,
        user: user,
      });

      var meRemoveModal = new bootstrap.Modal(
        document.getElementById("meRemove"),
        null
      );
      meRemoveModal.show();
    },

    capitalizeText(text, allwords) {
      return Utils.capitalizeText(text, allwords);
    },
    readableTimezone(timezoneCode) {
      return Utils.readableTimezone(timezoneCode);
    },
    timeAgo(dateString) {
      return Utils.timeAgo(dateString);
    },
  },
};
</script>
