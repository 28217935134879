<template>
  <Breadcrumbs :breaditems="[
    { title: 'Notifications', link: '/notifications' },
    { title: 'Notification #' + notification.id },
  ]" />
  <div class="container">
    <div class="card mb-2">
      <div class="card-header bg-light">
        <i class="bi bi-bell-fill me-2"></i>
        Notification #{{ notification.id }}
      </div>

      <div v-if="null === notification.service_id" class="card-body">
        <div class="d-flex justify-content-center">
          <div class="spinner-border" style="width: 7rem; height: 7rem" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
      <div v-else class="card-body">
        <ul class="list-group">
          <li class="list-group-item d-flex p-0 border-0 mt-1">
            <div class="w-25 py-2 px-3 bg-light">
              <i class="bi bi-hash me-3"></i>ID:
            </div>
            <div class="w-75 py-2 px-3">{{ notification.id }}</div>
          </li>

          <li class="list-group-item d-flex p-0 border-0 mt-1">
            <div class="w-25 py-2 px-3 bg-light">
              <i class="bi bi-window-sidebar me-3"></i>Service:
            </div>
            <div class="w-75 py-2 px-3">
              <router-link :to="'/admin/services/' + notification.service_id">{{ notification.service_label }}
              </router-link>
            </div>
          </li>

          <li class="list-group-item d-flex p-0 border-0 mt-1">
            <div class="w-25 py-2 px-3 bg-light">
              <i class="bi bi-disc me-3"></i>Event Type:
            </div>
            <div class="w-75 py-2 px-3">{{ notification.event_type }}</div>
          </li>

          <li class="list-group-item d-flex p-0 border-0 mt-1">
            <div class="w-25 py-2 px-3 bg-light">
              <i class="bi bi-card-text me-3"></i>Title:
            </div>
            <div class="w-75 py-2 px-3">{{ notification.title }}</div>
          </li>

          <li class="list-group-item d-flex p-0 border-0 mt-1">
            <div class="w-25 py-2 px-3 bg-light">
              <i class="bi bi-file-text me-3"></i>Description:
            </div>
            <div class="w-75 py-2 px-3 text-secondary">
              {{ notification.description }}
            </div>
          </li>

          <li class="list-group-item d-flex p-0 border-0 mt-1">
            <div class="w-25 py-2 px-3 bg-light">
              <i class="bi bi-shield-fill-exclamation me-3"></i>Priority:
            </div>
            <div v-if="1 == notification.priority" class="w-75 py-2 px-3 text-info">Low</div>
            <div v-if="2 == notification.priority" class="w-75 py-2 px-3 text-warning">Medium</div>
            <div v-if="3 == notification.priority" class="w-75 py-2 px-3 text-danger">High</div>
          </li>

          <li class="list-group-item d-flex p-0 border-0">
            <div class="w-25 py-2 px-3 bg-light">
              <i class="bi bi-stopwatch me-3"></i>Timestamp:
            </div>
            <div class="w-75 py-2 px-3 font-monospace small align-self-center">
              {{
                formattedTimestamp(notification.created_at) +
                " · " +
                timeAgo(notification.created_at)
              }}
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="card mb-2">
      <div class="card-header bg-light">
        <i class="bi bi-list-nested me-2"></i>
        Notification data
      </div>

      <div v-if="null === notification.service_id" class="card-body">
        <div class="d-flex justify-content-center">
          <div class="spinner-border" style="width: 7rem; height: 7rem" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
      <div v-else class="card-body">
        <ul class="list-group">
          <li class="list-group-item d-flex p-0 border-0" v-for="(items, key) in notification.properties"
            v-bind:key="key">
            <div class="w-25 py-2 px-3 bg-light">
              <i class="bi bi-circle-fill me-3"></i>
              {{ key }}
            </div>

            <div class="w-75 px-3 text-secondary">
              <table v-for="item in items" v-bind:key="item" class="table table-sm mb-2">
                <tbody>
                  <tr v-for="(value, key) in item" v-bind:key="key">
                    <td class="w-25 text-secondary">{{ key }}</td>
                    <td class="w-75">{{ value }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";

import Breadcrumbs from "@/components/Breadcrumbs.vue";

import Utils from "@/helpers/utils";

export default {
  name: "MyNotificationPage",
  components: { Breadcrumbs },
  data() {
    return {
      notification: {
        id: this.$route.params.notification_id,
        service_id: null
      },
    };
  },
  mounted() {
    this.fetch_notification();
  },
  methods: {
    fetch_notification() {
      axios
        .get(
          process.env.VUE_APP_NOTIFICATIONS_ENDPOINT +
          "/notification/" +
          this.$route.params.notification_id,
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then((response) => {
          this.notification = response.data;
        });
    },
    timeAgo(dateString) {
      return Utils.timeAgo(dateString);
    },
    localMachineTime(dateString) {
      return Utils.localMachineTime(dateString);
    },
    formattedTimestamp(date) {
      return moment(this.localMachineTime(date)).format("HH:mm:ss DD/MM/YY");
    },
  },
};
</script>