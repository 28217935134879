<template>
  <div class="modal fade" tabindex="-1" :id="id">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Update Profile</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-6 mb-3">
              <label for="firstName" class="form-label">First Name</label><input type="text" id="firstName" minlength="3"
                maxlength="50" class="form-control" v-model="first_name" @keyup="validate" />
              <small class="text-secondary">
                <i>Must be at least 3 characters</i></small>
            </div>
            <div class="col-md-6 mb-3">
              <label for="lastName" class="form-label">Last Name</label><input type="text" id="lastName" minlength="3"
                maxlength="50" class="form-control" v-model="last_name" @keyup="validate" />
              <small class="text-secondary">
                <i>Must be at least 3 characters</i></small>
            </div>
          </div>

          <div class="row">
            <div class="col-12 mb-3">
              <label for="jobTitle" class="form-label">Job Title</label><input type="text" id="jobTitle" maxlength="50"
                class="form-control" v-model="job_title" @keyup="validate" />
              <small class="text-secondary">
                <i>Must be at least 3 characters</i></small>
            </div>
          </div>

          <div class="row">
            <div class="col-12 mb-3">
              <label for="phoneNumber" class="form-label">Phone Number</label>
              <vue-tel-input id="phoneNumber" :value="phone_number" defaultCountry="GB" autoFormat="true"
                autocomplete="off" inputOptions.autocomplete="off" v-model="phone_number" @input="onInput" />
              <small class="text-secondary">
                <i>Must be at least 10 characters including country code</i></small>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <button type="button" class="btn btn-primary w-100" :disabled="!valid_input" @click="update_user">
                Update Profile
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import "vue3-tel-input/dist/vue3-tel-input.css";

export default {
  name: "ModalMyDetails",
  props: ["id"],
  data() {
    return {
      first_name: "",
      last_name: "",
      job_title: "",
      phone_number: "",
      valid_input: false,
      privacy: { email: true, phone: true },
    };
  },
  mounted() {
    this.emitter.on("user_details_loaded", (data) => {
      this.first_name = data.first_name ? data.first_name : "";
      this.last_name = data.last_name ? data.last_name : "";
      this.job_title = data.job_title ? data.job_title : "";
      this.phone_number = data.phone_number ? data.phone_number : "";
      this.privacy = data.privacy ? data.privacy : { email: true, phone: true };

      this.validate();
    });
  },
  methods: {
    validate() {
      if (this.privacy.phone) {
        this.valid_input =
          this.first_name.length >= 3 &&
          this.last_name.length >= 3 &&
          this.job_title.length >= 3;
      } else {
        this.valid_input =
          this.first_name.length >= 3 &&
          this.last_name.length >= 3 &&
          this.job_title.length >= 3 &&
          this.phone_number.length >= 10;
      }
    },

    onInput(phone, phoneObject, input) {
      if (phoneObject) {
        this.phone_number = phoneObject.formatted;

        this.validate();
      }
    },
    
    update_user() {
      let app = this;

      axios
        .patch(
          process.env.VUE_APP_URL + "/me/",
          {
            first_name: this.first_name,
            last_name: this.last_name,
            job_title: this.job_title,
            phone_number: this.phone_number.replace(/ /g, ""), // validation
          },
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then(
          (response) => {
            if (200 === response.status) {
              app.emitter.emit("user_details_updated");
            } else {
            }
          },
          (error) => {
            console.log(error);
          }
        );
    },
  },
};
</script>
