<template>
  <div class="modal fade" tabindex="-1" :id="id">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Update Service Details</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12 mb-3">
              <label for="name" class="form-label">Name</label
              ><input
                type="text"
                id="name"
                minlength="3"
                maxlength="50"
                class="form-control"
                v-model="name"
                @keyup="validate"
              />
              <small class="text-secondary">
                <i>Must be at least 3 characters</i></small
              >
            </div>
          </div>

          <div class="row">
            <div class="col-12 mb-3">
              <label for="description">Description</label
              ><input
                type="text"
                id="description"
                maxlength="50"
                class="form-control"
                v-model="description"
                @keyup="validate"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-12 mb-3">
              <label for="redirect_uri">Redirect URL</label
              ><input
                type="text"
                id="redirect_uri"
                maxlength="50"
                class="form-control"
                v-model="redirect_uri"
                @keyup="validate"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <button
                type="button"
                class="btn btn-primary w-100"
                :disabled="!valid_input"
                @click="update_service"
              >
                Update Service
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ModalServiceDetails",
  props: ["id"],
  data() {
    return {
      name: "",
      description: "",
      redirect_uri: "",
      type: true,
      require_accept: false,
      eligibility: false,
      valid_input: false,
    };
  },
  mounted() {
    this.emitter.on("service_details_loaded", (service) => {
      this.name = service.name;
      this.description = service.description;
      this.redirect_uri = service.redirect_uri;
      this.type = "public" === service.type ? true : false;
      this.require_accept = service.require_accept;
      this.eligibility = service.eligibility;

      this.validate();
    });
  },
  methods: {
    validate() {
      const re =
        /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
      this.valid_input =
        re.test(String(this.redirect_uri).toLowerCase()) &&
        this.name.length >= 3;
    },

    update_service() {
      let app = this;

      axios
        .patch(
          process.env.VUE_APP_URL +
            "/services/" +
            this.$route.params.service_id,
          {
            name: this.name,
            description: this.description,
            redirect_uri: this.redirect_uri,
            type: this.type ? "public" : "confidential",
            require_accept: this.require_accept,
            eligibility: this.eligibility,
          },
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then(
          (response) => {
            if (200 === response.status) {
              let serviceDetailsModal = bootstrap.Modal.getInstance(
                document.getElementById("serviceDetails")
              );
              serviceDetailsModal.hide();

              app.emitter.emit("service_details_updated");
            } else {
            }
          },
          (error) => {
            console.log(error);
          }
        );
    },
  },
};
</script>