<template>
  <div class="d-flex bd-highlight">
    <div class="flex-grow-1 me-2">
      <div class="input-group">
        <button
          class="input-group-text bg-white"
          @click="fetch_me_activities()"
        >
          <i class="bi bi-search"></i>
        </button>
        <input
          type="text"
          class="form-control"
          v-model="filter.activity_name"
          v-on:keyup.enter="fetch_me_activities()"
          placeholder="Search..."
        />
      </div>
    </div>
    <div class="bd-highlight me-2">
      <select class="form-select" v-model="filter.log_type">
        <option value="" selected="selected">
          All Logs ({{ this.paginator.total_items }})
        </option>
        <optgroup label="UMS">
          <option value="email_changed">Email changed</option>
          <option value="login">Login</option>
          <option value="login_failed">Login fail</option>
          <option value="login_google">Login with Google</option>
          <option value="member_deleted">Membership deleted</option>
          <option value="member_updated">Membership updated</option>
          <option value="organization_created">Organization created</option>
          <option value="organization_custom_created">
            Custom organization created
          </option>
          <option value="organization_deleted">Organization deleted</option>
          <option value="organization_logo_updated">
            Organization logo updated
          </option>
          <option value="organization_updated">Organization updated</option>
          <option value="password_changed">Password changed by user</option>
          <option value="password_changed_reset">Password reset</option>
          <option value="password_reset">Password changed</option>
          <option value="role_created">Role created</option>
          <option value="role_deleted">Role deleted</option>
          <option value="service_created">Service created</option>
          <option value="service_enabled">Service enabled</option>
          <option value="service_picture_updated">
            Service picture changed
          </option>
          <option value="service_reset_secret">Service's secret reset</option>
          <option value="service_updated">Service updated</option>
          <option value="session_revoked">Session revoked</option>
          <option value="site_created">Site created</option>
          <option value="site_deleted">Site deleted</option>
          <option value="site_updated">Site updated</option>
          <option value="user_banned">User banned</option>
          <option value="user_session_revoked">User session revoked</option>
          <option value="user_suspended">User suspended</option>
        </optgroup>
        <optgroup label="L8LOG">
          <option value="asset_created">Asset created</option>
          <option value="asset_deleted">Asset deleted</option>
          <option value="asset_updated">Asset updated</option>
          <option value="demo">Demo</option>
          <option value="location_created">Location created</option>
          <option value="reading_added">Reading added</option>
          <option value="sensor_added">Sensor added</option>
          <option value="sensor_deleted">Sensor deleted</option>
          <option value="site_risk_rating_changed">
            Site risk rating changed
          </option>
        </optgroup>
      </select>
    </div>
    <div class="bd-highlight me-2">
      <div class="input-group">
        <div class="input-group">
          <span class="input-group-text bg-white"
            ><i class="bi bi-calendar-week"></i
          ></span>
          <input
            type="text"
            id="fromDate"
            placeholder="From Date"
            class="form-control"
            style="max-width: 120px"
          />
          <span class="input-group-text bg-white"
            ><i class="bi bi-calendar-week"></i
          ></span>
          <input
            type="text"
            id="toDate"
            placeholder="To Date"
            class="form-control"
            style="max-width: 120px"
          />
        </div>
      </div>
    </div>
    <div class="bd-highlight">
      <button
        class="btn bg-white border text-decoration-none text-black"
        @click="export_my_activities()"
      >
        Export
      </button>
    </div>
  </div>

  <div class="card mt-2">
    <div class="card-header bg-light">
      <i class="bi bi-calendar-week me-2"></i>
      Activity Log
    </div>

    <div v-if="0 === activities.length" class="card-body">
      <div class="text-center">
        <img src="/images/empty-activity.svg" class="img-fluid py-5" />
      </div>
    </div>

    <div v-else class="list-group list-group-flush activity-log">
      <router-link
        :to="'/logs/' + activity.id"
        v-for="activity in activities"
        v-bind:key="activity.id"
        class="list-group-item list-group-item-action"
      >
        <div class="d-flex align-items-center">
          <div class="flex-shrink-0">
            <img
              src="/images/person-circle.svg"
              alt="twbs"
              class="rounded-circle align-self-center"
              width="36"
              height="36"
            />
          </div>
          <div class="flex-grow-1 ms-3">
            <span class="text-dark">{{ activity.causer_name }}</span>
            <p
              class="small text-muted mb-0 fw-normal"
              v-html="activity.plaintext ? activity.plaintext : '&nbsp;'"
            ></p>
          </div>
          <span
            class="text-muted float-end small text-nowrap"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title=""
            :data-bs-original-title="activity.created_at"
            >{{ timeAgo(activity.created_at) }}</span
          >
          <div class="float-end ms-3">
            <i class="bi bi-chevron-right align-self-center"></i>
          </div>
        </div>
      </router-link>
    </div>
    <div class="card-footer bg-light">
      <pagination
        v-if="paginator.current_page"
        :records="paginator.total_items"
        :per-page="10"
        v-model="current_page"
        :options="paginatorOptions"
      ></pagination>
    </div>
  </div>

  <ModalUserAgent id="userAgent" />
  <ModalUserLocation id="userLocation" />
</template>

<script>
import axios from "axios";
import FileDownload from "js-file-download";
import Pagination from "v-pagination-3";
import { Datepicker } from "vanillajs-datepicker";

import RTPagination from "@/components/RTPagination.vue";
import ModalUserAgent from "@/components/modals/ModalUserAgent.vue";
import ModalUserLocation from "@/components/modals/ModalUserLocation.vue";

import Utils from "@/helpers/utils";

var fileDownload = require("js-file-download");

export default {
  name: "ServiceActivity",
  components: {
    ModalUserAgent,
    ModalUserLocation,
    Pagination,
    RTPagination,
    FileDownload,
  },
  data() {
    return {
      paginatorOptions: {
        template: RTPagination,
        chunk: 15,
      },
      paginator: {},
      activities: [],
      filter: {
        activity_name: "",
        log_type: "",
        from_date: "",
        to_date: "",
      },
      current_page: 1,
    };
  },
  mounted() {
    let app = this;

    const fromDate = new Datepicker(document.getElementById("fromDate"), {
      clearBtn: true,
      autohide: true,
      maxDate: new Date(),
      format: "d M yyyy",
    });

    const toDate = new Datepicker(document.getElementById("toDate"), {
      clearBtn: true,
      autohide: true,
      minDate: new Date(),
      maxDate: new Date(),
      format: "d M yyyy",
    });

    document.getElementById("fromDate").addEventListener("changeDate", (ev) => {
      app.filter.from_date = Datepicker.formatDate(
        ev.detail.date,
        "yyyy-mm-dd"
      );

      toDate.setOptions({ minDate: ev.detail.date });

      if (toDate.getDate() < ev.detail.date) {
        toDate.setDate(ev.detail.date);
      }
    });

    document.getElementById("toDate").addEventListener("changeDate", (ev) => {
      this.filter.to_date = Datepicker.formatDate(ev.detail.date, "yyyy-mm-dd");
    });

    this.fetch_me_activities();

    setTimeout(() => {
      jQuery("[data-bs-toggle='tooltip']").each(function () {
        jQuery(this).tooltip();
      });
    }, 1000);
  },
  methods: {
    fetch_me_activities() {
      axios
        .get(
          process.env.VUE_APP_LOGS_ENDPOINT +
            "/activities?filter[entity_id]=" +
            this.$route.params.service_id +
            "&page=" +
            this.current_page +
            "&sort=created_at,desc&filter[type]=" +
            this.filter.log_type +
            "&filter[start]=" +
            this.filter.from_date +
            "&filter[end]=" +
            this.filter.to_date +
            "&filter[wildcard]=" +
            encodeURIComponent(this.filter.activity_name),
          {
            headers: {
              Authorization: process.env.VUE_APP_LOGS_SECRET,
            },
          }
        )
        .then((response) => {
          this.activities = response.data.data;
          this.paginator = response.data.paginator;
        });
    },
    export_my_activities() {
      axios
        .get(
          process.env.VUE_APP_LOGS_ENDPOINT +
            "/service/" +
            this.$route.params.service_id +
            "/export?items=-1=&sort=created_at,desc&filter[type]=" +
            this.filter.log_type +
            "&filter[start]=" +
            this.filter.from_date +
            "&filter[end]=" +
            this.filter.to_date +
            "&filter[wildcard]=" +
            encodeURIComponent(this.filter.activity_name),
          {
            headers: {
              Authorization: process.env.VUE_APP_LOGS_SECRET,
            },
          }
        )
        .then((response) => {
          fileDownload(response.data, "service_activities.csv");
        });
    },
    short_input(string) {
      if (string && string.length > 40) {
        return string.substring(0, 40) + "...";
      } else {
        return string;
      }
    },
    timeAgo(dateString) {
      return Utils.timeAgo(dateString);
    },
  },
  watch: {
    "filter.log_type": function () {
      this.fetch_me_activities();
    },
    "filter.from_date": function () {
      this.fetch_me_activities();
    },
    "filter.to_date": function () {
      this.fetch_me_activities();
    },
    current_page: function () {
      this.fetch_me_activities();
    },
  },
};
</script>


<style src="vanillajs-datepicker/dist/css/datepicker.min.css"></style>